import React from "react"
import { Button } from "antd"
import useViewport from "../hooks/useViewport"
import { useNavigate } from "react-router-dom"

export default function WelcomePage () {
  const { width, height } = useViewport()
  const navigate = useNavigate()

  return (
    <div style={{ width, height, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Button 
        type="primary" 
        style={{ color: "white", height: 200, fontSize: 20, fontWeight: "bold" }}
        onClick={() => {
          navigate("/checkout")
        }}
      >
        Scan Your First Item to Start Checkout 
      </Button>
    </div>
  )
}