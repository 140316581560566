import React, { useState } from "react"
import { Input, Button, Modal, Typography } from "antd"
import useViewport from "../../hooks/useViewport"
import loadSpinner from "../../assets/gif/loadSpinner.gif"
import NumpadModal from "./NumpadModal"
const { Title } = Typography


interface ProgressModalProps {
  isVisible: boolean
  title?: string
  description: string
  onOK?: () => void
  onCancel?: () => void
  containerStyle?: React.CSSProperties
  closable?: boolean
  isScale?: boolean
  onClick?: (weight: number) => void
  productImage?: string
}

const ProgressModal = ({ isVisible, title, description, onOK, onCancel, containerStyle, closable, isScale, onClick, productImage }: ProgressModalProps) => {
  const { width } = useViewport()
  const [isNumpadVisible, setIsNumpadVisible] = useState(false)

  const onConfirmValue = (value: number) => {
    setIsNumpadVisible(false)
    onClick && onClick(value)

  }
  return (
    <>
      <Modal
        // transitionName="none"
        // maskTransitionName="none"
        centered
        // title={title}
        width={width * 0.5}
        visible={isVisible}
        footer={null}
        onCancel={onCancel}
        onOk={onOK}
        closable={closable ? closable : false}
        maskClosable={isScale ? false : true}
      >
        <div style={containerStyle ? containerStyle : { margin: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
          <Title>{description}</Title>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }}>
            {isScale && productImage ?
              <img src={productImage} alt={description} style={{ maxWidth: 400, maxHeight: 400 }} />
              : null
            }

            <img src={loadSpinner} alt="loading spinner" />
          </div>
          {isScale ?
            <>
              <button
                className="staffOperateButton"
                style={{ marginTop: "20px", height: "100px", backgroundColor: "#2C6EF1", fontSize: "30px" }}
                onClick={() => setIsNumpadVisible(true)}
              >
                Input Weight
              </button>
              <button
                className="staffOperateButton"
                style={{ marginTop: "20px", height: "100px", backgroundColor: "#ED4141", fontSize: "30px" }}
                onClick={onCancel}
              >
                Cancel Scale
              </button>
            </>
            : null
          }
        </div>
      </Modal>
      {isNumpadVisible ?
        <NumpadModal
          title="weight"
          value="0"
          isVisible={isNumpadVisible}
          decimal={3}
          negative={false}
          onConfirm={onConfirmValue}
          onDismiss={() => {
            setIsNumpadVisible(false)
          }}
        />
        : null
      }
    </>
  )
}

export default ProgressModal
