import React, { useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, Form, Input, Button, Checkbox, AutoComplete } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import SearchBar from "../../common/SearchBar"
import { Customer } from "../../../models"
import { serveCustomer } from "../../../redux/Customer/slice"

interface CustomerScanModalProps {
  isVisible: boolean
  setVisibility: (value: boolean) => void
}

const CustomerScanModal = ({ isVisible, setVisibility }: CustomerScanModalProps) => {
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer, customers } = Customer
  const dispatch = useDispatch()
  const [options, setOptions] = useState<any[]>([])
  const [selectedKey, setSelectedKey] = useState<string>()

  const searchResult = (value: string) => {
    const result: Array<Customer> = customers.filter((customer: Customer) => {
      return customer.CustomerCode.includes(value) || customer.Mobile.includes(value) || customer.name.includes(value)
    })
    if (result.length > 0) {
      return result.map((_, index) => {
        return {
          key: _.CustomerCode,
          value: _.name,
          label: (
            <a>{_.name}</a>
          )
        }
      })
    } else {
      return []
    }
  }

  const onSelect = (value: string, option: any) => {
    // console.log('onSelect', value)
    // console.log("option: ", option)
    setSelectedKey(option.key)
  }

  const handleSearch = (value: string) => {
    setOptions(value ? searchResult(value) : [])
  }

  return (
    <Modal 
      transitionName="none"
      maskTransitionName="none"
      title="Choose Customer"
      visible={isVisible}
      // footer={null}
      okText="Confirm"
      onOk={(e) => {
        const selectedCustomer = customers.filter((customer: Customer) => { return customer.CustomerCode === selectedKey })
        console.log("selected customer: ", selectedCustomer)
        dispatch(serveCustomer({ customer: selectedCustomer[0] }))
        setVisibility(false)
      }}
      onCancel={(e) => {
        setVisibility(false)
      }}
      width={800}
    >
      <div style={{ display: "flex", justifyContent: "center", height: 300 }}>
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{ width: 500 }}
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
        >
          <Input.Search size="large" placeholder="input customer name/mobile/code" enterButton allowClear />
        </AutoComplete>
        {/* <SearchBar 
          onSearch={() => console.log("search")} 
          placeholderText="Enter customer code/phone number"
          containerStyle={{ width: 500 }}
        /> */}
      </div>
    </Modal>
  )
}

export default CustomerScanModal
