import "../../css/Global.css"
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form } from "antd"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { CartItem, StyleSheet, Total, Order, CartItemType } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import useViewport from "../../hooks/useViewport"

interface CheckoutListProps {
  tableHeightFactor?: number
  tableWidthFactor?: number
}

export default function RefundList({ tableHeightFactor, tableWidthFactor }: CheckoutListProps) {
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, refundItems } = Checkout
  const dispatch = useDispatch()
  const { height, width } = useViewport()

  // useEffect(() => {
  //   // let box = document.getElementById("box")
  //   let v1 = document.getElementsByClassName("ant-table-body")[0]
  //   let v2 = document.getElementsByClassName("ant-table-body")[1]
  //   // console.log("v: ", document.getElementsByClassName("ant-table-body"))
  //   if (v1) {
  //     // console.log("v1 scroll height: ", v1.scrollHeight)
  //     // console.log("v1 scroll top: ", v1.scrollTop)
  //     v1.scrollTop = v1.scrollHeight
  //   }
  //   if (v2) {
  //     // console.log("v2 scroll height: ", v2.scrollHeight)
  //     // console.log("v2 scroll top: ", v2.scrollTop)
  //     v2.scrollTop = v2.scrollHeight
  //   }
  // }, [itemsInCart.length])
  
  const columns = [
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>No</Typography.Text>,
      dataIndex: "itemId",
      width: "30px",
      ellipsis: false,
      render: (text:string, record: any, index: number) => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>{index + 1}</Typography.Text>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Description</Typography.Text>,
      dataIndex: ["productInfo", "name"],
      width: "35%",
      render: (text: string, item: CartItem) => (
        <div>
          {
            item.promotionText ?
            <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 15, fontWeight: "bold", color: "red" }}>{item.promotionText}</Typography.Text>
            :
            null
          }
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ fontSize: width > 1500 ? 20 : 15, marginBottom: 0 }}>{text}</Typography.Paragraph>
          {
            item.productInfo.nameKorean &&
            <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>{item.productInfo.nameKorean}</Typography>
          }
        </div>
      )
    },
    {
      title: () => <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ fontSize: width > 1500 ? 20 : 12, margin: 0 }}>Qty</Typography.Paragraph>,
      dataIndex: "quantity",
      width: "10%",
      render: (quantity:number, item: CartItem) => 
        <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>{quantity}</Typography>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Price</Typography.Text>,
      dataIndex: "unitPrice",
      width: "18%",
      render: (price:number, item: CartItem) => 
        <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>${price.toFixed(2)}</Typography>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Discount</Typography.Text>,
      dataIndex: "discountRatio",
      width: "10%",
      render: (discount: number, item: CartItem) => 
      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
        {
          item.allowDiscount ?
          <Typography.Text style={{ fontSize: width > 1500 ? 20 : 15 }}>{discount}%</Typography.Text>
          :
          <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>N/A</Typography>
        }
      </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Amount</Typography.Text>,
      dataIndex: "total",
      width: "18%",
      render: (text:any) => <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>${text.toFixed(2)}</Typography>
    }
  ]

  return (
    <div style={{ marginBottom: 20 }}>
      <Table 
        columns={columns}
        dataSource={refundItems}
        pagination={false}
        scroll={{
          y: tableHeightFactor ? height * tableHeightFactor : height * 0.35
        }}
        rowClassName={(record, index) => { return record.itemId === "sp" || record.type === CartItemType.Promotion ? "yellow" : "white" }}
        style={{ width: tableWidthFactor ? width * tableWidthFactor : width * 0.6 }}
        // pagination={tableRows ? { pageSize: tableRows } : { pageSize: 10 }}
        // style={tableHeight ? { height: tableHeight } : { height: 680 }}
      />
    </div>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  tableItem: {
    width: 50,
    fontSize: 20
  },
  totalContainer: {
    right: 10,
    position:"absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row"
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20 
  },
  button: {
    marginBottom: 20,
    width: 140,
    height: 140
  }
  
}
