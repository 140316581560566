import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Button, Divider, notification, Image } from "antd"
import { CreditCardFilled, DollarCircleFilled } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"
import useViewport from "../../hooks/useViewport"
import { primary } from "../../constants/Colors"
import ProgressModal from "../common/ProgressModal"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import {
  setPaymentTotal,
  setPaymentTotal1,
  setPaymentTotal2,
  setPaymentStep
} from "../../redux/Payment/slice"
import { calculatePaymentRounding, cartItemsToInvoiceItems, randomInteger, cartItemsToProductCodeOnlyItems, transferPaymentMethod } from "../../utils/Global"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { InvoiceHead, InvoiceItem, RecvAcct, Total } from "../../models"
import { BackendPaymentMethod } from "../../constants/Enums"
import { postInvoice } from "../../api/invoice"
import { addInvoice } from "../../redux/Customer/slice"
import AlertModal from "../common/AlertModal"
import { setToken } from "../../redux/Config/slice"
import { postRefund } from "../../api/refund"
import RefundInfoModal from "./RefundInfoModal"
import { clearRefundCart, setRefundMode } from "../../redux/Checkout/slice"
import RefundReceiptModal from "./RefundReceiptModal"
import { doPurchase } from "../../api/payment"
import { getConfiguration } from "../../utils/configurate"
const logo_royalpay = require("../../assets/png/logo_royalpay.png")

interface Props {
  isVisible: boolean
  setVisibility: (value: boolean) => void
  sectionHeightFactor?: number
  sectionWidthFactor?: number
  onRefundSuccess: (invoice: any) => void
  onManagerApprove: (paymentType: BackendPaymentMethod) => void
}


export default function RefundPaymentModal({ sectionHeightFactor, sectionWidthFactor, isVisible, setVisibility, onRefundSuccess, onManagerApprove }: Props) {
  const { height, width } = useViewport()
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, currentInvoice, showReceiptModal, paymentType, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2 } = Payment
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart, isRefundMode, currentRefundInvoice, refundItems, refundTotal } = Checkout
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer

  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [selectedRefundMethod, setSelectedRefundMethod] = useState<BackendPaymentMethod | null>(null)
  const PosConfig = getConfiguration("RefundPaymentModal")
  const { machineID: MachineID, warehouseCode: WarehouseCode } = PosConfig

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getCurrentInvoicePayBy = () => {
    let result = "NA"
    if (currentRefundInvoice?.RecvAccts?.length > 0) {
      result = currentRefundInvoice.RecvAccts[0].PayBy
    }
    return result
  }

  const getRefundInvoiceItems = () => {
    const result: InvoiceItem[] = []
    refundItems.forEach((item: any) => {
      const newItem = {
        Price: item.refundTotal,
        ProductCode: item.ProductCode,
        Qty: -item.planRefundQty,
        UnitCost: item.UnitCost,
        Discount: item.Discount,
        GSTRate: item.GSTRate,
        ReferenceItemID: item.ID
      }
      //@ts-ignore
      result.push(newItem)
    })
    return result
  }

  const postRefundCallback = (response: any) => {
    // console.log("callback: ", response)
    const invoice = response.data
    onRefundSuccess(invoice)
  }

  const sendPurchaseRequest = async () => {
    const purchaseRequest = {
      // txtPurchaseAmount: refundTotal.totalAmount,
      txtPurchaseAmount: "0.1",
      txtCashOutAmount: 0,
      applicationCode: 0,
      requestOptionCode: 1
    }
    await doPurchase(purchaseRequest)
  }

  const onPostRefund = async () => {
    const amount = -refundTotal.totalAmount
    const items = getRefundInvoiceItems()
    const ReferenceNo = currentRefundInvoice.InvoiceNo
    const RecvAcct = {
      //@ts-ignore
      PayBy: selectedRefundMethod ? selectedRefundMethod : "CASH",
      PaidAmount: amount,
      MachineID,
      Transfer: false,
      PaymentID: randomInteger(1, 10000)
    }
    const payload = {
      InvoiceHead: {
        Amount: Number(amount.toFixed(2)),
        CustomerCode: currentRefundInvoice.CustomerCode,
        InvoiceDate: new Date().toString(),
        InvoiceItems: items,
        WarehouseCode,
        ReferenceNo,
        //添加用户点数扣除数据
        CurrentPoints: servingCustomer ? servingCustomer.Points : 0,
        
      },
      RewardPoints: 0 - Math.floor(amount * 1),
      //@ts-ignore
      RecvAcct
    }
    
    //@ts-ignore
    await postRefund(payload, postRefundCallback)
  }

  return (
    <Modal
      transitionName="none"
      maskTransitionName="none"
      className="modalPage"
      title={<div className="modalTitle">Refund Payment<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
      visible={isVisible}
      footer={null}
      onCancel={(e) => {
        setVisibility(false)
      }}
      width={600}
    >
      {/* <div 
        style={{ 
          display: "flex", 
          justifyContent: "flex-start", 
          alignItems: "flex-start", 
          backgroundColor: "white", 
          flexDirection: "column", 
          zIndex: 100, 
          padding: 18, 
          height: sectionHeightFactor ? height * sectionHeightFactor : height * 0.45
        }}
      > */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: "white",
          flexDirection: "column",
          zIndex: 100,
          padding: 18,
          marginTop: "-40px"
          // height: sectionHeightFactor ? height * sectionHeightFactor : height * 0.45
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", alignSelf: "center", zIndex: 100 }}>
          {/* <Typography style={{ fontWeight: "bold", fontSize: 20 }}>Please Select Refund Method: </Typography> */}
          <div className="normalLabel" style={{ color: "#2C6EF1", display: "flex", alignItems: "center" }}>
            <div style={{ width: "4px", height: "15px", marginRight: "5px", backgroundColor: "#2C6EF1" }}></div>Please Select Refund Method
          </div>
          {
            getCurrentInvoicePayBy() !== "NA" &&
            <Typography style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>Invoice Originally Paid By: {getCurrentInvoicePayBy()}</Typography>
          }
          <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 20, justifyContent: "space-around" }}>
            <div
              onClick={() => {
                onManagerApprove(BackendPaymentMethod.Cash)
                setVisibility(false)
              }}
              style={{ height: "100px", display: "flex", flexDirection: "column", width: "30%", backgroundColor: "#fff", borderRadius: "6px", justifyContent: "center", alignItems: "center" }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/cashPayMethod-icon.png"} style={{ width: 50, height: 50 }} />
              <div className="normalLabel" style={{ fontSize: 20 }}>Cash</div>
            </div>

            <div
              onClick={async () => {
                onManagerApprove(BackendPaymentMethod.EFTPOS)
                setVisibility(false)
              }}
              style={{ height: "100px", display: "flex", flexDirection: "column", width: "30%", backgroundColor: "#fff", borderRadius: "6px", justifyContent: "center", alignItems: "center" }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/cardPayMethod-icon.png"} style={{ width: 50, height: 50 }} />
              <div className="normalLabel" style={{ fontSize: 20 }}>Card</div>
            </div>
            {/* <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }} /> */}
            {/* <div
              onClick={() => {
                onManagerApprove(BackendPaymentMethod.RoyalPay)
                setVisibility(false)
              }}
              style={{ height: "100px", display: "flex", flexDirection: "column", width: "30%", backgroundColor: "#fff", borderRadius: "6px", justifyContent: "center", alignItems: "center" }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/royalPayMethod-icon.png"} style={{ width: 50, height: 50 }} />
              <div className="normalLabel" style={{ fontSize: 20 }}>RoyalPay</div>
            </div> */}
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row", marginTop: 50, alignSelf: "center" }}>
            <div
              onClick={() => {
                // setIsConfirmModalVisible(true)
                // setSelectedRefundMethod(BackendPaymentMethod.Cash)
                onManagerApprove(BackendPaymentMethod.Cash)
                setVisibility(false)
              }}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <DollarCircleFilled style={{ fontSize: 80, color: primary }} />
              <Typography style={{ fontSize: 30, color: primary }}>Cash</Typography>
            </div>
            <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }} />
            <div
              onClick={async () => {
                // setIsConfirmModalVisible(true)
                // setSelectedRefundMethod(BackendPaymentMethod.EFTPOS)
                onManagerApprove(BackendPaymentMethod.EFTPOS)
                setVisibility(false)
              }}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <CreditCardFilled style={{ fontSize: 80, color: primary }} />
              <Typography style={{ fontSize: 30, color: primary }}>Credit Card</Typography>
            </div>
            <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }} />
            <div
              onClick={() => {
                // setIsConfirmModalVisible(true)
                // setSelectedRefundMethod(BackendPaymentMethod.RoyalPay)
                onManagerApprove(BackendPaymentMethod.RoyalPay)
                setVisibility(false)
              }}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Image
                src={logo_royalpay}
                width={150}
                preview={false}
              />
            </div>
          </div> */}
        </div>
        <ProgressModal isVisible={isInProgress} description={"Transaction In Progress"} />
        <AlertModal
          isVisible={isAlertModalVisible}
          description={errorMsg}
          buttonText={"OK"}
          onCancel={() => setIsAlertModalVisible(false)}
          onConfirm={() => setIsAlertModalVisible(false)}
        />
        {/* <ActionConfirmModal 
          isVisible={isConfirmModalVisible} 
          description={"Please wait for manager to confirm"} 
          actionText={"Manager Approve"} 
          cancelText={"Send Notification"} 
          onConfirm={() => {
            setIsConfirmModalVisible(false)
            sendPurchaseRequest()
            onPostRefund()
          }} 
          onCancel={() => {
            setIsConfirmModalVisible(false)
          }} 
        /> */}
      </div>
    </Modal>
  )
}
