import React, { useEffect, useState, useRef } from "react"
import { List, Image, Card, Button, Row, Col, Pagination } from "antd"
import CheckoutCategoryCard from "../Category/CheckoutCategoryCard"
import { Category } from "../../models"
import { PageOperationType } from "../../constants/Enums"
import "../../css/Global.css"
// import { useDispatch } from "react-redux"
// import { loadCategories } from "../../redux/Product/slice"

interface Props {
    categories: Array<any>
    onSelectProductCategory?: (item: any) => void
    currentCatgory: any
}

export default function CategoryPageList({ categories, onSelectProductCategory, currentCatgory }: Props) {
    const [currentCategoryPage, setCurrentCategoryPage] = useState(1)
    const [currentCatgories, setCurrentCategories] = useState<Array<Category>>([])
    const pageComponment: any = {}
    const isInitLoadCategory = useRef(true)
    // const dispatch = useDispatch()
    useEffect(() => {
        // console.log("categories 同步", categories)
        if (categories.length > 0 && isInitLoadCategory.current ) {
            setCurrentCategories(categories.slice(0, 9))
            isInitLoadCategory.current = false
        }
    // }, [])
    }, [categories])

    const filterCategoryByName = (CategoryName:string) => {
        if (CategoryName) {
            const target = categories.filter(item => item.name === CategoryName)
            if (target.length > 0) {
                return target[0].id
            } else return "9999"//没有匹配的产品类别 = mannually add
        } 
        console.log("return 0--->", CategoryName)
        return "0"
    }

    const onChangePage = async (type: PageOperationType) => {
        let page: number = 0
        switch (type) {
            case PageOperationType.Next:
                page = currentCategoryPage + 1
                break
            case PageOperationType.Prev:
                page = currentCategoryPage - 1
                break
        }
        // console.log("page is", page, currentCategoryPage, 9 * (page - 1), categories.length, categories.slice(9 * (page - 1), 9*page))
        if (page <= 0 || page > Math.ceil(categories.length / 9)) {
            // console.log("nothing happened")
            return
        }
        const newCategories:any[] = categories.slice(9 * (page - 1), 9*page)
        if (newCategories.length < 9) {
            for (let index = newCategories.length; index < 9; index++) {
                newCategories.push({id: index.toFixed(10)})       
            }
        }
        setCurrentCategories(newCategories)
        // dispatch(loadCategories({categories: newCategories}))
        setCurrentCategoryPage(page)
        // console.log("当前page is", page, currentCategoryPage, 9 * (page - 1), categories.length, categories.slice(9 * (page - 1), 9*page))
        
    }

    return (
        <div className="productCategories">
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <button
                    className="bigCategory"
                    style={{
                        color: currentCatgory?.id === "0" ? "#fff" : "#2C6EF1",
                        backgroundColor: currentCatgory?.id === "0" ? "#2C6EF1" : "#B9CBEE"
                    }}
                    onClick={() => {
                        onSelectProductCategory && onSelectProductCategory({id: filterCategoryByName("")})
                        
                    }}
                >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/mostSearched-icon.png"} style={{ width: "24px", height: "24px", marginRight: "10px" }} />
                    Most Searched
                </button>
                {/* <button
                    className="bigCategory"
                    onClick={() => onSelectProductCategory && onSelectProductCategory({id: filterCategoryByName("11.NO BARCODE")})}
                >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/noBarcode-icon.png"} style={{ width: "24px", height: "24px" }} />
                    No Barcode
                </button> */}
                <button
                    className="bigCategory"
                    style={{
                        color: currentCatgory?.id === "9999" ? "#fff" : "#2C6EF1",
                        backgroundColor: currentCatgory?.id === "9999" ? "#2C6EF1" : "#B9CBEE"
                    }}
                    onClick={() => onSelectProductCategory && onSelectProductCategory({id: filterCategoryByName("Mannually Added")})}
                >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/mannuallyAdd-icon.png"} style={{ width: "24px", height: "24px", marginRight: "10px" }} />
                    Mannually Added
                </button>
            </div>
            <List
                grid={{
                    gutter: 5,
                    xs: 5,
                    sm: 5,
                    md: 5,
                    lg: 5,
                    xl: 5,
                    xxl: 5,
                }}
                className="categoryList"
                // dataSource={categories}
                dataSource={[...currentCatgories, pageComponment]}
                // style={{ height: height * 0.3, overflowY: "scroll", overflowX: "hidden" }}
                renderItem={item => (
                    <List.Item>
                        {item.id ?
                            <CheckoutCategoryCard
                                category={item}
                                onCardPress={onSelectProductCategory}
                                cardColor={item.id === currentCatgory?.id ? "#2C6EF1" : "#fff"}
                                fontColor={item.id === currentCatgory?.id ? "#fff" : "#333333"}
                            />
                            :
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <button
                                    className="categoryListPagination"
                                    onClick={() => onChangePage(PageOperationType.Prev)}
                                >
                                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/prePageWhite-icon.png"} style={{ width: "24px", height: "24px" }} />
                                </button>
                                <button
                                    className="categoryListPagination"
                                    onClick={() => onChangePage(PageOperationType.Next)}
                                >
                                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/afterPageWhite-icon.png"} style={{ width: "24px", height: "24px" }} />
                                </button>
                            </div>
                        }
                    </List.Item>
                )}
            />

        </div>
    )
}