export const apiPrefix = "/api/v1"
export const serverAddress = "http://localhost:4000"
export const imageAddress = "http://localhost:7000"
// export const serverAddress = "http://13.211.140.62:4000"
// export const imageAddress = "http://13.211.140.62"
// export const serverAddress = PosConfig.serverAddress+":4000" || "http://13.211.140.62:4000"
// export const imageAddress = PosConfig.serverAddress || "http://13.211.140.62"
// export const serverAddress = "http://54.253.37.67:4000"
// export const imageAddress = "http://54.253.37.67"
export const smallPageSize = 16 //4X4
// export const smallPageSize = 20
// export const smallPageSize = 10
export const pageSize = 100
export const categoryPageSize = 120
export const largePageSize = 50000

export default {
  //queryRouteList: '/routes',

  // Users
  queryUserList: '/users',
  queryCurrentUser: '/users/current',
  queryUser: '/users/:FirebaseUid',
  createUser: 'POST /users/',
  updateUser: 'PUT /users/',
  //logoutUser: '/user/logout',
  loginUser: '/users/login', //POST 

  // Customers
  queryCustomerList: '/customers',
  queryCustomerFilter: '/customers/search',
  queryCustomer: '/customers/',
  // createCustomer: 'POST /customers',
  updateCustomer: 'PUT /customers',
  // createCustomer: '/customers/admin',
  createCustomer: '/customers',
  searchCustomer: '/customers/search',

  queryWarehouseList: '/warehouses',
  queryWarehouse: '/warehouses/:WarehouseCode',
  queryWarehouseFilter: '/warehouses/search',
  createWarehouse: 'POST /warehouses',
  updateWarehouse: 'PUT /warehouses',
  //removeWarehouse: 'DELETE /warehouses/:WarehouseCode',

  queryAisle: '/aisles/:AisleNo',
  createAisle: 'POST /aisles',
  updateAisle: 'PUT /aisles',
  //deleteAisle: 'DELETE /aisles/:AisleNo',

  queryShelf: '/shelves/:ShelfNo',
  createShelf: 'POST /shelves',
  updateShelf: 'PUT /shelves',
  //deleteShelf: 'DELETE /shelves/:ShelfNo',

  queryStockItem: '/stockItems/:ShelfNo',
  createStockItem: 'POST /stockItems',
  updateStockItem: 'Patch /stockItems',
  //deleteStockItem: 'DELETE /stockItems/:ID',

  // Products
  queryProductList: '/products',
  queryProductFilter: '/products/search',
  queryProduct: '/products/:ProductCode',
  createProduct: '/products', // POST
  updateProduct: '/products', // PUT
  deleteProduct: 'DELETE /products/:ProductCode',
  searchProduct: '/products/search',
  getProduct: '/products/',
  recordProductClick: '/products/click',

  querySupplierQuotation: '/supplierquotations',
  addSupplierQuotation: 'POST /supplierquotations',
  updateSupplierQuotation: 'PUT /supplierquotations',
  querySupplierQuotationByCode: '/supplierquotations/quotationsearch',
  querySupplierProduct: '/supplierquotations/:SupplierCode',

  querySupplierList: '/suppliers',
  querySupplierFilter: '/suppliers/suppliersearch',
  querySupplier: '/suppliers/:SupplierCode',
  createSupplier: 'POST /suppliers',
  updateSupplier: 'PUT /suppliers',
  deleteSupplier: 'DELETE /suppliers/:SupplierCode',

  //Categories
  queryCategory: '/categorys',
  queryCategoryName: '/categorys/search',
  createCategory: 'POST /categorys',
  updateCategory: 'PUT /categorys',

  // Invoices
  queryInvoiceList: '/invoices',
  queryInvoiceFilter: '/invoices/search',
  queryInvoiceCurrent: '/invoices/current',
  queryInvoice: '/invoices/:InvoiceNo',
  createInvoice: '/invoices', // POST
  postInvoice: '/orders/pay',
  getInvoices: '/invoices',


  // Holding Order
  postHoldingOrder: '/orders',
  getHoldingOrders: '/orders',

  uploadCSV: 'PUT /csvs',
  getCSV: '/csvs',
  getCSVByCode: '/csvs:Id',

  getImageUrl: 'PUT /images',

  queryPurchaseList: '/purchaseheads',
  queryPurchaseOrder: '/purchaseheads/:PurchaseNo',
  queryPurchaseFilter: '/purchaseheads/search',
  updatePurchaseOrder: 'PUT /purchaseheads',
  addPurchaseOrder: 'POST /purchaseheads',

  reports: '/reports',

  // Special Prices
  // getSpecialPrices: "/specialprices/search?ProductCode="
  getSpecialPrices: "/specialprices",
  getSpecialPriceGroups: "/specialprices/group",

  // CashDeclaration
  queryCashDeclaration: "/cashfloats/declaration",
  addCashDeclaration: "/cashfloats",
  getExpectedTotal: "/cashfloats/expectedTotalByMachine",
  // Draw open log
  addDrawOpenRecord: "/drawers/open"



}
