import "../../css/Global.css"
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form } from "antd"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { CartItem, StyleSheet, Total, Order, CartItemType } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import useViewport from "../../hooks/useViewport"
import { getProductDisplayName } from "../../utils/getProductDisplayName"

interface CheckoutListProps {
  tableHeightFactor?: number
  tableWidthFactor?: number
  items?: any[]
}

export default function CheckoutList({ tableHeightFactor, tableWidthFactor, items }: CheckoutListProps) {
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, total, isKeyboardVisible } = Checkout
  const dispatch = useDispatch()
  const { height, width } = useViewport()


  useEffect(() => {
    // let box = document.getElementById("box")
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    let v2 = document.getElementsByClassName("ant-table-body")[1]
    // console.log("v: ", document.getElementsByClassName("ant-table-body"))
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
    if (v2) {
      // console.log("v2 scroll height: ", v2.scrollHeight)
      // console.log("v2 scroll top: ", v2.scrollTop)
      v2.scrollTop = v2.scrollHeight
    }
  }, [itemsInCart.length, items?.length])

  const columns = [
    {
      title: "No",
      dataIndex: "itemId",
      width: "30px",
      ellipsis: false,
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Description",
      dataIndex: ["productInfo", "name"],
      width: "35%",
      render: (text: string, item: CartItem) => (
        <div>
          {
            item.promotionText ?
              <Typography.Text ellipsis style={{ fontWeight: 700, fontSize: "18px" }}>
                {item.promotionText.split("/").map(str => <div style={{ color: str.indexOf("WAS") > -1 ? "black" : "red" }}>{str}<br /></div>)}
              </Typography.Text>
              :
              null
          }
          {/* <Typography.Paragraph ellipsis={{ rows: 2 }} style={{fontSize: "18px"}}>{getProductDisplayName(item.productInfo)}</Typography.Paragraph> */}
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{fontSize: "18px"}}>{text}</Typography.Paragraph>
          {
            item.productInfo.nameKorean &&
            <Typography>{item.productInfo.nameKorean}</Typography>
          }
        </div>
      )
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      width: "10%",
      render: (quantity: number, item: CartItem) =>
        <Typography style={{ textAlign: "right", fontSize: "18px" }}>{quantity}{item.productInfo.isScalable ? "/kg" : ""}</Typography>
    },
    {
      title: "Price",
      dataIndex: "unitPrice",
      width: "18%",
      render: (price: number, item: CartItem) =>
        <>
          <Typography style={{ textAlign: "right", fontWeight: 400, fontSize: "18px" }}>${price.toFixed(2)}{item.productInfo.isScalable ? "/kg" : ""}</Typography>
          {/* <Typography style={{ textAlign: "right", color: item.promotionText ? "red" : "black", fontWeight: item.promotionText ? 700 : 400 }}>{item.promotionText && item.originalPrice && item.originalPrice > 0 ? `Save $${(item.originalPrice - price).toFixed(2)}` : null}</Typography> */}
        </>
    },
    {
      title: "Discount",
      dataIndex: "discountRatio",
      width: "10%",
      render: (discount: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", fontWeight: 300, fontSize: "18px" }}>
          {
            item.allowDiscount ?
              <div style={{ textAlign: "right" }}>{discount}%</div>
              :
              <div style={{ textAlign: "right" }}>N/A</div>
          }
        </div>
    },
    {
      title: "Amount",
      dataIndex: "total",
      width: "18%",
      render: (amount: number, item: CartItem) => {
        const isSpecial = (item.originalPrice && ((item.quantity * item.originalPrice) - amount) >= 0.01)
        return (
        <>
          <Typography style={{ textAlign: "right", fontWeight: 400 , fontSize: "18px"}}>${amount.toFixed(2)}</Typography>
          <Typography style={{ textAlign: "right", color: "red", fontWeight: 700, fontSize: "14px" }}>{isSpecial ? `Save $${((item.quantity * (item.originalPrice? item.originalPrice : 0)) - amount).toFixed(2)}` : null}</Typography>

        </>
        )
      }
    }
  ]

  return (
    <Table
      className="pageDetailTable pageDetailTableHeight900"
      columns={columns}
      dataSource={items ? items : itemsInCart}
      pagination={false}
      scroll={{
        y: height * 0.72
      }}
      rowClassName={(record, index) => { return record.itemId === "sp" || record.type === CartItemType.Promotion ? "yellow" : index % 2 > 0 ? "rowStyle1" : "rowStyle2" }}
      // style={{ width: tableWidthFactor ? width * tableWidthFactor : width * 0.6, height: "100%" }}
      rowKey={"itemId"}
      id="box"
    />
  )
}
