import "./Numpad.css"
import React, { useEffect, useRef, useState } from "react"
import { Input, Button, Modal, Typography, message } from "antd"
import useViewport from "../../hooks/useViewport"
import NumPad from "react-numpad"
import AlertModal from "./AlertModal"
// import CustomNumPad from "./CustomNumPad"

interface Props {
  title?: string
  value: string
  isVisible: boolean
  decimal: number
  negative: boolean
  onConfirm: (value: number) => void
  onDismiss: () => void
  onValueChange?: (value: string) => void
  containerStyle?: React.CSSProperties
}

const renderTitle = (title: "quantity" | "unitPrice" | "discount" | "weight" | string) => {
  const titles: Record<"quantity" | "unitPrice" | "discount" | "weight" | string, string> =
  {
    quantity: "Quantity",
    unitPrice: "Unit Price",
    discount: "Discount",
    weight: "Weight",
  }
  const showTitle: string | undefined = titles[title]
  if (showTitle) {
    return showTitle
  }
  // return startCase(title)
}

const NumpadModal = ({
  value,
  isVisible,
  containerStyle,
  onDismiss,
  decimal,
  negative,
  onConfirm,
  title = "Qty",
}: Props) => {
  const { width, height } = useViewport()
  const [tempValue, setTempValue] = useState<number | null>()
  const [tempDecimal, setTempDecimal] = useState<number>(decimal)
  const isTouched = useRef(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")
  const requestWeight = async () => {
    //发送称重指令
    //@ts-ignore
    if (window?.electronAPI) {
      await new Promise((resolve, reject) => {
        //@ts-ignore
        window?.electronAPI?.requestWeight((response: any) => {
          console.log('称重返回得是否是重量？', response)
          if (response !== "failed" && response !== "00.000") {
            //根据称重修改值
            setTempValue(response)
            resolve(true)
          } else {
            message.info("Can not get weight or weight is zero")
            reject(false)
          }
        });
      })
      // //@ts-ignore
      // window?.electronAPI?.requestWeight((response: any) => {
      //   console.log("称重指令已发送")
      // });
    }
  }

  // useEffect(() => {
  //   // 电子秤重量信息捕捉
  //   //@ts-ignore
  //   if (window?.electronAPI) {
  //     //@ts-ignore
  //     window.electronAPI.handleScale((event, value) => {
  //       console.log("the weight is ", Number(value))
  //       if (Number(value) > 0) {
  //         setTempValue(Number(value))
  //         // setCurWeight(Number(value))
  //       } else {
  //         message.error("Incorrect weight. Please reposition and scale again.")
  //       }
  //     })
  //   }
  // }, [])

  return (
    <Modal
      transitionName="none"
      maskTransitionName="none"
      centered
      // width={width * 0.4}
      width={"fit-content"}
      visible={isVisible}
      footer={null}
      onCancel={onDismiss}
      title={
        <Typography.Title level={3} style={{ margin: 0 }}>
          Current {renderTitle(title)} : <b style={{ fontSize: 40 }}>{value}{title === "discount" && "%"}</b>
        </Typography.Title>
      }
    >
      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignSelf: "center",
          width: "100%",
          marginLeft: "-10px",
          marginTop: "-20px"
        }}
      >
        <NumPad.Number
          onChange={(Value: string) => {
            if (!isTouched.current) {
              setTempValue(Number(Value.slice(-1)))
              setTempDecimal(Math.max(tempDecimal - 1, 0))
              isTouched.current = true
            } else {
              console.log("title",title, "Value", Number(Value), "originValue", Number(Value.slice(0, -1)))
              if (title === "discount" && Number(Value) >= 100) {
                console.log("//错误的discount")
                message.error("Discount must be less than 100%!")
                // setErrorMsg("Discount must be less than 100%!")
                // setIsAlertModalVisible(true)
                // setTempValue(Number(Value.slice(0, -1)))
              } else setTempValue(Number(Value))
            }
          }}
          value={
            // If the user has touched the button, always use the tempValue
            isTouched.current
              ? tempValue
              // Fix: Add a thin space to the default value to handle the edge case of adding up digit
              // In the first touch, When default value = 1, and we press 1, the tempValue should be 1,
              // but it will not overwrite the value in Numpad component (since to the Numpad, the value hasn't changed),
              // the numpad will concat the 1 after value and show '11' in the input field, 
              // so we add a space to the value to let the numpad know that they are different
              : String(value).length === 1
                ? "\u200A" + value
                : value
          }
          decimal={tempDecimal}
          negative={negative}
          inline={true}
          sync={true}
        />
        <div
          className="operation-bar"
          style={{
            width: "40%",
            marginTop: 20,
          }}
        >
          <Button
            onClick={() => {
              setTempValue((tempValue) =>
                Number(tempValue?.toString().slice(0, -1))
              )
            }}
            style={{
              fontSize: 22,
              width: "100%",
              height: "100%",
              marginLeft: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              backgroundColor: "#E3ECFF",
              borderRadius: "5px",
              border: "none"
            }}
          >
            Back
          </Button>
          <Button
            danger
            onClick={() => {
              setTempValue(0)
            }}
            style={{
              fontSize: 22,
              width: "100%",
              height: "100%",
              marginLeft: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              backgroundColor: "#F8E0E0",
              borderRadius: "5px",
              border: "none"
            }}
          >
            Clear
          </Button>
          <Button
            className="confirm"
            type="primary"
            onClick={() => {
              if (tempValue) {
                onConfirm(tempValue)
              } else {
                onConfirm(0)
                // message.error("Please input a number")
              }
            }}
            style={{
              fontSize: 22,
              width: "100%",
              height: "100%",
              marginLeft: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              // backgroundColor: "#F8E0E0",
              borderRadius: "5px"
            }}
          >
            OK
          </Button>
          {
            title === "weight" ?
            // title === "quantity" ?
              <Button
                className="cancel"
                color="warning"
                onClick={() => requestWeight()}
                // onClick={onDismiss}
                style={{
                  fontSize: 22,
                  width: "100%",
                  height: "100%",
                  marginLeft: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  backgroundColor: "#E3ECFF",
                  borderRadius: "5px",
                  border: "none"
                }}
              >
                Scale
              </Button>
              :
              <Button
                className="cancel"
                color="warning"
                // onClick={requestWeight}
                onClick={onDismiss}
                style={{
                  fontSize: 22,
                  width: "100%",
                  height: "100%",
                  marginLeft: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  backgroundColor: "#E3ECFF",
                  borderRadius: "5px",
                  border: "none"
                }}
              >
                Cancel
              </Button>
          }
          {/* <Button
            className="cancel"
            color="warning"
            onClick={requestWeight}
            // onClick={onDismiss}
            style={{
              fontSize: 22,
              width: "100%",
              height: "100%",
              marginLeft: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              backgroundColor: "#E3ECFF",
              borderRadius: "5px",
              border: "none"
            }}
          >
            Scale
          </Button> */}
        </div>
      </div>
    </Modal>
  )
}

export default NumpadModal
