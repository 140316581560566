import React, { useEffect, useState, useRef } from "react"

export default function Clock() {

    const [currentTime, setCurrentTime] = useState<string>()

    function tick() {
        setCurrentTime(new Date().toLocaleTimeString())
    }

    useEffect(() => {
        //启动时钟
        const clockTimer = setInterval(tick, 1000)
        return () => {
            //清除时钟
            clearInterval(clockTimer)
        }
    }, [])

    return (
        <>
            Current Time:
            <div id="clock" style={{ fontSize: 20, fontWeight: 700, marginLeft: 10, color: "#000" }}>{currentTime}</div>
        </>
    )
}