import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { Credential, InvoiceHead, PaymentTotal } from "../../models"
import { EmptyPaymentTotal } from "../../constants/data"
import { notification } from "antd"
import { retrieveLinklyData, storeLinklyData } from "../../utils/EFTPOS-functions"

interface PaymentState {
  paymentMethod: "cash" | "card" | "royalpay" | null
  paymentTotal: PaymentTotal | null
  currentInvoice: InvoiceHead | null
  showReceiptModal: boolean
  paymentType: "oneOff" | "split",
  paymentMethod1: "cash" | "card" | "royalpay" | null
  paymentMethod2: "cash" | "card" | "royalpay" | null
  paymentAmount1: number
  paymentAmount2: number
  paymentTotal1: PaymentTotal | null
  paymentTotal2: PaymentTotal | null
  paymentStep: "oneOff" | "split1" | "split2" | "finish"
  showRefundReceiptModal: boolean
  customerCopy: string[]
}

const initialState = {
  paymentMethod: null,
  paymentTotal: EmptyPaymentTotal,
  currentInvoice: null,
  showReceiptModal: false,
  paymentType: "oneOff",
  paymentMethod1: null,
  paymentMethod2: null,
  paymentAmount1: 0,
  paymentAmount2: 0,
  paymentTotal1: EmptyPaymentTotal,
  paymentTotal2: EmptyPaymentTotal,
  paymentStep: "oneOff",
  showRefundReceiptModal: false,
  customerCopy: []
} as PaymentState

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setReceiptCopy(state, action: PayloadAction<any>) {
      state.customerCopy = action.payload.customerCopy
    },
    setPaymentTotal(state, action: PayloadAction<any>) {
      state.paymentTotal = action.payload.paymentTotal
    },
    setCurrentInvoice(state, action: PayloadAction<any>) {
      state.currentInvoice = action.payload.invoice
    },
    setShowReceiptModal(state, action: PayloadAction<any>) {
      state.showReceiptModal = action.payload.isVisible
    },
    setPaymentMethod(state, action: PayloadAction<any>) {
      state.paymentMethod = action.payload.paymentMethod
    },
    setPaymentType(state, action: PayloadAction<any>) {
      state.paymentType = action.payload.paymentType
    },
    setSplitPaymentMethod1(state, action: PayloadAction<any>) {
      state.paymentMethod1 = action.payload.paymentMethod1
    },
    setSplitPaymentMethod2(state, action: PayloadAction<any>) {
      state.paymentMethod2 = action.payload.paymentMethod2
    },
    setSplitPaymentAmount1(state, action: PayloadAction<any>) {
      state.paymentAmount1 = action.payload.paymentAmount1
    },
    setSplitPaymentAmount2(state, action: PayloadAction<any>) {
      state.paymentAmount2 = action.payload.paymentAmount2
    },
    setPaymentTotal1(state, action: PayloadAction<any>) {
      state.paymentTotal1 = action.payload.paymentTotal1
    },
    setPaymentTotal2(state, action: PayloadAction<any>) {
      state.paymentTotal2 = action.payload.paymentTotal2
    },
    setPaymentStep(state, action: PayloadAction<any>) {
      state.paymentStep = action.payload.paymentStep
    },
    setAllPaymentTotal(state, action: PayloadAction<any>) {
      if (state.paymentStep === "oneOff") {
        state.paymentTotal = action.payload.paymentTotal
      } else if (state.paymentStep === "split1") {
        state.paymentTotal1 = action.payload.paymentTotal
      } else if (state.paymentStep === "split2") {
        state.paymentTotal2 = action.payload.paymentTotal
      }
    },
    clearPaymentProcedure(state, action: PayloadAction<any>) {
      state.paymentAmount1 = 0
      state.paymentAmount2 = 0
      state.paymentType = "oneOff"
      state.paymentMethod = null
      state.paymentMethod1 = null
      state.paymentMethod2 = null
      state.paymentTotal = EmptyPaymentTotal
      state.paymentTotal1 = EmptyPaymentTotal
      state.paymentTotal2 = EmptyPaymentTotal
      state.paymentStep = "oneOff"
    },
    setShowRefundReceiptModal(state, action: PayloadAction<any>) {
      state.showRefundReceiptModal = action.payload.isVisible
    },
  }
})

export const {
  setPaymentTotal,
  setCurrentInvoice,
  setShowReceiptModal,
  setPaymentMethod,
  setPaymentType,
  setSplitPaymentMethod1,
  setSplitPaymentMethod2,
  setSplitPaymentAmount1,
  setSplitPaymentAmount2,
  setPaymentTotal1,
  setPaymentTotal2,
  setPaymentStep,
  setAllPaymentTotal,
  clearPaymentProcedure,
  setShowRefundReceiptModal,
  setReceiptCopy
} = paymentSlice.actions

export default paymentSlice.reducer
