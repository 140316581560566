import {
  Button,
  Descriptions,
  Divider,
  PageHeader,
  Popconfirm,
  Spin,
  Typography,
  Image,
  Table
} from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Product, Order, InvoiceHead, InvoiceItem, Customer } from "../../models"

type Props = {
  customer: Customer
}

export default function CustomerDetail({ customer }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <PageHeader
      className="detailsPageHeader"
      ghost={false}
      backIcon={false}
      // title="Customer Detail"
    >
      <Spin tip="Loading..." spinning={isLoading}>
      <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Customer Name
          </div>
          <div className="detailDescValue">
          {customer.name}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Customer Code
          </div>
          <div className="detailDescValue">
          {customer.CustomerCode}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/customer-icon@2x.png"} className="pageIcon16" />Mobile
          </div>
          <div className="detailDescValue">
          {customer.Mobile}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Loyalty Points
          </div>
          <div className="detailDescValue">
          {customer.Points}
          </div>
        </div>
      </Spin>
    </PageHeader>
  )
}
