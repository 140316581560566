import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Button, PageHeader, Divider, Tag, message } from "antd"
import { UserOutlined, LaptopOutlined, NotificationOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { BrowserRouter, Routes, Route, Link, useLocation, useMatch, Router } from "react-router-dom"
import { CartItem, StyleSheet } from "../models"
import TotalSection from "../components/Checkout/TotalSection"
import { Provider, RootStateOrAny, useSelector } from "react-redux"
import CheckoutList from "../components/Checkout/CheckoutList"
import useViewport from "../hooks/useViewport"
import { reloadCustomerScreen } from "../utils/customerscreen-functions"
import ReactDOMServer from "react-dom/server"
import { PersistGate } from "redux-persist/integration/react"
import store from "../redux/store"
import reduxConfigure from "../redux/store"
import ReactDOM from "react-dom"
import ProgressModal from "../components/common/ProgressModal"
import CustomerInfoModal from "../components/Checkout/Customer/CustomerInfoModal"
import PaymentModal from "../components/Checkout/Payment/PaymentModal"
import CashModal from "../components/Checkout/Payment/CashModal"
import CardPaymentModal from "../components/Checkout/Payment/CardPaymentModal"
import ReceiptModal from "../components/Checkout/Payment/ReceiptModal"

const antdStyle = require('antd/dist/antd.css')

const { Header, Content, Sider, Footer } = Layout

export const openSecondWindow = async () => {
  return new Promise(() => {
    console.log('forwarding refresh request to the main process...')

    const data = document.getElementById("customerscreen")?.outerHTML;
    const cssPath = process.env.PUBLIC_URL + "/css/antd.css"
    // const cssPath = window.location.origin + "/css/antd.css"
    const meta = `<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />`
    const head = `<head><link rel="stylesheet" type="text/css" href=${cssPath}></head>`
    const html = meta + head + data

    console.log("html: ", html);
    //@ts-ignore
    let blob = new Blob([html], { type: 'text/html' });
    let url = URL.createObjectURL(blob);

    //@ts-ignore
    window.electronAPI.customerComponent(url, (response) => {
      console.log('Main: ', response);
    });
  });
}

export default function CustomerScreen() {
  const [isSignIn, setIsSign] = useState(true)
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { itemsInCart: reduxItemInCart, total: reduxTotal } = Checkout
  const { servingCustomer: reduxCustomer } = Customer
  // const { itemsInCart, total } = Checkout
  // const { servingCustomer } = Customer
  const { height, width } = useViewport()
  console.log("useViewport的宽高是", height, width)
  const { store, persistor } = reduxConfigure()
  const [isInProgress, setIsInProgress] = useState(false)
  const [isInRoyalPay, setIsInRoyalPay] = useState(false)
  const [progressMsg, setProgressMsg] = useState("")
  const [itemsInCart, setItemsInCart] = useState(reduxItemInCart)
  const [total, setTotal] = useState(reduxTotal)
  const [servingCustomer, setServingCustomer] = useState(reduxCustomer)
  const [qrcode, setQrcode] = useState()
  const [messageModalStyle, setMessageModalStyle] = useState<any>()
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<any>()
  const [showMessageTitle, setShowMessageTitle] = useState<string>()

  useEffect(() => {
    //@ts-ignore
    if (window?.electronAPI) {
      // @ts-ignore
      window.electronAPI.handleCustomerScreenUpdate((event, value) => {
        console.log('received!!!', value, store)
        setItemsInCart(value.Checkout?.itemsInCart)
        setTotal(value.Checkout?.total)
        console.log("total是否有surcharge???", value.Checkout?.total)
        setServingCustomer(value.Customer?.servingCustomer)
        console.log("客户信息。。。。。。。。。。。", value.Customer?.servingCustomer)
        // store.dispatch({type: 'customer/overWrite', payload: {...value.Customer}})
        store.dispatch({ type: 'checkout/overWrite', payload: { ...value.Checkout } })
      })
      // @ts-ignore
      window.electronAPI.handleCustomerScreenQRCode((event, qrCode, visible) => {
        console.log('QR Code received!!!', qrCode, visible)
        if (visible) {
          setShowMessageModal(false)
          setQrcode(qrCode)
        }
        setIsInRoyalPay(visible)
      })
      // @ts-ignore
      window.electronAPI.handleSignIn((event, value) => {
        console.log('User SignIn received!!!', value)
        setIsSign(value)
      })
      // // @ts-ignore
      // window.electronAPI.handleInvoice((event, value) => {
      //   console.log('发票 received!!!', value)
      //   setCurrentInvoice(value)
      // })
      // @ts-ignore
      window.electronAPI.handleModalVisible((event, value) => {
        console.log('弹窗同步 received!!!', value)
        setShowMessageModal(false)
        //暂时关闭弹窗同步，只保留royalpay
        if (value === "isCustomerModalVisible") {
          // setIsCustomerModalVisible(true)
          setShowMessageModal(true)
          setMessageModalStyle({})
          setShowMessageTitle("Customer Information Note")
          setShowMessage(
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
              <div className="modalTitle" >Are you a member?</div>
              <div className="normalLabel" style={{ fontSize: "24px", marginTop: "50px" }}>If so, please let us know your phone number.</div>
              <div className="normalLabel" style={{ fontSize: "24px", marginTop: "25px" }}>If not, would you like join us today?</div>
            </div>
          )
        }
        // if (value.indexOf("customerInfo") > -1) {
        //   // 展示customer信息
        //   const info = JSON.parse(value)
        //   setShowMessageModal(true)
        //   setShowMessageTitle("Customer Information")
        //   setMessageModalStyle({})
        //   setShowMessage(
        //     <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
        //       <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
        //         <div className="normalLabel">Name:</div>
        //         <div className="normalValue" style={{ fontSize: "24px" }}>{info.customername}</div>
        //       </div>
        //       <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
        //         <div className="normalLabel">Points:</div>
        //         <div className="normalValue" style={{ fontSize: "24px" }}>{info.points}</div>
        //       </div>
        //       <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
        //         <div className="normalLabel">Mobile:</div>
        //         <div className="normalValue" style={{ fontSize: "24px" }}>{info.mobile}</div>
        //       </div>
        //     </div>
        //   )
        // }
        if (value === "isCheckoutPaymentModalVisible") {
          setShowMessageModal(true)
          setShowMessageTitle("Payment Note")
          setMessageModalStyle({})
          setShowMessage(
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
              <div className="modalTitle" >We accept the following payment methods:</div>
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "25px" }}>
                <div>
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/cashPayMethod-icon.png"} style={{ width: 40, height: 40 }} />
                  <div className="normalLabel"
                    style={{ fontSize: 20, color: "#333333" }}
                  >Cash</div>
                </div>
                <div>
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/masterCard-icon.png"} style={{ width: 70, height: 40 }} />
                  <div className="normalLabel"
                    style={{ fontSize: 20, color: "#333333" }}
                  >Mastercard</div>
                </div>
                <div>
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/visa-icon.png"} style={{ width: 70, height: 40 }} />
                  <div className="normalLabel"
                    style={{ fontSize: 20, color: "#333333" }}
                  >Visa</div>
                </div>
                {/* <div>
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/royalPayMethod-icon.png"} style={{ width: 40, height: 40 }} />
                  <div className="normalLabel"
                    style={{ fontSize: 20, color: "#333333" }}
                  >Royal Pay</div>
                </div> */}

              </div>
            </div>
          )
        }
        if (value.indexOf("isCardPaymentModalVisible") > -1) {
          const info = JSON.parse(value)
          setShowMessageModal(true)
          setMessageModalStyle({ position: "absolute", left: 20 })
          setShowMessageTitle("Card Payment Note")
          setShowMessage(
            <>
              <div className="modalTitle" style={{ marginTop: "25px" }}>Please tap your card on the checkout terminal</div>
              {info.surCharge && info.surCharge !== 0 ?
                <div className="normalLabel" style={{ textAlign: "center", marginTop: "50px" }}>
                  A 10 cents surcharge for card payments under $5
                </div>
                : null
              }
            </>
          )
        }
      })
    }

  }, [])

  interface LastItemProps {
    lastItem: CartItem
  }
  const LastItemComponent = ({ lastItem }: LastItemProps) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <div style={{ width: "280px", height: "280px", alignItems: "center" }}>
            <img src={lastItem.productInfo?.image} style={{ width: "280px", height: "280px", maxWidth: '280px', maxHeight: '280px', alignSelf: "center" }} />
          </div>
          <div style={{ width: "100%", height: "100%", alignItems: "center" }}>
            <div className="detailDescriptions" style={{ height: "30px" }}>
              <div className="detailDescLabel" style={{ width: "100px" }}>
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />Quantity
              </div>
              <div className="detailDescValue" style={{ fontSize: "18px", fontWeight: "bold" }}>
                {lastItem.quantity}
              </div>
            </div>
            <div className="detailDescriptions" style={{ height: "30px" }}>
              <div className="detailDescLabel" style={{ width: "100px" }}>
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/points-icon@2x.png"} className="pageIcon16" />Unit Price
              </div>
              <div className="detailDescValue" style={{ fontSize: "18px", fontWeight: "bold" }}>
                ${lastItem.unitPrice?.toFixed(2)}
              </div>
            </div>
            <div className="detailDescriptions" style={{ height: "30px" }}>
              <div className="detailDescLabel" style={{ width: "100px" }}>
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />Discount
              </div>
              <div className="detailDescValue" style={{ fontSize: "18px", fontWeight: "bold" }}>
                {lastItem.discountRatio + "%"}
              </div>
            </div>
            <div className="detailDescriptions" style={{ backgroundColor: "#FFEEEE" }}>
              <div className="detailDescLabel" style={{ color: "#2C6EF1", width: "100px" }}>
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Subtotal
              </div>
              <div className="detailDescValue" style={{ fontSize: "30px", fontWeight: "bold" }}>
                ${lastItem.total?.toFixed(2)}
              </div>
            </div>
            {
              lastItem.originalPrice && (lastItem.originalPrice * lastItem.quantity) - lastItem.total > 0 ?
                <div className="detailDescriptions" style={{ height: "30px", backgroundColor: "#FFEEEE" }}>
                  <div className="detailDescLabel" style={{ color: "#2C6EF1", width: "100px", fontWeight: 500, fontSize: "20px" }}>
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />SAVE
                  </div>
                  <div className="detailDescValue" style={{ fontSize: "30px", fontWeight: "bold", color: "#FC2C2C" }}>
                    {lastItem.originalPrice ? ((lastItem.originalPrice * lastItem.quantity) - lastItem.total).toFixed(2) : 0}
                  </div>
                </div>
                :
                null
            }
          </div>
        </div>
        <div className="detailDescriptions" style={{ minHeight: "24px", fontSize: "24px", fontWeight: "bold" }}>
          {lastItem.productInfo?.nameKorean ? itemsInCart[itemsInCart.length - 1].productInfo?.nameKorean : itemsInCart[itemsInCart.length - 1].productInfo?.name}
        </div>
      </>
    )
  }

  const RoyalPayModal = () => {
    return (
      <Modal
        transitionName="none"
        maskTransitionName="none"
        centered
        className="modalPage"
        title={<div className="modalTitle">RoyalPay<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
        visible={isInRoyalPay}
        footer={null}
        width={500}
        zIndex={500}
        closable={false}
        style={{ height: 700 }}
      >
        <div className="normalLabel" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          Scan the QR code with WeChat and Alipay
        </div>
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img style={{ width: 400, height: 400, marginBottom: 20, borderRadius: "10px" }} src={qrcode} />
        </div>
        <div className="normalLabel" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          OR Present your payment code to the RoyalPay scanner
        </div>
      </Modal>
    )
  }
  const ShowMessageModal = () => {
    return (
      <Modal
        transitionName="none"
        maskTransitionName="none"
        // centered={total.surcharge && total.surcharge !== 0 ? false : true}
        className="modalPage"
        visible={showMessageModal}
        title={<div className="modalTitle">{showMessageTitle}<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
        footer={null}
        width={800}
        zIndex={500}
        closable={false}
        style={messageModalStyle}
      >
        {showMessage}
      </Modal>
    )
  }

  return (
    <>
      {isSignIn ?
        <div style={{ width: width - 10, height: height - 10 }} className="customerscreenContainer">
          <ProgressModal
            isVisible={isInProgress}
            description={progressMsg}
            closable={true}
            onCancel={() => setIsInProgress(false)}
          />
          {isInRoyalPay ?
            <RoyalPayModal />
            : null
          }
          {showMessageModal ?
            <ShowMessageModal />
            : null
          }
          <div className="customerscreenReminder">
            {
              servingCustomer ?
                <div className="reminder" style={{height: '120px', fontSize: '22px'}}>
                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignContent: 'center', fontWeight: 700 }}>
                    {"Customer: " + servingCustomer.name + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "Points: " + servingCustomer.Points + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "Mobile: " + servingCustomer.Mobile}
                    {servingCustomer.Group && servingCustomer.Group.GroupName ?
                      <div style={{ color: 'red' }}>
                        {servingCustomer.Group && servingCustomer.Group.GroupName ? `Congratulations! You're eligible for our ${servingCustomer.Group.GroupName} Discount. ` : ''}<br />
                        {servingCustomer.Group && servingCustomer.Group.GroupName ? `${servingCustomer.Group.DiscountValue}% discount on every non-special item in your cart.` : ''}
                      </div>
                      : null
                    }
                  </div>
                </div>
                :
                <div className="reminder errorReminder">
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/reminder-icon.png"} className="pageIcon16" />
                  {"Customer Not Selected. Please Scan your Membership Card."}
                </div>
            }

          </div>
          <div className="customerscreenTitle">
            <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/invoice-icon.png"} className="pageIcon" /></div>
            <div className="pageTitle">ITEMS IN CART</div>
          </div>
          <div className="customerscreenTotal">
            <TotalSection
              item={total}
              containerStyle={{
                backgroundColor: "white", flexDirection: "column", display: "flex", width: "100%", height: "100%"
                //  margin: 10, width: width * 0.3, height: height * 0.25 
              }}
            />
          </div>
          <div className="customerscreenList">
            <CheckoutList items={itemsInCart} />
            {/* <CheckoutList tableHeightFactor={0.8} /> */}
          </div>
          <div className="customerscreenLast">
            <PageHeader
              // className="detailsPageHeader"
              // style={{ height: "350px" }}
              title={
                <div className="detailNo"><img src={process.env.PUBLIC_URL + "/images/assets/icons/product2-icon.png"} className="pageIcon" /> Last Scanned Item</div>
              }
            >
              {
                itemsInCart && itemsInCart?.length > 0 ?
                  <LastItemComponent lastItem={itemsInCart[itemsInCart.length - 1]} />
                  :
                  <div className="detailDescriptions" style={{ height: "300px" }}>
                    <div className="detailDescLabel" style={{ textAlign: "center", color: "#2C6EF1", width: "100%" }}>
                      Please Scan your First Item
                    </div>
                  </div>
              }
            </PageHeader>
          </div>
        </div >
        :
        <div style={{ width, height, display: "flex", padding: "10px", fontSize: "40px", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
          Sorry, this registers is currently closed.
        </div>
      }
    </>

  )
}

