import "../../css/Global.css"
import React, { useEffect, useState, useRef, useCallback } from "react"
import { notification, Typography, Input, Button, Form, ConfigProvider, Radio, Modal, Divider } from "antd"
import { DeleteFilled } from "@ant-design/icons"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { CartItem, StyleSheet, Total, Order, CartItemType } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { addItemToCart, updateCartItem, updateTotal, removeItemFromCart, clearCart, showKeyboard, updateAllCartItems } from "../../redux/Checkout/slice"
import { addOrder, addHoldingOrder } from "../../redux/Order/slice"
import { calculateCartTotal, calculateGSTAmount, handleChangeCartItemQuantity } from "../../utils/Global"
import LoginModal from "../Login/LoginModal"
import NumPad from "react-numpad"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import AlertModal from "../common/AlertModal"
import { SmileOutlined } from "@ant-design/icons"
import NumpadModal from "../common/NumpadModal"
import { UserState } from "../../redux/User/slice"
import { getProductDisplayName } from "../../utils/getProductDisplayName"
import ScrollButtons from "../common/ScrollButtons"
import VirtualTable from "./VirtualTableForCart"
import { loginUser } from "../../api/user"
import { GlobalState, freshBarcode, freshWeight } from "../../redux/Globals/slice"
import KeyboardModal from "../common/KeyboardModal"

const { Title } = Typography
let buffer = '' // buffer for constructing the barcode from key presses

interface CheckoutCartProps {
  tableHeight?: number
  tableRows?: number
  scrollableHeight?: number
  isScanable?: boolean
  setIsScanable?: (value: boolean) => void
}

export default function CheckoutCart({ tableHeight, tableRows, scrollableHeight, isScanable, setIsScanable }: CheckoutCartProps) {
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, total,
    //  isKeyboardVisible 
  } = Checkout
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, holdingOrders } = Order
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const { isSignIn, user } = User
  console.log('购物车用户信息', user)
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")

  //things need to delete item
  const [keyboardLanguage, setKeyboardLanguage] = useState("eng")
  const [isApproveModalVisible, setIsApproveModalVisible] = useState<boolean>(false)
  const keyboardRef = useRef<any>()
  const inputRef = useRef<any>()
  const approveModalVisible = useRef<boolean>(false)
  const editRef = useRef<any>()
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
  const [form] = Form.useForm()
  const [isScanMode, setIsScanMode] = useState(true)
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)

  // Thins Need to set for edit item
  const [isNumpadVisible, setIsNumpadVisible] = useState(false)
  const [editItem, setEditItem] = useState<CartItem | null>(null)
  const [editType, setEditType] = useState<"quantity" | "unitPrice" | "discount" | string>("quantity")
  const [numpadValue, setNumpadValue] = useState("0")
  const [numpadDecimal, setNumpadDecimal] = useState(2)
  const [numpadNegative, setNumpadNegative] = useState(false)




  useEffect(() => {
    // let box = document.getElementById("box")
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    let v2 = document.getElementsByClassName("virtual-grid")[0]
    // console.log("v: ", document.getElementsByClassName("ant-table-body"))
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
    if (v2) {
      // console.log("v2 scroll height: ", v2.scrollHeight)
      // console.log("v2 scroll top: ", v2.scrollTop)
      v2.scrollTop = v2.scrollHeight
    }
  }, [itemsInCart.length])

  const handleScan = (value: string) => {
    console.log("扫manager码:::", value, isApproveModalVisible, approveModalVisible)
    if (!value || value === '' || !approveModalVisible.current) {
      console.log("扫manager码不满足条件")
      return
    }
    console.log("manager码扫到：", value)
    // todo 调用员工码识别api
    onManagerLogin({ barcode: value })
    // setSearchCustomerValue(value)
    // searchCustomer(value)
  }

  const scannerCallback = useCallback((event) => {
    console.log('event.key', event.key)
    let data = buffer || ''
    if (event.key === '') {
      return
    }
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      console.log('测试scanner', buffer, isApproveModalVisible)
      buffer = ''
      // if (isApproveModalVisible) {
      handleScan(data)
      // }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [])
  useEffect(() => {
    if (Globals.barcode) {
      handleScan(Globals.barcode)
      dispatch(freshBarcode({
        barcode: ""
      }))
    }
  }, [Globals.barcode])


  const handleValueChange = (input: string, editItem: CartItem, type: string) => {
    let newCartItems: Array<CartItem> = []
    const numberInput = Number(input)
    switch (type) {
      case "quantity": case "weight":
        if (numberInput > 9999) {
          setErrorMsg("Quantity must be less than 9999!")
          setIsAlertModalVisible(true)
        } else if (numberInput < 0) {
          const newPrice = numberInput * editItem.unitPrice * (1 - editItem.discountRatio / 100)
          const newFields = {
            quantity: numberInput,
            total: newPrice,
            type: "refund",
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          newCartItems = itemsInCart.map((item: CartItem) =>
            item.itemId === editItem.itemId
              ? { ...item, ...newFields }
              : item
          )
          dispatch(updateCartItem({ id: editItem.itemId, input: newFields }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        } else {
          const newPrice = numberInput * editItem.unitPrice * (1 - editItem.discountRatio / 100)
          const newCartItem = {
            ...editItem,
            quantity: numberInput,
            total: newPrice,
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          const newCartItems = handleChangeCartItemQuantity(newCartItem, itemsInCart)
          dispatch(updateAllCartItems({ cartItems: newCartItems }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        }
        break
      case "unitPrice":
        if (numberInput < 0) {
          setErrorMsg("Price must be larger than 0!")
          setIsAlertModalVisible(true)
        } else if (numberInput > 9999) {
          setErrorMsg("Price must be less than $9999!")
          setIsAlertModalVisible(true)
        } else {
          const newPrice = numberInput * editItem.quantity * (1 - editItem.discountRatio / 100)
          const newFields1 = {
            unitPrice: numberInput,
            total: newPrice,
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          newCartItems = itemsInCart.map((item: CartItem) =>
            item.itemId === editItem.itemId
              ? { ...item, ...newFields1 }
              : item
          )
          dispatch(updateCartItem({ id: editItem.itemId, input: newFields1 }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        }
        break
      case "discount":
        if (numberInput < 0 || numberInput > 100) {
          setErrorMsg("Discount ratio must between 0 and 100!")
          setIsAlertModalVisible(true)
        } else {
          const newPrice = editItem.quantity * editItem.unitPrice * (1 - numberInput / 100)
          const newFields2 = {
            discountRatio: numberInput,
            total: newPrice,
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          newCartItems = itemsInCart.map((item: CartItem) =>
            item.itemId === editItem.itemId
              ? { ...item, ...newFields2 }
              : item
          )
          dispatch(updateCartItem({ id: editItem.itemId, input: newFields2 }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        }
        break
    }
  }

  const onConfirmValue = (value: number) => {
    handleValueChange(value.toString(), editItem!, editType)
    setIsNumpadVisible(false)
  }

  const columns = [
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>No</Typography.Text>,
      dataIndex: "itemId",
      key: "id",
      width: "5%",
      // width: 50,
      ellipsis: false,
      render: (text: string, record: any, index: number) => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>{String(index + 1).padStart(2, '0')}</Typography.Text>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Description</Typography.Text>,
      // dataIndex: "itemId",
      dataIndex: ["productInfo", "name"],
      key: "productId",
      width: "40%",
      render: (text: any, item: CartItem) => (
        <div>
          {
            item && item.promotionText ?
              <Typography style={{ fontSize: width > 1500 ? 20 : 15, fontWeight: "bold", }}>
                {item.promotionText.split("/").map(str => <div style={{ color: str.indexOf("WAS") > -1 ? "black" : "red" }}>{str}<br /></div>)}
              </Typography>
              :
              null
          }
          {
            item && item.productInfo ?
              <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>{getProductDisplayName(item.productInfo)}</Typography>
              :
              null
          }
        </div>
      )
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Qty</Typography.Text>,
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      render: (quantity: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {
            item.type === "promotion" || item.promotionText && item.promotionText.indexOf("Buy") > -1 ?
              <Typography style={{ fontSize: 20, alignSelf: "center" }}>{quantity}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>kg</div> : ""}</Typography>
              :
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                onClick={() => {
                  if (item.productInfo.isScalable) {
                    setEditType("weight")
                  } else {
                    setEditType("quantity")
                  }
                  setEditItem(item)
                  editRef.current = item
                  setNumpadValue(quantity.toString())
                  setNumpadDecimal(4)
                  setNumpadNegative(true)
                  setIsNumpadVisible(true)
                }}
              >
                <Typography
                  style={{ fontSize: 20, alignSelf: "center", color: "blue", textDecoration: "underline" }}
                >
                  {quantity}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>kg</div> : ""}
                </Typography>
              </div>
          }
        </div>

    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Price</Typography.Text>,
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: "15%",
      render: (price: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {
            (item.type === CartItemType.Promotion || item.promotionText) && price > 0 ?
              <Typography style={{ fontSize: 20, alignSelf: "center", color: item.promotionText ? "red" : "black" }}><>${price.toFixed(2)}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>/kg</div> : ""}</></Typography>
              :
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                onClick={() => {
                  if (user?.EditSalesPrice) {
                    setEditType("unitPrice")
                    setEditItem(item)
                    editRef.current = item
                    setNumpadValue(price.toFixed(2))
                    setNumpadDecimal(3)
                    setNumpadNegative(false)
                    setIsNumpadVisible(true)
                  } else {
                    notification.error({
                      message: "Sorry, you can not update product price."
                    })
                  }

                }}
              >
                <Typography
                  style={{ fontSize: 20, alignSelf: "center", color: "blue", textDecoration: "underline" }}
                >
                  ${price.toFixed(2)}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>/kg</div> : ""}
                </Typography>
              </div>
          }
        </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Discount</Typography.Text>,
      dataIndex: "discountRatio",
      key: "discountRatio",
      width: "10%",
      render: (discount: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          {
            item.allowDiscount ?
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
              // onClick={() => {
              //   setEditType("discount")
              //   setEditItem(item)
              //   editRef.current = item
              //   setNumpadValue(discount.toFixed(0))
              //   setNumpadDecimal(0)
              //   setNumpadNegative(false)
              //   setIsNumpadVisible(true)
              // }}
              >
                {/* <Typography
                  style={{ fontSize: 20, alignSelf: "center", color: "blue", textDecoration: "underline" }}
                > */}
                <Typography
                  style={{ fontSize: 20, alignSelf: "center" }}
                >
                {discount.toFixed(0)}%
              </Typography>
              </div>
              :
  <Typography style={{ fontSize: 18, alignSelf: "center" }}>N/A</Typography>
}
        </div >
    },
// {
//   title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>GST</Typography.Text>,
//   dataIndex: "gstRatio",
//   key: "gstRatio",
//   width: "5%",
//   render: (gst: number, item: CartItem) =>
//     <div>
//       {
//         item.applyGst ?
//           <Typography style={{ fontSize: 18 }}>{gst.toFixed(0)}%</Typography>
//           :
//           <Typography style={{ fontSize: 18, alignSelf: "center" }}>N/A</Typography>
//       }
//     </div>
// },
{
  title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Amount</Typography.Text>,
    dataIndex: "total",
      key: "total",
        width: "10%",
          render: (amount: number, item: CartItem) => {
            const isSpecial = (item.originalPrice && ((item.quantity * item.originalPrice) - amount) >= 0.01)
            return (
              <>
                <Typography style={{ fontSize: "18px", textAlign: "right", fontWeight: 400 }}>${amount.toFixed(2)}</Typography>
                <Typography style={{ fontSize: "14px", textAlign: "right", color: "red", fontWeight: 700, lineHeight: "14px" }}>{isSpecial ? `Save $${((item.quantity * (item.originalPrice ? item.originalPrice : 0)) - amount).toFixed(2)}` : null}</Typography>
              </>
            )
          }
},

  ]

const onPageUp = () => {
  // setPageUpDown(pageUpDown - 1)
  let v1 = document.getElementsByClassName("virtual-grid")[0]
  // let v1 = document.getElementsByClassName("ant-table-body")[0]
  if (!v1) return
  v1.scrollTop -= height * 0.545
}

const onPageDown = () => {
  // setPageUpDown(pageUpDown + 1)
  let v1 = document.getElementsByClassName("virtual-grid")[0]
  // let v1 = document.getElementsByClassName("ant-table-body")[0]
  if (!v1) return
  v1.scrollTop += height * 0.545
}
const onRowUp = () => {
  // setPageUpDown(pageUpDown - 1)
  let v1 = document.getElementsByClassName("virtual-grid")[0]
  // let v1 = document.getElementsByClassName("ant-table-body")[0]
  if (!v1) return
  v1.scrollTop -= 70
}

const onRowDown = () => {
  // setPageUpDown(pageUpDown + 1)
  let v1 = document.getElementsByClassName("virtual-grid")[0]
  // let v1 = document.getElementsByClassName("ant-table-body")[0]
  if (!v1) return
  v1.scrollTop += 70
}

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center', height: height * 0.25, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>No Items In Cart</p>
  </div>
);

const deleteItem = (editItem: any) => {
  const removeItemPromise = () => new Promise((resolve, reject) => {
    const newCartItems = itemsInCart.filter((itemInCart: CartItem) => { return editItem?.itemId !== itemInCart.itemId })
    dispatch(removeItemFromCart({ item: editItem }))
    // const newTotal = calculateCartTotal(newCartItems)
    // dispatch(updateTotal({ input: newTotal }))
    resolve("finish")
  })
  removeItemPromise()
    .then(() => {
      setIsConfirmModalVisible(false)
      // reloadCustomerScreen()
    })
    .catch((e) => console.log('删除购物车明细错误：', e))

}

const onChangeMode = (e: any) => {
  setIsScanMode(e.target.value)
}

const onManagerLogin = async (values: any) => {
  // TODO: verify manager id
  const result: any | undefined = await loginUser(values.username, values.password, values.barcode)
  console.log("manager approve login result: ", result)
  if (result?.user && result.user.VoidSalesItem) {//判断是否有的授权删除明细的授权
    // if (result?.user) {
    deleteItem(editRef.current)
    // setIsConfirmModalVisible(false)
    approveModalVisible.current = false
  } else {
    notification.error({
      message: "Manager Barcode Verify Failed Or Non-Authorized",
      duration: 10
    })

  }
  setIsApproveModalVisible(false)
  setIsScanable && setIsScanable(true)
}

const ManagerLogin = () => (

  // <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
  <Modal
    transitionName="none"
    maskTransitionName="none"
    className="modalPage"
    title={<div className="modalTitle">Delete Waiting for Approval<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
    visible={isApproveModalVisible}
    footer={null}
    onCancel={(e) => {
      setIsApproveModalVisible(false)
      approveModalVisible.current = false
      setIsScanable && setIsScanable(true)
    }}
    width={800}
  >
    <KeyboardModal
      ref={keyboardRef}
      isVisible={isKeyboardVisible}
      language={keyboardLanguage}
      onHideKeyboard={() => {
        setIsKeyboardVisible(false)
      }}
      onSwitchLanguage={() => {
        if (keyboardLanguage === "eng") {
          setKeyboardLanguage("kor")
        } else {
          setKeyboardLanguage("eng")
        }
      }}
      onConfirm={(value: string) => {
        console.log('keyboard modal confirm...', value)
        // if (value && value !== '000001') {
        //   setSearchCustomerValue(value)
        //   searchCustomer(value)
        // } else if (value === '000001') {
        //   setErrorMsg("You can not redeem points with Cash Customer!")
        //   setIsAlertModalVisible(true)
        // }
        setIsKeyboardVisible(false)
      }}
      onSelect={(value) => {
        console.log('keyboard modal select...', inputRef.current, value)
        form.setFieldsValue({ [inputRef.current]: value })
        // inputRef.current === "password"? setPasswordValue(value) : setUsernameValue(value)
      }}
    // mask={false}
    // initialValue={form.getFieldValue(inputRef.current ?? "uesername")}
    />
    <div style={{ marginBottom: 50, display: "flex", flexDirection: "row" }}>
      <Typography style={{ fontWeight: "bold", marginRight: 10 }}>Login Method: </Typography>
      <Radio.Group onChange={onChangeMode} value={isScanMode}>
        <Radio value={true}>ID Card</Radio>
        <Radio value={false}>password</Radio>
      </Radio.Group>
    </div>
    {
      isScanMode ?
        <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Please scan your ID</Typography>
        :
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          // initialValues={{ remember: true, username: "admin@aibuild.com", password: "password" }}
          onFinish={onManagerLogin}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              onClick={() => {
                inputRef.current = "username"
                console.log('显示keyboard属性', keyboardRef)
                // keyboardRef.current.setInput(form.getFieldValue(inputRef.current ?? "uesername"))
                setIsKeyboardVisible(true)
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              onClick={() => {
                inputRef.current = "password"
                setIsKeyboardVisible(true)
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
    }
    {/* </div> */}
  </Modal>
)

return (
  <div>
    {isLoginModalVisible ?
      <LoginModal
        isVisible={isLoginModalVisible}
        setVisibility={(value: boolean) => {
          setIsLoginModalVisible(value)
        }}
      />
      : null
    }

    <AlertModal
      isVisible={isAlertModalVisible}
      description={errorMsg}
      buttonText={"OK"}
      onCancel={() => setIsAlertModalVisible(false)}
      onConfirm={() => setIsAlertModalVisible(false)}
    />
    {isNumpadVisible ?
      <NumpadModal
        title={editType}
        value={numpadValue}
        isVisible={isNumpadVisible}
        decimal={numpadDecimal}
        negative={numpadNegative}
        onConfirm={onConfirmValue}
        onDismiss={() => {
          setIsNumpadVisible(false)
        }}
      />
      : null
    }
    {
      isApproveModalVisible ?
        <ManagerLogin />
        : null
    }
    <ActionConfirmModal
      isVisible={isConfirmModalVisible}
      description={"Are you sure to remove this item from cart?"}
      actionText={"Remove"}
      cancelText={"Cancel"}
      onConfirm={() => {
        if (editItem) {
          //添加managerApprove
          //@ts-ignore
          if (!user || !user.VoidSalesItem) {
            setIsApproveModalVisible(true)
            approveModalVisible.current = true
            setIsScanable && setIsScanable(false)
          } else {
            deleteItem(editItem)
          }
          // const removeItemPromise = () => new Promise((resolve, reject) => {
          //   const newCartItems = itemsInCart.filter((itemInCart: CartItem) => { return editItem.itemId !== itemInCart.itemId })
          //   dispatch(removeItemFromCart({ item: editItem }))
          //   // const newTotal = calculateCartTotal(newCartItems)
          //   // dispatch(updateTotal({ input: newTotal }))
          //   resolve("finish")
          // })
          // removeItemPromise()
          //   .then(() => {
          //     // reloadCustomerScreen()
          //   })
          //   .catch((e) => console.log(e))
        }
      }}
      onCancel={() => {
        setIsConfirmModalVisible(false)
        setEditItem(null)
        editRef.current = null
      }}
      rightButtonDanger={true}
    />

    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <VirtualTable
        // <Table
        className="pageDetailTable"
        rowClassName={(record, index) => { return record.itemId === "sp" || record.type === CartItemType.Promotion ? "yellow" : index % 2 > 0 ? "rowStyle1" : "rowStyle2" }}
        size="small"
        columns={columns.concat(isSignIn ? [
          {
            title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Action</Typography.Text>,
            dataIndex: "code",
            key: "action",
            width: "10%",
            render: (text: any, item: CartItem) =>
              // item.type === "promotion" && item.specialInfo?.Type === 'BuyNGetOneFree' ?
              //   <></>
              //   :
              <button
                style={{
                  border: "none",
                  // backgroundColor: "red",
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.0)"
                }}
                onClick={() => {
                  setEditItem(item)
                  editRef.current = item
                  setIsConfirmModalVisible(true)
                }}
              >
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/deleteAction-icon.png"} style={{ width: 28, height: 28 }} />
              </button>
          }
        ] : [])}
        dataSource={itemsInCart}
        pagination={false}
        scroll={{
          // y: scrollableHeight ? scrollableHeight : height * 0.4,
          y: height * 0.545,
          x: "max-content"
        }}
        id="box"
      />
      <ScrollButtons
        onPageDown={onPageDown}
        onPageUp={onPageUp}
        onRowDown={onRowDown}
        onRowUp={onRowUp}
      />
    </ConfigProvider>

  </div>
)
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  tableItem: {
    width: 100,
    fontSize: 20
  },
  totalContainer: {
    right: 10,
    position: "absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row"
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20
  },
  button: {
    marginBottom: 20,
    width: 140,
    height: 140
  }

}
