import React, { useState } from "react"
import { Input, Button, Modal, Typography, Divider, List } from "antd"
import useViewport from "../../../hooks/useViewport"
import loadSpinner from "../../assets/gif/loadSpinner.gif"
import NumpadModal from "../../common/NumpadModal"
import { Product } from "../../../models"
import CheckoutProductCard from "../../Product/CheckoutProductCard"
const { Title } = Typography


interface Props {
  title: string
  isVisible: boolean
  description: string
  onCancel: () => void
  closable: boolean
  products?: Product[]
  onConform?: (item: Product) => void
}

const MoreBarcodeModal = ({ title, isVisible, description, onCancel, closable, products, onConform }: Props) => {
  const { width } = useViewport()
  return (
    <>
      <Modal
        className="modalPage"
        transitionName="none"
        maskTransitionName="none"
        title={<div className="modalTitle">{title}<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
        centered
        width={width * 0.5}
        visible={isVisible}
        footer={null}
        onCancel={onCancel}
        closable={closable ? closable : false}
      >
        <div style={{ margin: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
        <List
        grid={{
          gutter: 4,
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        dataSource={products}
        style={{ width: "100%", padding: "5px" }}
        renderItem={item => (
          <List.Item
          >
            <CheckoutProductCard
              product={item}
              onCardPress={onConform}
            />
          </List.Item>
        )}
      // footer={}
      />
        </div>
      </Modal>
    </>
  )
}

export default MoreBarcodeModal
