import React, { useState, useEffect } from "react"
import { Layout, Menu, Breadcrumb, Modal, Form, Input, Button, Checkbox, AutoComplete, Typography, notification, Divider, Tag, message } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Customer, StyleSheet, Order, InvoiceHead, InvoiceItem, CartItem, ScannnerFocusType } from "../../../models"
import useViewport from "../../../hooks/useViewport"
import { setPaymentTotal, setPaymentTotal1, setPaymentTotal2 } from "../../../redux/Payment/slice"
import { calculatePaymentRounding, cartItemsToInvoiceItems, cartItemsToProductCodeOnlyItems, randomInteger } from "../../../utils/Global"
import { registerScannerListener } from "../../../utils/scanner-functions"
import { postInvoice } from "../../../api/invoice"
import ProgressModal from "../../common/ProgressModal"
import { clearCart } from "../../../redux/Checkout/slice"
import { useNavigate } from "react-router-dom"
import { addInvoice, serveCustomer } from "../../../redux/Customer/slice"
import { ExampleInvoiceInDB } from "../../../constants/data"
import { royalpayCreateOrder, royalpayCheckExchangeRate, royalpayCreateQRCodeOrder, royalpayCheckOrderStatus } from "../../../utils/royalpay-functions"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import AlertModal from "../../common/AlertModal"
import { setScannerFocus } from "../../../redux/Config/slice"
import { reloadCustomerScreen } from "../../../utils/customerscreen-functions"
import { BackendPaymentMethod } from "../../../constants/Enums"
import useSound from 'use-sound'

const errorBeep = require('../../../assets/sounds/failure-wrong-action.mp3')
const successBeep = require('../../../assets/sounds/success-2.mp3')
const qrImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQAAAABRBrPYAAACRklEQVR4Xu2WQQ7dIAxEzYpj5KZAbsoRumSFOzPkSw2q1G6q6ltYUQT2Y5FhcDD/m/hhe+a3cbAtDrbFwbY42BYH2+JfYNMYdVjJ3bI3u1r22zuzORbGcQdTAJgl71hiudenFAnrSnYC7vewNK7JVZAoIAY1ptRAFXCLi1WZvFIQqrH4cJjT5Hb5oMmLxLllA5UCYcZg9f0wGwt7YjwisD+Pa0mxIgxGQfj53Pp7WJW9Mb25MBSGKl6NxnY+VINk9ZfJI2D+NK70Oc4Yrz5WYmH4fMv0ti+TUxkO7s0hIbDCjV6dmZ6HGibY3oJ8P8Zvb5kiYDx5zVgXjOtXk0fA7HKp0XQ9hjKmgeW3IN+Pucysh5osAKvQrpPqYbBlcpTqLwbAwJgMhZE0AoW34scJSQaYwTC2KVv5JpPXwX2XSqGwqc7c2L7A0ORpUIqbssTCcjf5fDGVC6lPYT4U5vh8+Nl4d0I1qV9hWiVLKIydGQ9KNHlB17JrOb+qHAkr616xTC57V/6huDYYlti1KMU9aIAFz/w2+fdjnD7NmUtMA0yNZDSsGA+vD8IwQIHJ/WllwTAI4h9XN/ybxiUpOI2F8Swb8zzIVENdC5mmZBhsxeRGd8vdNChyuGSJg1ENda2pQ52kRoHzsSQYxjH+Ps/P6F4G0CqWQ2HccSmgn5GqUikohn3P6zgb+Ib25dupj4JlXp8amM+daoqPheFFHdK4OJUUYEyDUBgz+vbPifbnUL+3/vuxP8bBtjjYFgfb4mBbHGyL/4P9BIDVtW7RNuq2AAAAAElFTkSuQmCC"

interface Props {
  isVisible: boolean
  setVisibility: (value: boolean) => void
  handleSplitPayment: () => void
  postInvoiceToBackend: (payBy: string, payAmount: number, customerCopy?: string[], royalPay?: string) => void
  setIsCheckoutPaymentModalVisible?: (value: boolean) => void
}

const RoyalPayModal = ({ isVisible, setVisibility, handleSplitPayment, postInvoiceToBackend, setIsCheckoutPaymentModalVisible }: Props) => {
  const { width, height } = useViewport()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer, customers } = Customer
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart } = Checkout
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, paymentTotal1, paymentTotal2, paymentAmount1, paymentAmount2, paymentType, paymentStep } = Payment
  const Config = useSelector((state: RootStateOrAny) => state.Config)
  const { scannerFocus } = Config

  const [options, setOptions] = useState<any[]>([])
  const [selectedKey, setSelectedKey] = useState<string>()
  const [amountPaid, setAmountPaid] = useState<string>("0.00")
  const [change, setChange] = useState<string>("0.00")
  const [rounding, setRounding] = useState<number>(0)
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [showReceipt, setShowReceipt] = useState<boolean>(false)
  const [authCode, setAuthCode] = useState<string>("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>("")
  const [qrcode, setQrcode] = useState(qrImg)
  const [wechatRate, setWechatRate] = useState<number>(0)
  const [alipayRate, setAlipayRate] = useState<number>(0)
  const [payStatus, setPayStatus] = useState<string>("Unpaid")
  const [curParams, setCurParams] = useState<any>()
  const [playError] = useSound(errorBeep)
  const [playSuccess] = useSound(successBeep)

  let IntervalMessage: any
  let IntervalCount: number = 0

  const generateQRCode = async () => {
    const params = {
      partnerOrderId: randomInteger(100000, 200000),
      description: "New sales transaction at KT-Mart",
      // price: Number(total.totalAmount?.toFixed(2)) * 100
      price: paymentType === "split" ? paymentStep === "split1" ? Number(paymentAmount1?.toFixed(2)) * 100 : Number(paymentAmount2?.toFixed(2)) * 100 : Number(total.totalAmount?.toFixed(2)) * 100
    }
    const response = await royalpayCreateQRCodeOrder(params)
    console.log("createQrCodeOrder response: ", response)
    if (response.status === 200) {
      setQrcode(response.data.qrcode_img)
      //二维码同步显示在客户屏
      //@ts-ignore
      if (window?.electronAPI) {
        console.log("二维码同步显示在客户屏...", response.data.qrcode_img, true)
        //@ts-ignore
        window.electronAPI.royalQRCodeSync(response.data.qrcode_img, true, (response) => {
          console.log('Secend QR: ', response);
        })
      }
      setCurParams(response.data)
      IntervalMessage = setInterval(async () => {
        await CheckOrderStatus(params.partnerOrderId)
      }, 1000)
    } else {
      message.error("Generate QR Code Failed. Please Check The Network.")
    }
  }

  const CheckOrderStatus = async (partnerOrder?: number) => {
    IntervalCount += 1
    if (IntervalCount > 300) {
      clearInterval(IntervalMessage)
      setPayStatus("Time Out")
      playError()
      return
    }
    const params = {
      // partnerOrderId: 123572
      // partnerOrderId: 168980
      partnerOrderId: partnerOrder ? partnerOrder : curParams?.partner_order_id
    }
    const response = await royalpayCheckOrderStatus(params)
    console.log("royalpay检查订单状态 response: ", "/" + paymentType + "/", response)
    if (response.status === 200) {
      setPayStatus(response.data.result_code)
      if (response.data.result_code === "PAY_SUCCESS") {
        playSuccess()
        //付款成功。记录本次交易信息
        const RoyalPay: any = JSON.stringify(response.data)
        if (paymentType === "oneOff") {
          console.log('然后显示payment', Payment, RoyalPay)
          dispatch(setPaymentTotal({ paymentTotal: { ...paymentTotal, RoyalPay } }))
        } else if (paymentStep === "split1") {
          dispatch(setPaymentTotal1({ paymentTotal1: { ...paymentTotal1, RoyalPay } }))
        } else if (paymentStep === "split2") {
          dispatch(setPaymentTotal2({ paymentTotal2: { ...paymentTotal2, RoyalPay } }))
        }
        //停止轮询订单状态，进入下一步
        clearInterval(IntervalMessage)
        handleProceedPayment(RoyalPay)
        return
      } else if (response.data.result_code === "PAY_SUCCESS") {
        playError()
        setPayStatus(response.data.result_code)
        clearInterval(IntervalMessage)
        return

      }

      // setPayStatus("PAY_SUCCESS")

    } else {
      message.error("Check Order Status Failed. Please Check The Network.")
    }

  }

  const getExchangeRate = async () => {
    const result = await royalpayCheckExchangeRate()
    console.log("result: ", result)
    if (result.data.return_code === "SUCCESS") {
      setAlipayRate(result.data.alipay_retail_rate)
      setWechatRate(result.data.wechat_rate)
    }
  }

  const clearCartTrigger = () => {
    const setCustomerPromise = () => new Promise((resolve, reject) => {
      dispatch(serveCustomer({ customer: null }))
      dispatch(clearCart({}))
      resolve("finish")
    })
    setCustomerPromise()
      .then(() => {
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e))
  }

  const registerScannerListener = () => {
    let buffer = '' // buffer for constructing the barcode from key presses

    document.addEventListener('keypress', event => {
      let data = buffer || ''
      if (event.key !== 'Enter') { // barcode ends with enter -key
        data += event.key
        buffer = data
      } else {
        buffer = ''
        console.log(data)
        setAuthCode(data)
        handleRoyalPay(data)
      }
    })
  }

  const removeScannerListener = () => {
    let buffer = ''
    document.removeEventListener('keypress', event => {
      let data = buffer || ''
      if (event.key !== 'Enter') {
        data += event.key
        buffer = data
      } else {
        buffer = ''
        console.log(data)
        setAuthCode(data)
      }
    })
  }

  useEffect(() => {
    if (isVisible) {
      setAuthCode("")
      dispatch(setScannerFocus({ scannerFocus: ScannnerFocusType.RoyalPayAuthCode }))
    } else {
      dispatch(setScannerFocus({ scannerFocus: ScannnerFocusType.CustomerCode }))
      clearInterval(IntervalMessage)
    }
  }, [isVisible])

  useEffect(() => {
    registerScannerListener()
    // Temporary comment to avoid error display
    generateQRCode()
    getExchangeRate()
    return () => {
      //取消二维码同步显示在客户屏
      //@ts-ignore
      if (window?.electronAPI) {
        console.log("取消二维码同步显示在客户屏...", true)
        //@ts-ignore
        window.electronAPI.royalQRCodeSync( "", false, (response) => {
        // window.electronAPI.customerScreenQRCode( "", false, (response) => {
          console.log('Cancel show QR: ', response);
        })
      }
      removeScannerListener()
      clearInterval(IntervalMessage)
    }
  }, [])

  const handleRoyalPay = async (authCode: string) => {
    if (authCode) {
      setIsInProgress(true)
      const params = {
        partnerOrderId: randomInteger(100000, 200000),//设置其他值区间会导致创建order不成功
        description: "New transaction at KT-Mart by auth code",
        price: paymentType === "split" ? paymentStep === "split1" ? Number(paymentAmount1?.toFixed(2)) * 100 : Number(paymentAmount2?.toFixed(2)) * 100 : Number(total.totalAmount?.toFixed(2)) * 100,
        authCode: authCode
      }
      console.log("params1111111111111", params)
      const result = await royalpayCreateOrder(params)
      console.log("刷用户返回值", authCode, result)
      if (result.data.return_code === "SUCCESS") {
        setIsInProgress(false)
        // setIsConfirmModalVisible(true)
        //todo  开启监控付款状态
        // setQrcode("")
        setCurParams(result.data)
        clearInterval(IntervalMessage)//关闭上一次状态监控
        IntervalMessage = setInterval(async () => {
          await CheckOrderStatus(params.partnerOrderId)
        }, 1000)

      } else {
        setIsInProgress(false)
        setErrorMsg("Create order Failed. Please Try Again!")
        setIsAlertModalVisible(true)
      }
      // setTimeout(() => {
      //   setIsInProgress(false)
      //   setIsConfirmModalVisible(true)
      // }, 3000)
    } else {
      setErrorMsg("Please Scan Customer QRCode Before Pay!")
      setIsAlertModalVisible(true)
    }
  }

  const handleProceedPayment = (RoyalPay?: string) => {
    console.log("handleProceedPayment当前RoyalPay参数是:", RoyalPay)
    if (paymentType === "oneOff") {
      setVisibility(false)
      postInvoiceToBackend(BackendPaymentMethod.RoyalPay, total.totalAmount, [], RoyalPay)
    } else if (paymentStep === "split1") {
      setVisibility(false)
      handleSplitPayment()
    } else if (paymentStep === "split2") {
      setVisibility(false)
      postInvoiceToBackend(BackendPaymentMethod.RoyalPay, total.totalAmount, [], RoyalPay)
    }
  }


  return (
    <Modal
      className="modalPage"
      title={<div className="modalTitle">RoyalPay - {paymentType}{paymentType === "split" ? ` - ${paymentStep}` : null}<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
      transitionName="none"
      maskTransitionName="none"
      // title={
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <Typography style={{ fontSize: 18, marginRight: 20  }}>RoyalPay</Typography>
      //     <div style={{ flexDirection: "row", display: "flex", position: "absolute", right: 20 }}>
      //       <Button 
      //         danger
      //         type="primary" 
      //         style={{ fontSize: 24, height: 50, width: 200, marginRight: 50 }}
      //         onClick={() => {
      //           setVisibility(false)
      //         }}
      //       >
      //         Cancel Payment
      //       </Button>
      //       <Button 
      //         type="primary" 
      //         style={{ fontSize: 24, height: 50, width: 200, backgroundColor: "green", display: "flex", justifyContent: "center" }}
      //         onClick={() => {
      //           handleProceedPayment()
      //         }}
      //         >
      //           Proceed
      //       </Button>
      //     </div>
      //   </div>
      // }
      visible={isVisible}
      footer={null}
      closable={false}
      onCancel={(e) => {
        setIsCheckoutPaymentModalVisible && setIsCheckoutPaymentModalVisible(true)
        setVisibility(false)
      }}
      width={924}
      // width={width * 0.7}
      zIndex={500}
    >

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", marginTop: "-40px" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", borderRadius: "10px", backgroundColor: "#F2F2F2", width: "300px", minHeight: "493px", marginRight: "20px", padding: "10px" }}>
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/royalPayValue-icon.png"} style={{ width: 160, height: 122 }} />
          <div className="normalLabel">Invoice Amount: </div>
          <div className="normalValue" style={{ fontSize: 50, color: "#FC2C2C" }}>$
            {paymentType === "split" ? paymentStep === "split1" ? paymentAmount1?.toFixed(2) : paymentAmount2?.toFixed(2) : total.totalAmount?.toFixed(2)}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", borderRadius: "10px", backgroundColor: "#F2F2F2", width: "564px", padding: "10px", minHeight: "493px", }}>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
            <div className="normalLabel">Current Exchange Rate (Wechat Pay): </div>
            <div className="normalValue">{wechatRate?.toFixed(2)}</div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
            <div className="normalLabel">Current Exchange Rate (Alipay): </div>
            <div className="normalValue">{alipayRate?.toFixed(2)}</div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <div className="normalLabel">Auth Code: </div>
            <Input value={authCode} style={{ width: 500, fontSize: 22, height: 50, marginRight: 10 }} placeholder={"Please Scan Customer QRCode"} />
          </div>
          {/* <div style={{ backgroundColor: "#fff" }}> */}
          <div className="normalLabel">Customer Scan:</div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <img style={{ width: 200, height: 200, marginBottom: 20, borderRadius: "10px" }} src={qrcode} />
            <div style={{ display: "flex", flexDirection: "column", height: "200px" }}>
              <div className="normalLabel">Status:
                <Tag color={payStatus === "PAY_SUCCESS" ? "success" : "error"} style={{ fontSize: "18px", marginLeft: "10px" }}>{payStatus}</Tag>
              </div>
              <button
                style={{ height: "50px", width: "250px", border: "none", borderRadius: "5px", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal", fontSize: "18px", marginTop: "20px" }}
                onClick={() => {
                  generateQRCode()
                }}
              >
                Generate QRCode
              </button>
              <button
                style={{ height: "50px", width: "250px", border: "none", borderRadius: "5px", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal", fontSize: "18px", marginTop: "20px" }}
                onClick={() => {
                  CheckOrderStatus()
                }}
              >
                Check Status
              </button>
            </div>
          </div>
          {/* </div> */}

          <div style={{
            display: "flex", flexDirection: "row", justifyContent: "space-between",
            // marginTop: 50 
          }}>
            <button
              style={{ height: "50px", width: "250px", border: "none", borderRadius: "5px", backgroundColor: "#E63737", color: "#fff", fontWeight: "normal", fontSize: "18px" }}
              onClick={() => {
                setIsCheckoutPaymentModalVisible && setIsCheckoutPaymentModalVisible(true)
                setVisibility(false)
              }}
            >
              Cancel Payment
            </button>
            <button
              style={{ height: "50px", width: "250px", border: "none", borderRadius: "5px", backgroundColor: (payStatus === "PAY_SUCCESS") ? "#32B373" : "#DAE2EC", color: (payStatus === "PAY_SUCCESS") ? "#fff" : "#979797", fontWeight: "normal", fontSize: "18px" }}
              onClick={() => {
                handleProceedPayment()
              }}
              disabled={payStatus !== "PAY_SUCCESS"}
            >
              Proceed
            </button>
            {/* <Button
              type="primary"
              onClick={() => {
                generateQRCode()
              }}
              style={{fontFamily: "PingFangSC-Semibold", fontSize: 18, height: 50, width: 270, borderRadius: 6 }}
            >
              Generate QRCode
            </Button> */}
            {/* <Button 
            type="primary" 
            onClick={async () => { 
              if (authCode) {
                setIsInProgress(true)
                const params = {
                  partnerOrderId: randomInteger(100000,200000),
                  description: "test transaction",
                  price: 50,
                  authCode: authCode
                }
                const result = await royalpayCreateOrder(params)
                if (result.data.return_code === "SUCCESS") {
                  setIsInProgress(false)
                  setIsConfirmModalVisible(true)
                } else {
                  setIsInProgress(false)
                  setErrorMsg("Pay Failed. Please Try Again!")
                  setIsAlertModalVisible(true)
                }
                // setTimeout(() => {
                //   setIsInProgress(false)
                //   setIsConfirmModalVisible(true)
                // }, 3000)
              } else {
                setErrorMsg("Please Scan Customer QRCode Before Pay!")
                setIsAlertModalVisible(true)
              }       
            }}
            style={{ fontWeight: "bold", marginRight: 50, fontSize: 22, backgroundColor: "green", height: 60, width: 200 }}
          >
            Pay
          </Button> */}

          </div>
        </div>

      </div>
      <ProgressModal isVisible={isInProgress} description={"Processing Payment"} />
      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
    </Modal>
  )
}

export default RoyalPayModal

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  button: {
    marginRight: 20,
    fontSize: 25,
    width: 100,
    height: 100
  }
}