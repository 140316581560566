import React, { useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Tooltip } from "antd"
import { InfoCircleOutlined, UserOutlined, HomeOutlined, MobileOutlined } from "@ant-design/icons"
import { RootStateOrAny, useSelector } from "react-redux"
import useViewport from "../../../hooks/useViewport"
import { UserState } from "../../../redux/User/slice"

type Props = {
  returnValue?: "staff" | "customer" | undefined
}

export default function StaffInfoSection({ returnValue }: Props) {
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer
  console.log("当前servingCustomer是。。。", servingCustomer)
  const User:UserState = useSelector((state: RootStateOrAny) => state.User)
  const { user } = User
  const { width, height } = useViewport()


  switch (returnValue) {
    case "staff":
      return (
        <>Login Staff: {user?.Name}</>
      )

    case "customer":
      return (
        <div>{
          servingCustomer ?
            "Customer: " + servingCustomer.name + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "Points: " + servingCustomer.Points
            :
            "Customer: Cash Customer"
        }</div>)


    default:
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginLeft: 10, marginTop: 10, width: width * 0.53 }}>
            <div>
              <Typography style={{ fontWeight: "bold", marginBottom: 10, fontSize: 20 }}>Login Staff: {user?.Name}</Typography>
            </div>
          </div>
          <div style={{ marginTop: 10, width: width * 0.4 }}>
            <div>
              <Typography style={{ fontWeight: "bold", marginBottom: 10, fontSize: 20 }}>
                {
                  servingCustomer ?
                    "Customer: " + servingCustomer.name + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "Points: " + servingCustomer.Points
                    :
                    "Customer: Cash Customer"
                }
              </Typography>
            </div>
          </div>
        </div>
      )
  }


}
