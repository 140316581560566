import react, { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { CashDeclaration } from "../../models"
import { Radio, Form, InputNumber, PageHeader, Row, Col, Typography, DatePicker, Input, notification, message } from "antd"
import moment from "moment";
import NumpadModal from "../common/NumpadModal"
import KeyboardModal from "../common/KeyboardModal"
import { PostCashDeclaration, getExpectedTotal } from "../../api/cashDeclaration"
import { getConfiguration } from "../../utils/configurate"
import { switchKeyboardLanguage } from "../../redux/Checkout/slice"
const PosConfig = getConfiguration("CashDeclaration")
const { machineID: MachineID } = PosConfig
type Props = {
    declaration: CashDeclaration | null
    defaultOperationType: string
    // onCancel: () => void
    reset: () => void
    setCurCash: (value: CashDeclaration | null) => void
}

// export default function CashDeclarationDetail({ declaration, reset, setCurCash }: Props) {
export default function CashDeclarationDetail({ declaration, reset, setCurCash, defaultOperationType }: Props) {
    const User = useSelector((state: RootStateOrAny) => state.User)
    const { uid } = User
    const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
    const { keyboardLanguage } = Checkout
    console.log('显示declaration的内容', declaration)
    const dispatch = useDispatch()
    const [curTotal, setCurTotal] = useState<number>(0)
    const [curExpectedTotal, setCurExpectedTotal] = useState<any>({
        StartBalance: 0,
        CashInflow: 0,
        CashOutflow: 0,
        ExpectedTotal: 0,
        StartDateTime: undefined
    })
    const [isCashIn, setIsCashIn] = useState<string>(declaration ? declaration.OperationType : 'EndDeclaration')
    const [form] = Form.useForm()
    // Thins Need to set for edit item
    const [isNumpadVisible, setIsNumpadVisible] = useState(false)
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
    const [editType, setEditType] = useState<string>("")
    const [numpadValue, setNumpadValue] = useState("0")
    const [numpadDecimal, setNumpadDecimal] = useState(1)
    const [numpadNegative, setNumpadNegative] = useState(false)
    const [curNotes, setCurNotes] = useState<string>("")
    const emptyValues: any =
    {
        HundredDollar: 0,
        FiftyDollar: 0,
        TwentyDollar: 0,
        TenDollar: 0,
        FiveDollar: 0,
        TwoDollar: 0,
        OneDollar: 0,
        FiftyCent: 0,
        TwentyCent: 0,
        TenCent: 0,
        FiveCent: 0,
        ID: undefined,
        Notes: ""
    }
    const initValues: any =
    {
        ...emptyValues,
        // OperationType: defaultOperationType,
        // StartDateTime: moment(),
        EndDateTime: moment(),
        OpFirebaseUid: uid,
        MachineID
    }

    const onConfirmValue = (value: number) => {
        form.setFieldsValue({ [editType]: value })
        setIsNumpadVisible(false)
        onValuesChange()

    }
    const onFinish = async (values: any) => {
        const mergeValues = {
            ...values,
            Total: curTotal,
            StartDateTime: isCashIn === 'EndDeclaration' ? moment(curExpectedTotal.StartDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
            // StartDateTime: moment(values.StartDateTime).format("YYYY-MM-DD hh:mm:ss"),
            EndDateTime: moment(values.EndDateTime).format("YYYY-MM-DD HH:mm:ss"),
            CreateDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            StartBalance: isCashIn === 'EndDeclaration' ? curExpectedTotal.StartBalance : undefined,
            CashInflow: isCashIn === 'EndDeclaration' ? curExpectedTotal.CashInflow : undefined,
            CashOutflow: isCashIn === 'EndDeclaration' ? curExpectedTotal.CashOutflow : undefined,
        }
        console.log('准备提交的cashdeclaration数据', mergeValues)
        if (!values.ID) {
            const res: any = await PostCashDeclaration(mergeValues)
            console.log('查看cashdeclaraton返回值。。。', res)
            reset && reset()
            setCurCash(res)
        } else
            notification.error({
                message: `Cash Declaration History ${values.ID} Can Not Be Updated`
            })
    }
    const onValuesChange = async () => {
        const values = form.getFieldsValue()
        console.log('onValuesChange', values)
        setCurTotal(
            (values.HundredDollar * 10000 +
                values.FiftyDollar * 5000 +
                values.TwentyDollar * 2000 +
                values.TenDollar * 1000 +
                values.FiveDollar * 500 +
                values.TwoDollar * 200 +
                values.OneDollar * 100 +
                values.FiftyCent * 50 +
                values.TwentyCent * 20 +
                values.TenCent * 10 +
                values.FiveCent * 5) / 100
        )
    }
    const getExpected = () => new Promise((resolve, reject) => {
        const responseData = getExpectedTotal({ MachineID })
        resolve(responseData)
    })
    useEffect(() => {//获得当前的expectedTotal

        getExpected()
            .then((result) => {
                console.log('获得当前的expectedTotal', result)
                if (result) {
                    setCurExpectedTotal(result)
                }
            })
            .catch((e) => console.log('获得exceptTotal结果错误。。', e))
    }, [])

    useEffect(() => {
        if (declaration) {
            form.setFieldsValue({
                ...declaration,
                // "StartDateTime": moment(declaration.StartDateTime),
                "EndDateTime": moment(declaration.EndDateTime),
                // "OperationType": declaration.OperationType
            })
            setIsCashIn(declaration.OperationType)
            setCurNotes(declaration.Notes)
            // message.info(JSON.stringify(declaration))
        } else {
            form.setFieldsValue(initValues)
            if (defaultOperationType) {
                // message.info(defaultOperationType)
                form.setFieldsValue({ OperationType: defaultOperationType })
                setIsCashIn(defaultOperationType)
                setCurNotes("")
            }
        }
    }, [declaration, defaultOperationType])

    return (
        <PageHeader
            className="detailsPageHeader"
            ghost={false}
            backIcon={false}
            title={
                <div style={{ display: "flex", flexDirection: "row", marginTop: "-30px" }}>
                    <div className="detailNo">
                        {
                            declaration ?
                                <>View Cash Declaration: <br /> {declaration.ID}</>
                                :
                                `New Cash Declaration`
                        }
                    </div>
                </div>
            }
            extra={[
                <div style={{ display: "flex" }}>
                    <button key="cancel" className="viewDetailsButton" style={{ width: "60px", marginRight: "10px" }}
                        // <button key="cancel" className="scanToRefundButton" style={{ width: "60px" }}
                        onClick={() => {
                            //取消修改，恢复原始数据
                            setCurCash(null)
                            getExpected()
                                .then((result) => {
                                    console.log('获得当前的expectedTotal', result)
                                    if (result) {
                                        setCurExpectedTotal(result)
                                    }
                                })
                                .catch((e) => console.log('获得exceptTotal结果错误。。', e))
                            // form.setFieldsValue(initValues)
                            // onCancel && onCancel()
                        }}
                    >
                        <img src={process.env.PUBLIC_URL + "/images/assets/icons/cancel-icon.png"} className="pageIcon" />
                        New
                    </button>
                    <button key="edit" className="printDetailsButton"
                        onClick={() => {
                            if (declaration) {
                                message.warning('Can not save again.')
                                return
                            }
                            //save cashdeclaration
                            onFinish(form.getFieldsValue())

                        }}
                    >
                        <img src={process.env.PUBLIC_URL + "/images/assets/icons/cardPayMethodChecked-icon.png"} className="pageIcon" />
                        Save
                    </button>

                </div>
            ]}
        >
            {isNumpadVisible ?
                <NumpadModal
                    title={editType}
                    value={numpadValue}
                    isVisible={isNumpadVisible}
                    decimal={numpadDecimal}
                    negative={numpadNegative}
                    onConfirm={onConfirmValue}
                    onDismiss={() => {
                        setIsNumpadVisible(false)
                    }}
                />
                : null
            }
            {isKeyboardVisible ?
                <KeyboardModal
                    // mask={false}
                    isVisible={isKeyboardVisible}
                    language={keyboardLanguage}
                    onHideKeyboard={() => {
                        setIsKeyboardVisible(false)
                    }}
                    onSwitchLanguage={() => {
                        dispatch(switchKeyboardLanguage({}))
                    }}
                    onConfirm={(value: string) => {
                        setCurNotes(value)
                        console.log("notes value: ", value)
                        form.setFieldsValue({ "Notes": value })
                        setIsKeyboardVisible(false)
                    }}
                    initialValue={curNotes}
                />
                : null
            }
            <Form
                name="CashDeclarationDetailForm"
                disabled={!!declaration}
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                initialValues={declaration ? declaration : initValues}
            >
                <Form.Item
                    name="EndDateTime"
                    label="Declaration DateTime"
                >
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item
                    name="OperationType"
                    label="Operation Type"
                >
                    <Radio.Group
                        onChange={(e) => {
                            setIsCashIn(e.target.value)
                            form.setFieldsValue(emptyValues)
                        }}
                    // value={isCashIn}
                    // defaultValue={isCashIn}
                    >
                        <Radio value={'StartDeclaration'}>StartDeclaration</Radio>
                        <Radio value={'CashIn'}>CashIn</Radio>
                        <Radio value={'CashOut'}>CashOut</Radio>
                        <Radio value={'EndDeclaration'}>EndDeclaration</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                        {/* <Form.Item
                            name="StartDateTime"
                            label="Start DateTime"
                            style={{ display: "none" }}
                        >
                            <DatePicker showTime />
                        </Form.Item> */}
                        <div>
                            <Form.Item
                                name="HundredDollar"
                                label="100 Dollar"
                            >
                                <InputNumber
                                    min={0}
                                    style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("HundredDollar")
                                        setNumpadValue(form.getFieldValue("HundredDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="TwentyDollar"
                                label="20 Dollar"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("TwentyDollar")
                                        setNumpadValue(form.getFieldValue("TwentyDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="FiveDollar"
                                label="5 Dollar"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("FiveDollar")
                                        setNumpadValue(form.getFieldValue("FiveDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="OneDollar"
                                label="1 Dollar"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("OneDollar")
                                        setNumpadValue(form.getFieldValue("OneDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="TwentyCent"
                                label="20 Cent"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("TwentyCent")
                                        setNumpadValue(form.getFieldValue("TwentyCent").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="FiveCent"
                                label="5 Cent"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("FiveCent")
                                        setNumpadValue(form.getFieldValue("FiveCent").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>

                    </Col>
                    <Col span={12} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                        <div>
                            <Form.Item
                                name="FiftyDollar"
                                label="50 Dollar"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("FiftyDollar")
                                        setNumpadValue(form.getFieldValue("FiftyDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="TenDollar"
                                label="10 Dollar"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("TenDollar")
                                        setNumpadValue(form.getFieldValue("TenDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="TwoDollar"
                                label="2 Dollar"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("TwoDollar")
                                        setNumpadValue(form.getFieldValue("TwoDollar").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="FiftyCent"
                                label="50 Cent"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("FiftyCent")
                                        setNumpadValue(form.getFieldValue("FiftyCent").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="TenCent"
                                label="10 Cent"
                            >
                                <InputNumber min={0} style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                                    onClick={() => {
                                        setEditType("TenCent")
                                        setNumpadValue(form.getFieldValue("TenCent").toFixed(0))
                                        setNumpadDecimal(1)
                                        setNumpadNegative(true)
                                        setIsNumpadVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <Typography style={{ fontWeight: 700, display: !!declaration ? 'flex' : 'none', }}>
                            Total: {declaration ? declaration.Total : curTotal}
                        </Typography>
                        {/* <Typography style={{ display: isCashIn === 'EndDeclaration' ? 'flex' : 'none', fontWeight: 700 }}>
                            ExpectedTotal: {declaration ? declaration.ExpectedTotal.toFixed(2) : curExpectedTotal.ExpectedTotal.toFixed(2)}
                            {declaration ? declaration.StartBalance +'/'+ declaration.CashInflow +'/'+ declaration.CashOutflow : 'ooo'} */}
                        {/* ExpectedTotal: {declaration ? declaration.StartBanlance + declaration.CashInflow + declaration.CashOutflow : curExpectedTotal.ExpectedTotal} */}
                        {/* ExpectedTotal: {JSON.stringify(curExpectedTotal)}
                        </Typography> */}
                        <Typography
                            style={{
                                display: isCashIn === 'EndDeclaration' && !!declaration ? 'flex' : 'none',
                                fontWeight: 700,
                                color: declaration ? Math.abs(declaration.Total - declaration.ExpectedTotal).toFixed(2) === '0.00' ? 'green' : 'red' : Math.abs(curTotal - curExpectedTotal.ExpectedTotal).toFixed(2) === '0.00' ? 'green' : 'red'
                            }}
                        >
                            Matched?: {declaration ? Math.abs(declaration.Total - declaration.ExpectedTotal).toFixed(2) === '0.00' ? 'YES' : `NO` : Math.abs(curTotal - curExpectedTotal.ExpectedTotal).toFixed(2) === '0.00' ? 'YES' : `NO`}
                            {/* Matched?: {declaration ? (declaration.Total - declaration.ExpectedTotal).toFixed(2) === '0.00' ? 'YES' : `NO(${(declaration.Total - declaration.ExpectedTotal).toFixed(2)})` : (curTotal - curExpectedTotal.ExpectedTotal).toFixed(2) === '0.00' ? 'YES' : `NO(${(curTotal - curExpectedTotal.ExpectedTotal).toFixed(2)})`} */}
                        </Typography>
                    </Col>

                </Row>
                <Form.Item
                    name="OpFirebaseUid"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Notes"
                    name="Notes"
                >
                    <Input.TextArea onClick={() => setIsKeyboardVisible(true)} />
                </Form.Item>
                <Form.Item
                    name="ID"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="MachineID"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="StartDateTime"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
            </Form>

        </PageHeader>
    )

}