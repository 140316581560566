import "../../css/Global.css"
import React, { useState, useEffect } from "react"
import { Layout, Menu, Breadcrumb, Modal, Form, Input, Button, Checkbox, AutoComplete, Typography, notification, Radio, Divider } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Customer, StyleSheet, Order, InvoiceHead, InvoiceItem, CartItem } from "../../models"
import NumPad from "react-numpad"
import useViewport from "../../hooks/useViewport"
import { applyRatioDiscount, applyAmountDiscount, removeDiscount } from "../../redux/Checkout/slice"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import AlertModal from "../common/AlertModal"
import NumpadModal from "../common/NumpadModal"

interface Props {
  isVisible: boolean
  setVisibility: (value: boolean) => void
  curDiscount: number
  setCurDiscount: (value: number) => void
}

const DiscountModal = ({ isVisible, setVisibility, curDiscount, setCurDiscount }: Props) => {
  const { width, height } = useViewport()
  const dispatch = useDispatch()

  const [discountType, setDiscountType] = useState<"ratio" | "amount">("ratio")
  const [discountAmount, setDiscountAmount] = useState(curDiscount || 0)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")

  const [isNumpadVisible, setIsNumpadVisible] = useState(false)
  const [editType, setEditType] = useState<"quantity" | "unitPrice" | "discount">("quantity")
  const [numpadValue, setNumpadValue] = useState("0")
  const [numpadDecimal, setNumpadDecimal] = useState(2)
  const [numpadNegative, setNumpadNegative] = useState(false)

  const applyDiscountTrigger = (value?:number) => {
    const setDiscountPromise = () => new Promise((resolve, reject) => {
      if (discountType === "ratio") {
        dispatch(applyRatioDiscount({ discount: Number(value? value : discountAmount) }))
      } else {
        dispatch(applyAmountDiscount({ discount: Number(value? value : discountAmount) }))
      }
      resolve("finish")
    })
    setDiscountPromise()
      .then(() => {
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e))
  }

  const removeDiscountTrigger = () => {
    const setDiscountPromise = () => new Promise((resolve, reject) => {
      dispatch(removeDiscount({}))
      resolve("finish")
    })
    setDiscountPromise()
      .then(() => {
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e))
  }


  const DiscountTypeSelect = () => {
    return (
      <div style={{ width: "100%", marginBottom: 20, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
        <div className="normalLabel" style={{ marginRight: "20px" }}>Discount Type: </div>
        <Radio.Group
          onChange={(e: any) => {
            // console.log('radio checked', e.target.value)
            setDiscountType(e.target.value)
          }}
          value={discountType}
        >
          <Radio value={"ratio"} className="normalLabel" style={{ fontSize: 20 }}>Ratio(%)</Radio>
          {/* <Radio value={"amount"} style={{ fontSize: 22 }}>Amount</Radio> */}
        </Radio.Group>
      </div>
    )
  }

  const onConfirmValue = (value: number) => {
    console.log("//新numpadmodal模式下根据返回值修改discount", value)
    // setDiscountAmount(value)
    applyDiscountTrigger(value)
    setIsNumpadVisible(false)
    setVisibility(false)
    setCurDiscount(value) 
  }

  useEffect(() => {
    if (isVisible) {
      console.log("每次单击apply discount都会执行", discountAmount)
      setEditType("discount")
      setNumpadValue(discountAmount.toString())
      // setNumpadValue(discountAmount.toString()+"%")
      setNumpadDecimal(1)
      setNumpadNegative(false)
      setIsNumpadVisible(true)
    }

  }, [isVisible])

  return (
    <>
      {isNumpadVisible ?
        <NumpadModal
          title={editType}
          value={numpadValue}
          isVisible={isNumpadVisible}
          decimal={numpadDecimal}
          negative={numpadNegative}
          onConfirm={onConfirmValue}
          onDismiss={() => {
            setIsNumpadVisible(false)
            setVisibility(false)
          }}
        />
        : null
      }
      <Modal
        className="modalPage"
        title={<div className="modalTitle">Apply Discount<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
        transitionName="none"
        maskTransitionName="none"
        // title="Apply Discount"
        visible={false}
        // visible={isVisible}
        // footer={null}
        onOk={(e) => {
          applyDiscountTrigger()
          setVisibility(false)
        }}
        onCancel={(e) => {
          setVisibility(false)
        }}
        width={600}
        centered
        footer={[]}
      >
        <div style={styles.container}>

          <AlertModal
            isVisible={isAlertModalVisible}
            description={errorMsg}
            buttonText={"OK"}
            onCancel={() => setIsAlertModalVisible(false)}
            onConfirm={() => setIsAlertModalVisible(false)}
          />
          <DiscountTypeSelect />
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div className="normalLabel">{discountType === "ratio" ? "Discount Ratio:" : "Discount Amount:"}</div>
            <Input value={discountAmount}
              style={{ fontSize: 20, fontWeight: 500 }}
              onClick={() => {
                setEditType("discount")
                setNumpadValue(discountAmount.toString())
                setNumpadDecimal(1)
                setNumpadNegative(false)
                setIsNumpadVisible(true)
              }}
            />
            {/* <NumPad.Number
            onChange={(value: string) => {
              const numberInput = Number(value)
              if (numberInput < 0 || numberInput > 100) {
                setErrorMsg("Discount Ratio Must Between 0 and 100!")
                setIsAlertModalVisible(true)
              } else {
                setDiscountAmount(Number(value))
                // applyDiscountTrigger()
                // setVisibility(false)
              }
            }}
            value={discountAmount}
            decimal={0}
            negative={false}
          >
            <Input value={discountAmount} style={{fontSize: 20, fontWeight: 500}} />
          </NumPad.Number> */}
          </div>

        </div>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "50px" }}>
          <button
            style={{ height: "50px", width: "270px", border: "none", borderRadius: "5px", backgroundColor: "#E63737", color: "#fff", fontWeight: "normal", fontSize: "18px" }}
            onClick={() => {
              removeDiscountTrigger()
              setVisibility(false)
            }}
          >
            Remove Discount
          </button>
          <button
            style={{ height: "50px", width: "270px", border: "none", borderRadius: "5px", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal", fontSize: "18px" }}
            onClick={() => {
              applyDiscountTrigger()
              setVisibility(false)
            }}
          >
            Apply Discount
          </button>
        </div>

      </Modal>
    </>
  )
}

export default DiscountModal

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "-40px"
  },
  button: {
    marginRight: 20,
    fontSize: 25,
    width: 100,
    height: 100
  }
}