import React, { useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Steps, Tabs, Button } from "antd"
import { UserOutlined, LaptopOutlined, NotificationOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { BrowserRouter, Routes, Route, Link, useLocation, useMatch, Router } from "react-router-dom"
import 'react-tiny-fab/dist/styles.css'
import useWindowDimensions from "../hooks/useWindowDimensions"
import { StyleSheet, Total } from "../models"
import CheckoutCart from "../components/Checkout/CheckoutCart"
import ProductSection from "../components/Checkout/ProductSection"
import "react-simple-keyboard/build/css/index.css"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import MultiLanguageKeyboard from "../components/common/MultiLanguageKeyboard"
import { showKeyboard, switchKeyboardLanguage } from "../redux/Checkout/slice"
import CustomerInfoSection from "../components/Checkout/Customer/CustomerInfoSection"
import TotalSection from "../components/Checkout/TotalSection"
import CustomerOperationSection from "../components/Checkout/Customer/CustomerOperationSection"

export default function SelfCheckoutPage() {
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { isKeyboardVisible, keyboardLanguage, total } = Checkout
  const { Step } = Steps
  const { TabPane } = Tabs
  const { height, width } = useWindowDimensions()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentTab, setCurrentTab] = useState<string>("1")
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const onChange = (input: any) => {
    console.log("Input changed", input);
  }

  const onKeyPress = (button: any) => {
    console.log("Button pressed", button);
  }

  const onStepsChange = (current: any) => {
    setCurrentStep(current)
    switch (current) {
      case 0:
        setCurrentTab("1")
        break
      case 1: 
        setCurrentTab("2")
        break
      case 2:
        setCurrentTab("3")
        break
      default:
    }
  }

  const Total = () => (
    <div style={{ backgroundColor: "white", flexDirection: "row", display: "flex", width: width * 0.25 }}>
        <TotalSection item={total} />
    </div>
  )

  return (
    <div style={{...styles.container, height: height }}>
      <div style={{ display: "flex", flexDirection: "row"}}>
        <div style={{ width: width * 0.7 }}>
          <CustomerInfoSection />
          <CheckoutCart tableRows={15} tableHeight={ height * 0.8 } scrollableHeight={ height * 0.7 }/>
        </div>
        
        <div style={{ width: width * 0.3, alignItems: "center", display: "flex", flexDirection: "column" }}>   
          <Breadcrumb style={{ margin: '24px 24px', fontWeight: "bold", fontSize: 20 }}>
            <Breadcrumb.Item>Checkout</Breadcrumb.Item>
          </Breadcrumb>
          {/* <TotalSection item={total} /> */}
          <Total />
          <CustomerOperationSection />
        </div>
      </div>
    </div>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "white"
  },
  leftContainter: {
    width: "50%"
  },
  rightContainer: {
    width: "50%"
  },
  stepsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white"
  },
  tabsContainer: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    marginLeft: 50,
    flexDirection: "column",
    backgroundColor: "white"
  },
  infoContainer: {
    marginTop: 10,
    marginBottom: 5,
    flexDirection: "row"
  },
  paymentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white"
  },
  operationContainer: {
    marginTop: 5,
    marginBottom: 5,
    flexDirection: "row"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    marginBottom: 5
  },
  subTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: "grey",
  },
  descRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  descText: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
  },
  disabledLayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
  button: {
    marginLeft: 10
  }
}