import React from "react"
import { Button, Typography } from "antd"
import useViewport from "../hooks/useViewport"
import { useNavigate } from "react-router-dom"
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"

const { Title } = Typography

export default function WelcomePage () {
  const { width, height } = useViewport()
  const navigate = useNavigate()

  return (
    <div style={{ width, height, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CheckCircleFilled/>
      <Title>Success!</Title>
      <Button 
        type="primary" 
        style={{ color: "white", height: 200, fontSize: 16 }}
        onClick={() => {
          navigate("/selfcheckout")
        }}
      >
        Go Back to Checkout
      </Button>
    </div>
  )
}