import "../css/Global.css"
import "../css/Numpad.css"
import "../css/Keyboard.css"
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, Button, Typography, notification, message } from "antd"
import { ReloadOutlined } from '@ant-design/icons'
import { BrowserRouter, Routes, Route, Link, useLocation, useMatch, Router, useNavigate } from "react-router-dom"
import ProductPage from "./ProductPage"
import OrderPage from "./OrderPage"
import CartPage from "./CartPage"
import SelfCheckoutPage from "./SelfCheckoutPage"
import CheckoutPage from "./CheckoutPage"
import CustomerPage from "./CustomerInvoicePage"
import InvoicePage from "./InvoicePage"
import PaymentPage from "./PaymentPage"
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import useViewport from "../hooks/useViewport"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import WelcomePage from "./WelcomePage"
import LoginModal from "../components/Login/LoginModal"
import { userLogout, UserState } from "../redux/User/slice"
import { CustomerState } from "../redux/Customer/slice"
import { CheckoutState } from "../redux/Checkout/slice"
import { GlobalState, freshBarcode, freshWeight } from "../redux/Globals/slice"
import SettingPage from "./SettingPage"
import PaymentSuccessPage from "./PaymentSuccessPage"
import { listCheckoutProductsByPage, listProducts } from "../api/product"
import { listCategories } from "../api/category"
import { listCustomers } from "../api/customer"
import { loadProducts, loadCategories, loadCheckoutProductsByPage, loadCheckoutProductsNextPage, loadCheckoutProductsPrePage } from "../redux/Product/slice"
import { loadAllInvoices, loadCustomers } from "../redux/Customer/slice"
import { productInDBToProduct, categoryInDBToCategory, customerInDBToCustomer, handleMultipageResponse, customerOrderHeadsToOrders, invoiceInDBsToLocalInvoices } from "../utils/Global"
import CustomerScreenMainContent from "../components/CustomerScreen/CustomerScreenMainContent"
import CustomerScreen, { openSecondWindow } from "./CustomerScreen"
import InvoiceHistoryPage from "./InvoiceHistoryPage"
import { setCurrentScreen } from "../redux/Config/slice"
import { UserScreenType } from "../models/index"
import { listOrders } from "../api/order"
import { loadHoldingOrders } from "../redux/Order/slice"
import ProgressModal from "../components/common/ProgressModal"
import { listInvoices, listNoneRefundInvoices } from "../api/invoice"
import { listRefunds } from "../api/refund"
import { loadAllRefunds } from "../redux/Invoice/slice"
import RefundPage from "./RefundPage"
import { DisplayDataType } from "../constants/Enums"
import CashDeclarationPage from "./CashDeclaration"
import { PostDrawOpen } from "../api/drawOpen"
import { getConfiguration, saveConfiguration } from "../utils/configurate"
import ActionConfirmModal from "../components/common/ActionConfirmModal"

import { doLogon } from "../api/payment"

const { confirm } = Modal
const { SubMenu } = Menu
const { Header, Content, Sider, Footer } = Layout

export default function HomePage() {
  const PosConfig = getConfiguration("Homepage")
  const dispatch = useDispatch()
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const Customer: CustomerState = useSelector((state: RootStateOrAny) => state.Customer)
  const Checkout: CheckoutState = useSelector((state: RootStateOrAny) => state.Checkout)
  // const Globals: UserState = useSelector((state: RootStateOrAny) => state.Globals)
  const { isSignIn } = User
  const { pathname } = useLocation()
  // const { height, width } = useWindowDimensions()
  const { height, width } = useViewport()
  const [isCartVisible, setIsCartVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState<string[]>(["1"])
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false)
  const [isCashDeclarationAlertModal, setIsCashDeclarationAlertModal] = useState<boolean>(false)
  const [isInProgress, setIsInProgress] = useState(false)
  const [isInitPrinterAlertModal, setIsInitPrinterAlertModal] = useState<boolean>(false)
  
  // const [pathname, setPathname] = useState("/welcome")
  const scannerCallback = async (e: any) => {
    if (e.key === 'F7') { // F7 = open draw
      //@ts-ignore
      if (window?.electronAPI) {
        //@ts-ignore
        console.log('open drawer in homepage')
        //@ts-ignore
        window.electronAPI.openDrawComponent((response) => {
          console.log('Todo add unsale open draw log: ', response);
        });
      } else console.log("cant load electronAPI:", window)
      const openRecord: any = {
        Reasons: "no sales"
      };
      PostDrawOpen(openRecord)
    }
  }

  React.useEffect(() => {
    //初始化eftpos logon
    // doLogon()
    //是否用户已登录
    !isSignIn && pathname !== "/customerscreen" && setIsLoginModalVisible(true)
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.handleResponseFromMain((event, value) => {
        console.log("来自后台的消息：：：", value)
        if (value.indexOf("printer error") > -1) {
        // if (JSON.stringify(value).indexOf("Error: Cannot read properties of undefined (reading 'open')") > -1) {
          setIsInitPrinterAlertModal(true)
        }
      })
    }
    //初始化scanner和scale的串口，初始化打印机
    //@ts-ignore
    if (window?.electronAPI && pathname !== "/customerscreen") {
      //@ts-ignore
      window?.electronAPI?.initDevices(PosConfig, (response: any) => {
        console.log("电子秤和打印机初始化成功", response)
      });
    }
    // 电子秤信息捕捉
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.handleScanner((event, value) => {
        console.log("the 产品购物车 barcode is ", value)
        dispatch(freshBarcode({
          barcode: value
        }))
        setTimeout(() => {
          dispatch(freshBarcode({
            barcode: ""
          }))//0.1秒后该条码失效，防止后续切换页面误处理
        }, 100);
      })
      // //@ts-ignore
      // window.electronAPI.handleScale((event, value) => {
      //   console.log("the weight is ", Number(value))
      //   dispatch(freshWeight({
      //     weight: value
      //   }))
      //   setTimeout(() => {
      //     dispatch(freshWeight({
      //       weight: ""
      //     }))//0.1秒后该重量失效，防止后续切换页面误处理
      //   }, 100);
      // })
    }
    document.addEventListener('keydown', (e) => scannerCallback(e))
    // document.addEventListener('keydown', (e) => {console.log(`Key "${e.key}" pressed [event: keydown]`);})

    // openSecondWindow()

    return () => {
      document.removeEventListener('keydown', (e) => scannerCallback(e))
    }
  }, [])

// useEffect(() => {
//   console.log("Posconfig of homepage......>", PosConfig)
//   if (PosConfig.MachineID) {

//   }
// }, [PosConfig])

  useEffect(() => {
    const fetchData = async () => {
      // setIsInProgress(true)
      const productsData = await listProducts()
      const categoriesData = await listCategories()
      const customersData = await listCustomers()
      const ordersData = await listOrders()
      //default load invoice of this machine at first time
      const invoicesData = await listNoneRefundInvoices({WarehouseCode: PosConfig.warehouseCode, MachineID: PosConfig.machineID})
      // const refundsData = await listRefunds()
      const checkoutProductsData = await listCheckoutProductsByPage(1)
      const checkoutProducts = productInDBToProduct(checkoutProductsData.data)
      const productTotal = checkoutProductsData.total
      dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: 1, total: productTotal, type: DisplayDataType.All }))
      //同时检索出第二页等待翻页
      const checkoutProductsDataNextPage = await listCheckoutProductsByPage(2)
      const checkoutNextPageProducts = productInDBToProduct(checkoutProductsDataNextPage.data)
      dispatch(loadCheckoutProductsNextPage({ products: checkoutNextPageProducts }))
      dispatch(loadCheckoutProductsPrePage({ products: [] }))
      const products = productInDBToProduct(productsData.data)
      const categories = categoryInDBToCategory(categoriesData.data)
      const customers = customerInDBToCustomer(customersData.data)
      const orders = customerOrderHeadsToOrders(ordersData.data)
      const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)
      const invoicesPagination = invoicesData.pagination
      // const refunds = invoiceInDBsToLocalInvoices(refundsData.data)
      // const checkoutProducts = productInDBToProduct(checkoutProductsData.data)
      // const productTotal = checkoutProductsData.total
      // console.log("发票新查询带翻页: ", invoicesData)
      dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: 1, total: productTotal, type: DisplayDataType.All }))
      dispatch(loadCategories({ categories: categories }))
      dispatch(loadProducts({ products: products }))
      dispatch(loadCustomers({ customers: customers }))
      dispatch(loadHoldingOrders({ holdingOrders: orders }))
      dispatch(loadAllInvoices({ invoices: invoices, pagination: invoicesPagination }))
      // dispatch(loadAllRefunds({ refunds: refunds }))
      setIsInProgress(false)
    }
    fetchData()
  }, [])
  //auth.currentUser

  useEffect(() => {
    console.log("pathname: ", pathname)
    console.log(`main 路径显示：app.getAppPath() : XXX __dirname: ${__dirname}, process.env.PUBLIC_URL是： ${process.env.PUBLIC_URL}`)

    switch (pathname) {
      case "/checkout":
        setCurrentTab(["1"])
        break
      case "/holdingorders":
        setCurrentTab(["3"])
        break
      case "/customers":
        setCurrentTab(["4"])
        break
      case "/invoices":
        setCurrentTab(["5"])
        break
      case "/refunds":
        setCurrentTab(["6"])
        break
      case "/customerscreen":
        setCurrentTab(["7"])
        break
      case "/setting":
        setCurrentTab(["8"])
        break
      case "/cashDeclaration":
        setCurrentTab(["9"])
        break
      default:
    }
  }, [pathname])

  useEffect(() => {//redux store 变换，刷新客户显示屏
    console.log("todo refresh customerScreen...", Checkout)
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.customerScreenSync({ Checkout, Customer }, (response) => {
        console.log('Secend: ', response);
      });
    }
  }, [Customer, Checkout])

  return (
    <Layout
      style={{ minHeight: height, width: width }}
    >
      <Content>
        {/* <CustomerScreenMainContent /> */}
        {isInitPrinterAlertModal ?
        <ActionConfirmModal
          isVisible={isInitPrinterAlertModal}
          description={"Printer Error. Please Restart The Printer, Then Click 'Reset'"}
          actionText={"Reset"}
          cancelText={"Cancel"}
          onConfirm={() => {
            //@ts-ignore
            if (window?.electronAPI) {
              console.log("打印机重新初始化...", false)
              //@ts-ignore
              window.electronAPI.initPrinter(PosConfig.printerInterface, (response) => {
                console.log('重置打印机结果: ', response);
                if (response === 'failed') {
                  message.error("Printer init failed.")
                } else {
                  message.success("Printer init successed.")
                }
              })
            }
            setIsInitPrinterAlertModal(false)
          }}
          onCancel={() => {
            setIsInitPrinterAlertModal(false)
          }}
          rightButtonDanger={false}
        />
        : null
      }
        {isLoginModalVisible ?
          <LoginModal
            isVisible={isLoginModalVisible}
            setVisibility={(value: boolean) => {
              setIsLoginModalVisible(value)
            }}
            setIsCashDeclarationAlertModal={(value: boolean) => {
              setIsCashDeclarationAlertModal(value)
            }}
          />
          : null
        }
        {isCashDeclarationAlertModal ?
          <ActionConfirmModal
          isVisible={isCashDeclarationAlertModal}
          description={"Do you want to proceed Cash Declaration?"}
          actionText={"Yes"}
          cancelText={"No"}
          onConfirm={() => {
            navigate("/cashDeclaration?from=StartDeclaration")
            setIsCashDeclarationAlertModal(false)
          }}
          onCancel={() => {
            setIsCashDeclarationAlertModal(false)
          }}
          rightButtonDanger={false}
        />
          : null
        }
        <ProgressModal
          isVisible={isInProgress}
          description={"Data Loading, Please Wait..."}
          closable={false}
          onCancel={() => setIsInProgress(false)}
        />
        {
          pathname === "/" ?
            // <WelcomePage />
            <CheckoutPage setIsLoginModalVisible={(value: boolean) => setIsLoginModalVisible(value)} />
            :
            null
        }
        <Routes>
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/selfcheckout" element={<SelfCheckoutPage />} />
          {/* <Route path="/products" element={<ProductPage />}/> */}
          <Route path="/checkout" element={<CheckoutPage setIsLoginModalVisible={(value: boolean) => setIsLoginModalVisible(value)} />} />
          <Route path="/holdingorders" element={<OrderPage />} />
          <Route path="/invoices" element={<InvoiceHistoryPage />} />
          <Route path="/customers" element={<CustomerPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/refunds" element={<RefundPage />} />
          <Route path="/paymentsuccess" element={<PaymentSuccessPage />} />
          <Route path="/customerscreen" element={<CustomerScreen />} />
          <Route path="/cashDeclaration" element={<CashDeclarationPage />} />
        </Routes>
      </Content>
      {/* <Footer style={{ textAlign: "center", marginBottom: 0 }}>AiBuild © 2022</Footer> */}
    </Layout>
  )
}
