import "../css/antd.css"
import React, { useState, useEffect, useCallback, useRef } from "react"
import { Layout, Menu, Breadcrumb, List, Card, Drawer, Table, Typography, Button, Tabs, message, Switch } from 'antd'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons'
import { Product, Category, Order, Customer, InvoiceHead } from "../models"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import { selectCustomer, selectInvoice, loadInvoiceByCustomer, showKeyboard, switchKeyboardLanguage, searchCustomer, searchInvoice, serveCustomer, searchCustomerInvoices } from "../redux/Customer/slice"
import SearchBar from "../components/common/SearchBar"
import useViewport from "../hooks/useViewport"
import InvoiceDetail from "../components/Invoice/InvoiceDetail"
import CustomerDetail from "../components/Customer/CustomerDetail"
import KeyboardModal from "../components/common/KeyboardModal"
import moment from "moment"
import { dateFormat } from "../constants/data"
import ActionConfirmModal from "../components/common/ActionConfirmModal"
import CustomerVirtualTable from "../components/Customer/CustomerVirtualTable"
import { getCustomerByCustomerCode, searchCustomerOnline } from "../api/customer"
import { searchInvoicesByCustomerAndItem } from "../api/invoice"
import { customerInDBToCustomer, invoiceInDBsToLocalInvoices } from "../utils/Global"
import { getInvoiceByInvoiceNo } from "../api/invoice"
import { GlobalState, freshBarcode, freshWeight } from "../redux/Globals/slice"
import { Link } from "react-router-dom"
import ScrollButtons from "../components/common/ScrollButtons"
import { useNavigate } from "react-router-dom"

const { Title } = Typography
const { Content } = Layout
let buffer = '' // buffer for constructing the barcode from key presses
let isScanable: boolean = false

export default function CustomerInvoicePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width, height } = useViewport()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { customers, selectedCustomer, invoicesByCustomer, selectedInvoice, allInvoices, isKeyboardVisible, keyboardLanguage, searchedCustomers, searchedCustomerInvoices } = Customer
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)

  const [searchType, setSearchType] = useState<"customer" | "invoice">("customer")
  const [customerSearchValue, setCustomerSearchValue] = useState<string>("")
  const [invoiceSearchValue, setInvoiceSearchValue] = useState<string>("")
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceHead>()
  const [isLeftDrawerVisible, setIsLeftDrawerVisible] = useState<boolean>(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [customerDisplayType, setCustomerDisplayType] = useState<"search" | "all">("all")
  const [invoiceDisplayType, setInvoiceDisplayType] = useState<"search" | "all">("all")
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [invoicePageUpDown, setInvoicePageUpDown] = useState<number>(1)
  const [confirmMsg, setConfirmMsg] = useState("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [editCustomer, setEditCustomer] = useState<Customer | null>(null)
  const [oldPage, setOldPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0)
  const SwitchStatus = useRef(false)
  // const searchMessage = useRef<string>("")

  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const onClose = () => {
    setIsDrawerVisible(false)
  }

  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // if (!v1) return
    // v1.scrollTop -= 624
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // if (!v1) return
    // v1.scrollTop += 624
  }

  const onSearchCustomer = (value: string) => {
    const result: Array<Product> = customers.filter((customer: Customer) => {
      return (customer.CustomerCode.toLowerCase().includes(value.toLowerCase()) || customer.name.toLowerCase().includes(value.toLowerCase()) || customer.Mobile.toLowerCase().includes(value.toLowerCase())) ? true : false
    })
    setCustomerDisplayType("search")
    dispatch(searchCustomer({ customers: result }))
  }

  const onSearchCustomerOnline = async (value: string) => {
    console.log("SwitchStatus", SwitchStatus.current)
    let result: any
    let searchedCustomers:any 
    try {
      if (SwitchStatus.current) {//精确查找客户
        result = await getCustomerByCustomerCode(value)
        console.log("单一客户查询结果", result)
        const resultArr: Array<any> = []
        resultArr.push(result)
        searchedCustomers = customerInDBToCustomer(resultArr)
      } else {//模糊匹配客户
        result = await searchCustomerOnline(value)
        console.log("模糊匹配客户", result)
        searchedCustomers = customerInDBToCustomer(result.data)
      }
    } catch (error) {
      console.log("result: error........", error)
    }
    if (result && result.data.length > 0) {
      dispatch(searchCustomer({ customers: searchedCustomers }))
      console.log("客户查询结果首项",searchedCustomers[0].CustomerCode)
      dispatch(selectCustomer({ customer: searchedCustomers[0] }))
      onSearchInvoiceByItems(undefined, searchedCustomers[0])
      // searchMessage.current = ""
      //@ts-ignore
      // dispatch(loadInvoiceByCustomer({ customerCode: searchedCustomers[0].CustomerCode }))
    } else {
      message.error(`invoice or customer ${value} is not exist`)
      dispatch(searchCustomer({ customers: [] }))
    }
    setCustomerDisplayType("search")
  }

  const onSearchInvoiceByItems = async (value: string | undefined, selectedCustomer: Customer, page?: number) => {
    console.log("onSearchInvoiceByItems", value, "selectedCustomer", selectedCustomer)
    if (selectedCustomer) {
      const result = await searchInvoicesByCustomerAndItem(selectedCustomer.CustomerCode, value, page)
      console.log("result: ", result)
      if (result.data.length > 0) {
        setTotalPageCount(result.total)
        setInvoiceDisplayType("search")
        dispatch(searchCustomerInvoices({ invoices: invoiceInDBsToLocalInvoices(result.data) }))
      } else {
        setTotalPageCount(0)
        setInvoiceDisplayType("search")
        dispatch(searchCustomerInvoices({ invoices: [] }))
        
      }
    }
  }

  // const onSearchInvoice = (value: string) => {
  //   const result: Array<Product> = allInvoices.filter((invoice: InvoiceHead) => {
  //     return (invoice.InvoiceNo.toLowerCase().includes(value.toLowerCase())) ? true : false
  //   })
  //   dispatch(searchInvoice({ invoices: result }))
  // }

  const onConfirmSelect = () => {
    if (editCustomer) {
      dispatch(serveCustomer({ customer: editCustomer }))
    }
    setIsConfirmModalVisible(false)
    //go to checkout page immediately
    navigate("/checkout")
  }

  const customerColumns = [
    {
      title: "Customer Code",
      dataIndex: "CustomerCode",
      key: "CustomerCode",
      width: "20%",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "createdBy",
      width: "20%",
      render: (text: any) => <Typography>{text}</Typography>
    },

    {
      title: "Points",
      dataIndex: "Points",
      key: "id",
      width: "10%",
      render: (text: any, item: Customer) => <Typography style={{ textAlign: "right" }}>{text}</Typography>
    },
    {
      title: "Actions",
      // dataIndex: "id",
      key: "id",
      width: "30%",
      // width: "125%",
      render: (text: any, item: Customer) => (
        // <div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
          <button
            className="viewDetailsButton"
            style={{ width: "140px" }}
            onClick={() => {
              dispatch(selectCustomer({ customer: item }))
              setIsLeftDrawerVisible(true)
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/details-icon.png"} className="pageIcon" />
            View Details
          </button>

          <button
            className="paidStatus"
            style={{ width: "140px" }}
            onClick={() => {
              setConfirmMsg("Are you sure to select \n" + item.name + "\nas current customer?")
              setEditCustomer(item)
              setIsConfirmModalVisible(true)
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/scanToRefund-icon.png"} className="pageIcon" />
            Select Customer
          </button>

        </div>
      )
    }
  ]

  const invoiceColumns = [
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Customer Name",
      dataIndex: "CustomerCode",
      key: "InvoiceNo",
      render: (customerCode: string) => {
        let customerName = "Cash Customer"
        const filteredCustomers = customers.filter((customer: Customer) => { return customer.CustomerCode === customerCode })
        if (filteredCustomers.length > 0) {
          customerName = filteredCustomers[0].name
        }
        return (
          <Typography>{customerName}</Typography>
        )
      }
    },

    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceNo",
      render: (date: string) => <Typography>{moment(date).format(dateFormat)}</Typography>
    },
    {
      title: "Total",
      dataIndex: "Amount",
      key: "InvoiceNo",
      render: (amount: number) => <Typography style={{ textAlign: "right" }}>${amount?.toFixed(2)}</Typography>
    },
    {
      title: "Points Earned",
      dataIndex: "RewardPoints",
      key: "InvoiceNo",
      render: (points: number) => <Typography style={{ textAlign: "right" }}>{points ? points : 0}</Typography>
    },
    {
      title: "Points Redeemed",
      dataIndex: "RewardPoints",
      key: "InvoiceNo",
      render: (points: number, item: InvoiceHead) => {
        let redeemPoints = 0
      //得到RedeemPoints
      item.LoyaltyPoints?.forEach(point => {
        if (point.RedeemPoints > 0) {
          redeemPoints = point.RedeemPoints
        }
      })
        return <Typography style={{ textAlign: "right" }}>-{redeemPoints}</Typography>
      }
    },
    {
      title: "Points",
      dataIndex: "RewardPoints",
      key: "InvoiceNo",
      render: (points: number, item: InvoiceHead) => {
        let redeemPoints = 0
      //得到RedeemPoints
      item.LoyaltyPoints?.forEach(point => {
        if (point.RedeemPoints > 0) {
          redeemPoints = point.RedeemPoints
        }
      })
        return <Typography style={{ textAlign: "right" }}>{(item.CurrentPoints? item.CurrentPoints : 0) + (item.RewardPoints? item.RewardPoints : 0) - redeemPoints}</Typography>
      }
    },
    {
      title: "Actions",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
      render: (text: any, item: InvoiceHead) =>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <button
            className="viewDetailsButton"
            // type="primary"
            // size="large" 
            onClick={() => {
              setCurrentInvoice(item)
              setIsDrawerVisible(true)
            }}
            style={{ height: "80px" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/details-icon.png"} className="pageIcon" />
            View Details
          </button>
        </div>
    }
  ]

  //begin of 处理扫描invoiceNo事件

  const handleBarcodeScan = async (data: string) => {
    if (isScanable) {
      console.log("handel客户发票barcode ::", data)
      const result = await getInvoiceByInvoiceNo(data)
      if (result) {
        setCurrentInvoice(result)
        setIsDrawerVisible(true)
      }
      else {
        // searchMessage.current = `Invoice ${data} is not exist.`
        onSearchCustomerOnline(data)
      }
    } else {
      console.log("customer不执行串口监听")
    }
  }

  const scannerCallback = useCallback((event) => {
    let data = buffer || ''
    if (event.key === '') {
      return
    }
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ''
      handleBarcodeScan(data)
    }
  }, [])

  useEffect(() => {
    isScanable = true
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      isScanable = false
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [])
  // end of 处理扫描invoiceNo事件

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onInvoiceFirstPage = () => {
    onSearchInvoiceByItems(invoiceSearchValue, selectedCustomer, 1)
    // let v1 = document.getElementsByClassName("ant-table-body")[1]
    // if (v1) {
    //   // console.log("v1 scroll height: ", v1.scrollHeight)
    //   // console.log("v1 scroll top: ", v1.scrollTop)
    //   v1.scrollTop = 0
    // }
  }
  const onInvoiceLastPage = () => {
    onSearchInvoiceByItems(invoiceSearchValue, selectedCustomer, totalPageCount)
    // let v1 = document.getElementsByClassName("ant-table-body")[1]
    // if (v1) {
    //   // console.log("v1 scroll height: ", v1.scrollHeight)
    //   // console.log("v1 scroll top: ", v1.scrollTop)
    //   v1.scrollTop = v1.scrollHeight
    // }
  }

  const onInvoicePageUp = () => {
    if (invoicePageUpDown > 0) {
      setInvoicePageUpDown(invoicePageUpDown - 1)
      onSearchInvoiceByItems(invoiceSearchValue, selectedCustomer, invoicePageUpDown - 1)
    }
    
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // if (!v1) return
    // v1.scrollTop -= 624
  }

  const onInvoicePageDown = () => {
    if (invoicePageUpDown < totalPageCount - 1) {
      setInvoicePageUpDown(invoicePageUpDown + 1)
      onSearchInvoiceByItems(invoiceSearchValue, selectedCustomer, invoicePageUpDown + 1)
    }
    
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // if (!v1) return
    // v1.scrollTop += 624
  }

  const onInvoicePageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.52
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.52
    }
    setOldPage(newPage)
  };

  const switchOnChange = (checked: boolean) => {
    console.log("switchOnChange", checked)
    SwitchStatus.current = checked
  }

  useEffect(() => {
    if (Globals.barcode) {
      handleBarcodeScan(Globals.barcode)
      dispatch(freshBarcode({
        barcode: ""
      }))
    }
  }, [Globals.barcode])

  useEffect(() => onInvoicePageUpDown(invoicePageUpDown), [invoicePageUpDown]);

  return (
    <div className="doublePageContainer" style={{ width, height }}>
      <ActionConfirmModal
        isVisible={isConfirmModalVisible}
        description={confirmMsg}
        actionText={"Yes"}
        cancelText={"No"}
        onConfirm={onConfirmSelect}
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }}
      />
      <KeyboardModal
        key={searchType}
        isVisible={isKeyboardVisible}
        language={keyboardLanguage}
        onHideKeyboard={() => {
          dispatch(showKeyboard({ isVisible: false }))
        }}
        onSwitchLanguage={() => {
          dispatch(switchKeyboardLanguage({}))
        }}
        onConfirm={(value: string) => {
          if (searchType === "customer") {
            setCustomerSearchValue(value)
            // onSearchCustomer(value)
            onSearchCustomerOnline(value)
          } else {
            setInvoiceSearchValue(value)
            onSearchInvoiceByItems(value, selectedCustomer)
            // onSearchInvoice(value)
          }
          dispatch(showKeyboard({ isVisible: false }))
        }}
        onSelect={(value) => {
          if (searchType === "customer") {
            setCustomerSearchValue(value)
            // onSearchCustomerOnline(value)
          } else {
            setInvoiceSearchValue(value)
            // onSearchInvoiceByItems(value)
          }
        }}
        initialValue={searchType === "customer"? customerSearchValue : invoiceSearchValue}
      />
      <Drawer
        className="drawer"
        title="Customer Detail"
        placement="left"
        onClose={() => {
          setIsLeftDrawerVisible(false)
        }}
        visible={isLeftDrawerVisible}
        width={width * 0.5}
        closable={false}
      >
        {
          selectedCustomer ?
            <CustomerDetail customer={selectedCustomer} />
            :
            null
        }
      </Drawer>
      <Drawer
        className="drawer"
        title="Invoice Detail"
        placement="right"
        onClose={onClose}
        visible={isDrawerVisible}
        width={width * 0.7}
        closable={false}
      >
        {
          currentInvoice ?
            <InvoiceDetail invoice={currentInvoice} closeDrawer={() => setIsDrawerVisible(false)} />
            :
            null
        }
      </Drawer>
      <div className="doublePage01">
        <div style={{ width: "100%", marginLeft: 10, marginRight: "-40px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "inline-flex" }}>
            <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/product-icon.png"} className="pageIcon" /></div>
            <div className="pageTitle">CUSTOMERS</div>
          </div>
          {/* <Title level={4} className="pageTitle">REFUNDS</Title> */}
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <button
              className="pageSearchButton"
              onClick={() => { setCustomerDisplayType("all") }}
              style={{ marginRight: 10, alignSelf: "center" }}
            >
              Show All
            </button>
            <SearchBar
              onSearch={onSearchCustomer}
              containerStyle={{ justifyContent: "flex-end", display: "flex", width: 350, alignSelf: "center" }}
              placeholderText="Search By Customer Name/Mobile/Code"
              value={customerSearchValue}
              onChange={setCustomerSearchValue}
              onClick={() => {
                setCustomerSearchValue("")
                setSearchType("customer")
                dispatch(showKeyboard({ isVisible: true }))
              }}
            />
            <Switch
              style={{ height: "25px", marginTop: "5px", marginLeft: "-30px" }}
              checkedChildren="Equal"
              unCheckedChildren="Like"
              // defaultChecked 
              onChange={switchOnChange}
            />
          </div>
        </div>
      </div>
      <div className="doublePage03">
        {
          customerDisplayType === "search" ?
            <CustomerVirtualTable
              //@ts-ignore
              columns={customerColumns}
              dataSource={searchedCustomers}
              //@ts-ignore
              onRow={(record: any, rowIndex: number) => {
                return {
                  onClick: () => {
                    // console.log("onclick row")
                    dispatch(selectCustomer({ customer: record }))
                    onSearchInvoiceByItems(undefined, record)
                    //@ts-ignore
                    // dispatch(loadInvoiceByCustomer({ customerCode: record.CustomerCode }))
                  }
                }
              }}
              pagination={false}
              scroll={{
                y: height * 0.85,
              }}
              onViewDetail={(item) => {
                dispatch(selectCustomer({ customer: item }))
                setIsLeftDrawerVisible(true)
              }}
              onSelectCustomer={(item) => {
                setConfirmMsg("Are you sure to select \n" + item.name + "\nas current customer?")
                setEditCustomer(item)
                setIsConfirmModalVisible(true)
              }}
              newPage={pageUpDown}
            />
            :
            <CustomerVirtualTable
              //@ts-ignore
              columns={customerColumns}
              dataSource={customers}
              //@ts-ignore
              onRow={(record: any, rowIndex: number) => {
                return {
                  onClick: () => {
                    console.log("onclick row", record.CustomerCode)
                    dispatch(selectCustomer({ customer: record }))
                    onSearchInvoiceByItems(undefined, record)
                    //@ts-ignore
                    // dispatch(loadInvoiceByCustomer({ customerCode: record.CustomerCode }))
                  }
                }
              }}
              pagination={false}
              scroll={{
                y: height * 0.85,
              }}
              onViewDetail={(item) => {
                dispatch(selectCustomer({ customer: item }))
                setIsLeftDrawerVisible(true)
              }}
              onSelectCustomer={(item) => {
                setConfirmMsg("Are you sure to select \n" + item.name + "\nas current customer?")
                setEditCustomer(item)
                setIsConfirmModalVisible(true)
              }}
              newPage={pageUpDown}
            />
        }
      </div>
      <div className="doublePage05">
        <Link to="/checkout">
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Return
          </button>
        </Link>
        <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ScrollButtons
            onPageDown={onLastPage}
            onPageUp={onFirstPage}
            onRowDown={onPageDown}
            onRowUp={onPageUp}
          />
        </div>
        {/* <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <button
            className="unCheckedMenu2"
            style={{ backgroundColor: "#DCE3EC", width: "150px", display: "flex", justifyContent: "center", }}
            onClick={onPageUp}
          >
            Page Up
          </button>
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC", width: "150px", display: "flex", justifyContent: "center", }}
            onClick={onPageDown}
          >
            Page Down
          </button>
        </div> */}
      </div>
      <div className="doublePage02">
        <div style={{ width: "100%", marginLeft: 10, marginRight: "-40px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "inline-flex" }}>
            <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/refund-icon.png"} className="pageIcon" /></div>
            <div className="pageTitle">INVOICES BY CUSTOMER</div>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="pageSearchButton"
                onClick={() => {
                  setInvoiceDisplayType("all")
                }}
                style={{ marginRight: 10, alignSelf: "center" }}
              >
                Show All
              </button>
              <SearchBar
                onSearch={(value) => onSearchInvoiceByItems(value, selectedCustomer)}
                // containerStyle={{ width: 250 }}
                placeholderText="Input Product Description"
                value={invoiceSearchValue}
                onChange={setInvoiceSearchValue}
                onClick={() => {
                  setSearchType("invoice")
                  dispatch(showKeyboard({ isVisible: true }))
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="doublePage04">
        {
          (selectedCustomer && invoiceDisplayType === "search") ?
            <Table
              className="pageDetailTable pageDetailTableHeight900 "
              rowClassName={(_, index) => {
                if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
              }}
              columns={invoiceColumns}
              dataSource={searchedCustomerInvoices}
              pagination={false}
              scroll={{
                y: height * 0.8,
                // scrollToFirstRowOnChange: true
              }}
            />
            :
            (selectedCustomer && invoiceDisplayType === "all") ?
              <Table
                className="pageDetailTable pageDetailTableHeight900 "
                rowClassName={(_, index) => {
                  if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
                }}
                columns={invoiceColumns}
                dataSource={invoicesByCustomer}
                pagination={false}
                scroll={{
                  y: height * 0.855,
                  // scrollToFirstRowOnChange: true
                }}
              />
              :
              <Table
                className="pageDetailTable pageDetailTableHeight900 "
                rowClassName={(_, index) => {
                  if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
                }}
                columns={invoiceColumns}
                dataSource={[]}
                pagination={false}
                scroll={{
                  y: height * 0.855,
                  // scrollToFirstRowOnChange: true
                }}
              />
        }
        <ScrollButtons
          onPageDown={onInvoiceLastPage}
          onPageUp={onInvoiceFirstPage}
          onRowDown={onInvoicePageDown}
          onRowUp={onInvoicePageUp}
        />
      </div>
    </div >
  )
}
