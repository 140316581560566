const PAIRING_MENU = "PAIRING_MENU";
const POS_SALE_UI = "POS_SALE_UI";
const TXN = "TXN";
const REPRINT_RECEIPT = "REPRINT_RECEIPT";
const TXN_STATUS = "TXN_STATUS";
const SETTLEMENT = "SETTLEMENT";
const CANCEL = "CANCEL";

const TYPES = {
  PAIRING_MENU,
  POS_SALE_UI,
  TXN,
  REPRINT_RECEIPT,
  TXN_STATUS,
  SETTLEMENT,
  CANCEL
}

const pairingPath =
  "https://auth.sandbox.cloud.pceftpos.com/v1/pairing/cloudpos";
const tokenRequestPath =
  "https://auth.sandbox.cloud.pceftpos.com/v1/tokens/cloudpos";
const linklyEndpoint =
  "https://rest.pos.sandbox.cloud.pceftpos.com/v1/sessions/";
const posName = "React REST POS";
const posVersion = "1.0.0";
const posId = "f818d27b-c98c-4007-97f5-6eb173bb7d9b";
const posVendorId = "482c12c7-4506-482e-a05a-761c113c9a40";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const EFTPOS = {
  TYPES,
  pairingPath,
  tokenRequestPath,
  linklyEndpoint,
  posId,
  posVendorId,
  headers,
  posName,
  posVersion
}

export default EFTPOS 
