import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CashDeclaration } from "../../models"

interface CashDeclarationState {
  cashDeclarations: {
    list: Array<CashDeclaration>,
    pagination: {
      current: number,
      pageSize: number,
      total: number,
    }
  }
}

const initialState = {
  cashDeclarations: {}

} as CashDeclarationState

const cashDeclarationStateSlice = createSlice({
  name: "cashDeclaration",
  initialState,
  reducers: {
    refreshCashDeclarationList(state, action: PayloadAction<any>) {
      console.log("cashDeclaration结果集: ", action.payload)
      state.cashDeclarations = action.payload
    },

  }
})

export const {
  refreshCashDeclarationList
} = cashDeclarationStateSlice.actions

export default cashDeclarationStateSlice.reducer
