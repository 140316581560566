import React, { useState } from "react"
import { StyleSheet, Product, OrderItem, Category } from "../../models"
import { Image, Typography, Card, Button, InputNumber } from "antd"
import { StarOutlined, RightOutlined } from "@ant-design/icons"
import InputNumberButtonGroup from "../common/InputNumberButtonGroup"

interface CheckoutCategoryCardProp {
  category: Category
  cardWidth?: number
  cardHeight?: number
  cardColor?: string
  fontColor?: string
  imageWidth?: number
  imageHeight?: number
  showAddToCart?: boolean
  onCardPress?: (category: Category) => void
  onViewDetail?: (category: Category) => void
  onAddToCart?: (category: Category) => void
}

const CheckoutCategoryCard: React.FC<CheckoutCategoryCardProp> = ({
  category,
  cardHeight = "100%",
  cardWidth = "100%",
  // imageWidth = 60,
  showAddToCart = true,
  onCardPress,
  onViewDetail,
  onAddToCart,
  cardColor = "white",
  fontColor = "grey"
}) => {
  return (
    <Card
      className="categoryCard"
      onClick={(e) => {
        onCardPress && onCardPress(category)
      }}
      style={{ width: cardWidth, height: cardHeight, color: fontColor, backgroundColor: cardColor, borderRadius: 5, padding: 0 }}
      hoverable
    >

      <div style={{width: "100%", textAlign: "center" }}>
      {category.name}
      </div>
    </Card>
  )
}

export default CheckoutCategoryCard

const styles: StyleSheet = {
  container: {
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: "lightgrey",
    overflow: "hidden",
    margin: 5
  },
  infoContainer: {
    marginTop: 5,
    marginBottom: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  operationContainer: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    marginBottom: 5
  },
  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "grey",
  },
  descRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  descText: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
  },
  disabledLayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
  button: {
    marginLeft: 10
  }
}
