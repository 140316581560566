import React, { useState, useEffect } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Tooltip, Button, notification, AutoComplete, Form } from "antd"
import { RootStateOrAny, useSelector } from "react-redux"
import CustomerScanModal from "./CustomerScanModal"
import useViewport from "../../../hooks/useViewport"
import NumPad from "react-numpad"
import { useDispatch } from "react-redux"
import { redeemLoyaltyPoints } from "../../../redux/Checkout/slice"
import AlertModal from "../../common/AlertModal"
import { Customer, ScannnerFocusType } from "../../../models"
import { serveCustomer, appendCustomer } from "../../../redux/Customer/slice"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import KeyboardModal from "../../common/KeyboardModal"
import { reloadCustomerScreen } from "../../../utils/customerscreen-functions"
import { createNewCustomer } from "../../../api/customer"
import CustomerJoinModal from "./CustomerJoinModal"
import { customerInDBToCustomer } from "../../../utils/Global"

export default function CustomerScanRedeemSection() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { height, width } = useViewport()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer, customers } = Customer
  const Config = useSelector((state: RootStateOrAny) => state.Config)
  const { scannerFocus } = Config

  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState<boolean>(false)
  const [redeemPoints, setRedeemPoints] = useState(0)
  const [errorMsg, setErrorMsg] = useState("")
  const [searchCustomerValue, setSearchCustomerValue] = useState("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
  const [keyboardLanguage, setKeyboardLanguage] = useState("eng")
  const [isCustomerJoinModalVisible, setIsCustomerJoinModalVisible] = useState(false)

  const [currentMode, setCurrentMode] = useState<"display" | "new">("display")
  

  useEffect(() => {
    if (scannerFocus === ScannnerFocusType.CustomerCode) {
      setSearchCustomerValue("")
    }
  }, [scannerFocus])

  useEffect(() => {
    const registerScannerListener = () => {
      let buffer = '' // buffer for constructing the barcode from key presses
    
      document.addEventListener('keypress', event => {
        let data = buffer || ''
        if (event.key !== 'Enter') { // barcode ends with enter -key
          data += event.key
          buffer = data
        } else {
          buffer = ''
          console.log(data) // ready barcode ready for a use
          setSearchCustomerValue(data)
          searchCustomer(data)
        }
      })
    }
    registerScannerListener()
  }, [])

  const searchCustomer = (input: string) => {
    const selectedCustomer = customers.filter((customer: Customer) => { return customer.CustomerCode === input || customer.Mobile === input })
    // console.log("selected customer: ", selectedCustomer)
    if (selectedCustomer.length > 0) {
      const setCustomerPromise = () => new Promise((resolve, reject) => {
        dispatch(serveCustomer({ customer: selectedCustomer[0] }))
        resolve("finish")
      })
      setCustomerPromise()
      .then(() => {
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e)) 
    } else {
      setErrorMsg("Sorry, we could not find the searched customer")
      setIsAlertModalVisible(true)
    }
  }

  const onSubmitCustomerForm = async (values: any) => {
    const customer = {
      CustomerName: values.customername,
      Mobile: values.mobile,
      Email: values.email,
      Live: true
    }
    const result = await createNewCustomer(customer)
    if (result.CustomerCode) {
      const newCustomer = customerInDBToCustomer([result])[0]
      dispatch(appendCustomer({ customer: newCustomer }))
      dispatch(serveCustomer({ customer: newCustomer }))
      setCurrentMode("display")
      form.setFieldsValue({
        "customername": "",
        "email": "",
        "mobile": ""
      })
    } else {
      setErrorMsg("This Phone Number / Email is Already Been Registered!")
      setIsAlertModalVisible(true)
    }
    // console.log("result: ", result)
    
  }

  return (
    <div style={{ height: height * 0.35, marginBottom: 50 }}>
      <ActionConfirmModal 
        isVisible={isConfirmModalVisible} 
        description={"Confirm Reset Customer and Points to Redeem?"} 
        actionText={"Confirm"} 
        cancelText={"Cancel"} 
        onConfirm={async () => {
          setIsConfirmModalVisible(false)
          setRedeemPoints(0)
          setSearchCustomerValue("")
          dispatch(serveCustomer({ customer: null }))
          dispatch(redeemLoyaltyPoints({ points: 0 }))
        }} 
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }} 
        rightButtonDanger={true}
      />
      <AlertModal 
        isVisible={isAlertModalVisible} 
        description={errorMsg} 
        buttonText={"OK"} 
        onCancel={() => setIsAlertModalVisible(false)} 
        onConfirm={() => setIsAlertModalVisible(false)}      
      />
     {/* <CustomerScanModal 
        isVisible={isCustomerModalVisible} 
        setVisibility={(value: boolean) => {
          setIsCustomerModalVisible(value)
        }} 
      />
      <CustomerJoinModal 
        isVisible={isCustomerJoinModalVisible} 
        setVisibility={(value: boolean) => {
          setIsCustomerJoinModalVisible(value)
        }} 
      /> */}
      <KeyboardModal
        initialValue={searchCustomerValue}
        isVisible={isKeyboardVisible} 
        language={keyboardLanguage} 
        onHideKeyboard={() => {
          setIsKeyboardVisible(false)
        }} 
        onSwitchLanguage={() => {
          if (keyboardLanguage === "eng") {
            setKeyboardLanguage("kor")
          } else {
            setKeyboardLanguage("eng")
          }
        }} 
        onConfirm={(value: string) => {
          setSearchCustomerValue(value)
          searchCustomer(value)
          setIsKeyboardVisible(false)
        }}
        onSelect={(value) => {
          setSearchCustomerValue(value)
        }}
      />
      
      <div style={{ display: "flex", backgroundColor: "white", flexDirection: "column", zIndex: 100, marginBottom: 20 }}>
      {
        servingCustomer ?
        <div style={{ marginLeft: 20, marginTop: 20 }}>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 20, alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row"}}>
              <Typography style={{ fontWeight: "bold", fontSize: 18, marginRight: 10 }}>Name: </Typography>
              <Typography style={{ fontSize: 18, marginRight: 20 }}>{servingCustomer.name}</Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "row"}}>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 18 }}>Points: </Typography>
              <Typography style={{ fontSize: 18, marginRight: 20 }}>{servingCustomer.Points}</Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "row"}}>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 18 }}>Mobile: </Typography>
              <Typography style={{ fontSize: 18, marginRight: 20 }}>{servingCustomer.Mobile}</Typography>
            </div>

            <Button 
              danger
              type="primary" 
              onClick={() => {
                setIsConfirmModalVisible(true)
              }}
              style={{ marginRight: 20, width: 200, height: 40, fontSize: 20 }} 
            >
              Reset
            </Button>
          </div>
          
          <div style={{ marginBottom: 30, display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography style={{ fontSize: 18, marginRight: 20 }}>
              Not the Current Customer? Please Re-Scan
            </Typography>
            
            <Input 
              value={searchCustomerValue} 
              onChange={(e) => {
                setSearchCustomerValue(e.target.value)
              }}
              onClick={() => {
                setIsKeyboardVisible(true)
              }}
              style={{ width: 300, fontSize: 20, height: 40, marginRight: 10 }} 
              placeholder={"Please Scan Customer Code"} 
            />
            
          </div>

         
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 30 }}>
            <NumPad.Number
              onChange={(value: string) => { 
                const numberInput = Number(value)
                if (numberInput > servingCustomer.Points) {
                  setErrorMsg("You Can Not Redeem Points Larger than Current Points!")
                  setIsAlertModalVisible(true)
                } else if (!Number.isInteger(numberInput/200)) {
                  setErrorMsg("You Can Only Redeem Points that are Multiplies of 200!")
                  setIsAlertModalVisible(true)
                } else {
                  setRedeemPoints(numberInput)
                  dispatch(redeemLoyaltyPoints({ points: numberInput }))
                }
              }}
              value={redeemPoints}
              decimal={0}
              negative={false}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 18, alignSelf: "center" }}>Redeem Points (200pts=$1):</Typography>
                <Input value={redeemPoints} style={{ width: 200, fontSize: 20, height: 40 }}/>
              </div>
            </NumPad.Number>
            {/* <Button 
              type="primary" 
              onClick={() => {
                const redeemPointsPromise = () => new Promise((resolve, reject) => {
                  dispatch(redeemLoyaltyPoints({ points: redeemPoints }))
                  resolve("finish")
                })
                redeemPointsPromise()
                .then(() => {
                  // reloadCustomerScreen()
                })
                .catch((e) => console.log(e))
              }}
              style={{ marginRight: 20, width: 200, marginLeft: 20, backgroundColor: "green", height: 40, fontSize: 20 }} 
            >
              Confirm Redeem
            </Button> */}
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row"}}>
            <Typography style={{ fontWeight: "bold", fontSize: 22, marginRight: 10 }}>Offers Available: </Typography>
            <Typography style={{ fontSize: 22, marginRight: 20 }}>Double Points on Specific Items</Typography>
          </div> */}
        </div>
        :
        currentMode === "new" ?
        <div style={{ display: "flex", justifyContent: "center", height: 300, flexDirection: "column", marginLeft: 20 }}>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
            <Typography style={{ fontWeight: "bold", fontSize: 22, marginRight: 20, alignSelf: "center" }}>
              New Customer Join
            </Typography>
            <Button
              type="primary"
              style={{ height: 50, width: 250, fontSize: 20, alignSelf: "center" }}
              onClick={() => {
                setCurrentMode("display")
              }}
            >
              Swicth to Customer Scan
            </Button>
          </div>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true, username: "admin@aibuild.com", password: "password" }}
            onFinish={onSubmitCustomerForm}
            form={form}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={<Typography style={{ fontWeight: "bold", fontSize: 22, marginRight: 10 }}>Customer Name</Typography>}
              name="customername"
              rules={[{ required: true, message: 'Please input customer name!' }]}
              style={{ width: 600, margin: 10 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<Typography style={{ fontWeight: "bold", fontSize: 22, marginRight: 10 }}>Email</Typography>}
              name="email"
              rules={[{ required: false, message: 'Please input email!' }]}
              style={{ width: 600, margin: 10 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<Typography style={{ fontWeight: "bold", fontSize: 22, marginRight: 10, alignSelf: "center" }}>Mobile</Typography>}
              name="mobile"
              rules={[{ required: true, message: 'Please input valid mobile number!', pattern: new RegExp(/^[0-9]{10,10}$/) }]}
              style={{ width: 600, margin: 10 }}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              wrapperCol={{ offset: 8, span: 16 }}
              style={{ marginTop: 20, alignSelf: "center" }}
            >
              <Button 
                type="primary" 
                htmlType="submit"
                style={{ height: 50, width: 250, backgroundColor: "green", fontSize: 20 }}
              >
                Join
              </Button>
            </Form.Item>
          </Form>
        </div>
        :
        <div style={{ marginLeft: 20, marginTop: 20 }}>
          <div style={{ marginBottom: 30, display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
              <Typography style={{ fontWeight: "bold", fontSize: 22, marginRight: 20, alignSelf: "center" }}>
                Scan Existing Customer / Join as New Customer
              </Typography>
              <Button
                type="primary"
                style={{ height: 50, width: 250, fontSize: 20, alignSelf: "center", marginRight: 20 }}
                onClick={() => {
                  setCurrentMode("new")
                }}
              >
                New Customer Join
              </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input 
                value={searchCustomerValue} 
                onChange={(e) => {
                  setSearchCustomerValue(e.target.value)
                }}
                onClick={() => {
                  setIsKeyboardVisible(true)
                }}
                style={{ width: 600, fontSize: 22, height: 50, marginRight: 10 }} 
                placeholder={"Please Scan Membership Card or Input Customer Mobile"} 
              />
              {/* <Button 
                type="primary" 
                onClick={() => {
                  // setIsCustomerModalVisible(true)
                  searchCustomer(searchCustomerValue)
                }}
                style={{ marginRight: 20, width: 200, height: 50, fontSize: 22 }} 
              >
                Search
              </Button> */}
            </div>
          </div>
        </div>
      }
      </div>
    </div>
  )
}
