import React, { useState, useEffect } from "react"
import { Form, Button, Input, Typography, Row, Col, Select, message, Switch } from "antd"
import useViewport from "../../hooks/useViewport"
import { getConfiguration, saveConfiguration } from "../../utils/configurate"
// import {getConfiguration, saveConfiguration} from "../../utils/configurate"
import { SerialPortInfo } from "../../models";

const ScannerTab = () => {
  const [form] = Form.useForm()
  const { width } = useViewport()
  const [portEnums, setPortEnums] = useState<any[]>()
  const parityOptions = [
    { label: "none", value: "none" },
    { label: "even", value: "even" },
    { label: "odd", value: "odd" },
  ]
  const PosConfig = getConfiguration("ScannerTab")
  const {
    scannerSerialPort,
    scannerBaudRate,
    scannerDataBits,
    scannerStopBits,
    scannerParity,
    scaleSerialPort,
    scaleBaudRate,
    scaleDataBits,
    scaleStopBits,
    scaleParity,
    isScanReadable,
    isScaleReadable

  } = PosConfig
  const initValues = {
    scannerSerialPort: "COM3",
    scannerBaudRate: 9600,
    scannerDataBits: 8,
    scannerStopBits: 1,
    scannerParity: "none",
    scaleSerialPort: "COM4",
    scaleBaudRate: 9600,
    scaleDataBits: 7,
    scaleStopBits: 1,
    scaleParity: "even",
    isScanReadable: true,
    isScaleReadable: true,
  }
  useEffect(() => {
    //获得可用的串口列表
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window?.electronAPI?.listSerialPorts((ports:SerialPortInfo[]) => {
        if (ports.length > 0) {
          const portsEnum = ports.map((port:SerialPortInfo) => ({label: port.path, value: port.path}))
          setPortEnums(portsEnum)
        } else {
          message.warning("No ports discovered")
        }
      });
    }

    if (scannerSerialPort) {
      form.setFieldsValue({
        scannerSerialPort,
        scannerBaudRate,
        scannerDataBits,
        scannerStopBits,
        scannerParity,
        scaleSerialPort,
        scaleBaudRate,
        scaleDataBits,
        scaleStopBits,
        scaleParity,
        isScanReadable,
        isScaleReadable
      })
    } else {
      form.setFieldsValue(initValues)
    }

  }, [])
  const onFinish = async (values: any) => {
    saveConfiguration(values)
    //重新初始化scanner和scale的串口
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window?.electronAPI?.initSerialPorts(values, (response:any) => {
        message.success("Serial Ports of Scanner and Scale have reinitialize", response)
      });
    }
    // alert('This function just run in electron env.')
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
      <Typography style={{ marginBottom: 20, color: "grey" }}>Enter scanner configuration for this POS</Typography>
      <Form
        // labelCol={{ span: 100 }}
        wrapperCol={{ span: 100 }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        style={{ width: "50%" }}
      >
        <Row gutter={24}>
          <Col span={11}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // marginRight: 10,
              borderRadius: 10,
              border: "1px solid #979797"
            }}
          >
            <div className="modalTitle">Scanner</div>
            <Form.Item
              label="Serial Port"
              name="scannerSerialPort"
              rules={[{ required: true, message: "Enter Serial Port for this Scanner" }]}
            >
              <Select  
                style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }}
                options={portEnums} 
              />
              {/* <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} /> */}
            </Form.Item>

            <Form.Item
              label="Baud Rate"
              name="scannerBaudRate"
              rules={[{ required: true, message: "Enter baudRate for this Scanner" }]}
            >
              <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} />
            </Form.Item>

            <Form.Item
              label="Data Bits"
              name="scannerDataBits"
              rules={[{ required: true, message: "Enter dataBits for this Scanner" }]}
            >
              <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} />
            </Form.Item>

            <Form.Item
              label="Stop Bits"
              name="scannerStopBits"
              rules={[{ required: true, message: "Enter dataBits for this Scanner" }]}
            >
              <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} />
            </Form.Item>

            <Form.Item
              label="parity"
              name="scannerParity"
              rules={[{ required: false, message: "Enter dataBits for this Scanner" }]}
            >
              <Select style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }}
                options={parityOptions}
              />
              {/* <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} /> */}
            </Form.Item>

            <Form.Item
              label="Read Method"
              name="isScanReadable"
              // rules={[{ required: false, message: "Connect with COM?" }]}
            >
              <Switch
              style={{ height: "25px", width: "100px" }}
              checkedChildren="COM"
              unCheckedChildren="USB"
              defaultChecked={isScanReadable} 
              // onChange={switchOnChange}
            />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // marginRight: 10,
              borderRadius: 10,
              border: "1px solid #979797"
            }}
          >
            <div className="modalTitle">Scale</div>
            <Form.Item
              label="Serial Port"
              name="scaleSerialPort"
              rules={[{ required: true, message: "Enter Serial Port for this Scale" }]}
            >
              <Select  
                style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }}
                options={portEnums} 
              />
            </Form.Item>

            <Form.Item
              label="Baud Rate"
              name="scaleBaudRate"
              rules={[{ required: true, message: "Enter baudRate for this Scale" }]}
            >
              <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} />
            </Form.Item>

            <Form.Item
              label="Data Bits"
              name="scaleDataBits"
              rules={[{ required: true, message: "Enter dataBits for this Scale" }]}
            >
              <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} />
            </Form.Item>

            <Form.Item
              label="Stop Bits"
              name="scaleStopBits"
              rules={[{ required: true, message: "Enter dataBits for this Scale" }]}
            >
              <Input style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }} />
            </Form.Item>

            <Form.Item
              label="parity"
              name="scaleParity"
              rules={[{ required: false, message: "Enter dataBits for this Scale" }]}
            >
              <Select style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }}
                options={parityOptions}
              />
            </Form.Item>

            <Form.Item
              label="Read Method"
              name="isScaleReadable"
              // rules={[{ required: false, message: "Connect with COM?" }]}
            >
              <Switch
              style={{ height: "25px", width: "100px" }}
              checkedChildren="COM"
              unCheckedChildren="USB"
              defaultChecked={isScaleReadable} 
              // onChange={switchOnChange}
            />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item >
          <Button type="primary" htmlType="submit" style={{ marginTop: "20px", width: "100%", height: "50px", borderRadius: "4px", border: "none" }}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ScannerTab
