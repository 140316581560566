import axios from "axios"
import POSCons from "../constants/EFTPOS"

export const sendPairRequest = (params: any, callback: any) => {
  const username = params.username
  const password = params.password
  const pairCode = params.pairCode
  axios
  .post(
    POSCons.pairingPath,
    {
      username,
      password,
      pairCode,
    },
    // POSCons.headers
  )
  .then((response) => {
    console.log("SETTING SECRET")
  })
  .catch((error) => {
    console.log("error :>> ", error)
  })
}

export const getToken = (params: any, callback: any) => {
  const secret = params.secret
  const posName = POSCons.posName
  const posVersion = POSCons.posVersion
  const posId = POSCons.posId
  const posVendorId = POSCons.posVendorId
  axios
  .post(
    POSCons.tokenRequestPath,
    {
      secret,
      posName,
      posVersion,
      posId,
      posVendorId,
    },
    // POSCons.headers
  )
  .then((response) => {
    console.log("response getToken :", response)
    // setToken(response.data.token);
    // setTokenExpiry(Date.now() + response.data.expirySeconds * 1000);
    console.log("SETTING PAIRED")
    // setPaired(true);
  })
  .catch((error) => {
    console.log("error :>> ", error)
  })
}

export const retrieveLinklyData = (key: string) => {
  let data = localStorage.getItem(key)
  if (data) {
    return JSON.parse(data)
  } else return null
}

export const storeLinklyData = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}
