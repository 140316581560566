import React, { useState } from "react"
import { Input, Button, Modal, Typography } from "antd"
import useViewport from "../../hooks/useViewport"

const { Title } = Typography


interface Props {
  isVisible: boolean
  description: string
  actionText: string
  cancelText: string
  onConfirm: () => void
  onCancel: () => void
  containerStyle?: React.CSSProperties
  leftButtonDanger?: boolean
  rightButtonDanger?: boolean
}

const ActionConfirmModal = ({ isVisible, description, onConfirm, onCancel, containerStyle, actionText, cancelText, leftButtonDanger, rightButtonDanger }: Props) => {
  const { width } = useViewport()

  return (
    <Modal
      // transitionName="none"
      // maskTransitionName="none"
      className="modalConfirm"
      centered
      width={width * 0.5}
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
      closable={false}
    >
      <div style={containerStyle ? containerStyle : { margin: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", height: 200 }}>
        <div className="comment">{description}</div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 50, width: "100%" }}>
          <button
            className={leftButtonDanger ? "dangerButton" : "normalButton"}
            onClick={onCancel}
          >
            {cancelText}
          </button>
          <button
            className={rightButtonDanger ? "dangerButton" : "normalButton"}
            onClick={onConfirm}
          >
            {actionText}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ActionConfirmModal
