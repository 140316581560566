import {
  Button,
  Descriptions,
  Divider,
  PageHeader,
  Popconfirm,
  Spin,
  Typography,
  Image,
  Table,
  Tag,
} from "antd"
import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { InvoiceHead, InvoiceItem, Customer } from "../../models"
import RefundReceiptModal from "../Refund/RefundReceiptModal"
import { setReceiptCopy } from "../../redux/Payment/slice"
type Props = {
  invoice: InvoiceHead
}

export default function RefundDetail({ invoice }: Props) {
  const dispatch = useDispatch()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { customers, servingCustomer } = Customer
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRefunding, setIsRefunding] = useState(false)
  const [selectedRefundItems, setSelectedRefundItems] = useState<InvoiceItem[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [isRefundReceiptVisible, setIsRefundReceiptVisible] = useState(false)
  const [selectedMethod, setSelectedMethod] = useState<"cash" | "card" | "royalpay">("cash")

  useEffect(() => {
    // console.log("invoice:", invoice)
    if (invoice.ReceiptCustomerCopy && invoice.ReceiptCustomerCopy.indexOf("CUSTOMER COPY") > -1) {
      console.log('card -------------------->', invoice.ReceiptCustomerCopy.split(","))
      setSelectedMethod('card')
      dispatch(setReceiptCopy({ customerCopy: invoice.ReceiptCustomerCopy.split(",") }))
    }
  }, [invoice])
  const itemColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: "7%",
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Description",
      dataIndex: ["Product", "Description"],
      key: "ID",
      width: "37%",
      render: (description: string, record: InvoiceItem) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>{description}</Typography>
            <Tag
              color={"error"}
              style={{
                width: "50px",
                marginLeft: "auto",
                marginTop: "-20px",
                marginRight: "0px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "16px",
                borderRadius: "8px"
              }}>refund</Tag>
          </div>
        )
      }
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "ID",
      width: "10%",
      render: (text: any) => <Typography style={{textAlign: "right"}}>{text}</Typography>
    },
    {
      title: "UnitPrice",
      dataIndex: "Price",
      // dataIndex: "UnitCost",
      key: "ID",
      width: "13%",
      render: (text: any) => <Typography style={{textAlign: "right"}}>${text?.toFixed(2)}</Typography>
    },
    {
      title: "Subtotal",
      // dataIndex: "Price",
      key: "Subtotal",
      width: "13%",
      render: (text: any, record: InvoiceItem) => <Typography style={{textAlign: "right"}}>${(record.Price * record.Qty).toFixed(2)}</Typography>
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "ID",
      width: "10%",
      render: (text: any) => <Typography style={{textAlign: "right"}}>{text?.toFixed(0)}%</Typography>
    },
    {
      title: "GST",
      dataIndex: "GSTRate",
      key: "ID",
      width: "10%",
      render: (text: any) => <Typography style={{textAlign: "right"}}>{text?.toFixed(0)}%</Typography>
    },
  ]

  const RenderCustomerName = () => {
    let customerName = "Cash Customer"
    const filteredCustomers = customers.filter((customer: Customer) => { return customer.CustomerCode === invoice.CustomerCode })
    if (filteredCustomers.length > 0) {
      customerName = filteredCustomers[0].name
    }
    return (
      <Typography>{customerName}</Typography>
    )
  }

  const calculateRefundAmount = () => {
    let amount = 0
    // console.log(invoice.InvoiceItems)
    invoice.InvoiceItems.forEach(item => {
      if (selectedRowKeys.includes(item.ID)) {
        amount += item.Price
      }
    })
    return -amount
  }

  const getRefundInvoiceItems = () => {
    const result: InvoiceItem[] = []
    invoice.InvoiceItems.forEach(item => {
      if (selectedRowKeys.includes(item.ID)) {
        const newItem = {
          Price: item.Price,
          ProductCode: item.ProductCode,
          Qty: item.Qty,
          UnitCost: item.UnitCost,
          Discount: item.Discount,
          GSTRate: item.GSTRate
        }
        // const { InvoiceNo, Product, CreditedQty, DeductStockQty, KindFlag...newItem } = item
        //@ts-ignore
        result.push(newItem)
      }
    })
    return result
  }

  const onSelectChange = (newSelectedRowKeys: any[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  return (
    <div style={{marginTop: "16px"}}>
      <PageHeader
        className="detailsPageHeader"
        ghost={false}
        backIcon={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="detailNo">{invoice.InvoiceNo}</div>
            <Tag
              color={"error"}
              style={{
                marginLeft: "10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "16px",
                borderRadius: "8px"
              }}
            >
              incl refund
            </Tag>
          </div>

        }
        extra={[
          <button key="edit" className="printDetailsButton"
            onClick={() => {
              setIsRefundReceiptVisible(true)
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/print-icon.png"} className="pageIcon" />
            Print
          </button>
        ]}
      >
        <Spin tip="Loading..." spinning={isLoading}>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Store
            </div>
            <div className="detailDescValue">
              {invoice.StoreId ? invoice.StoreId : "STORE01"}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Sales
            </div>
            <div className="detailDescValue">
              {invoice.SalesName ? invoice.SalesName : "Supervisor"}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/customer-icon@2x.png"} className="pageIcon16" />Customer Name
            </div>
            <div className="detailDescValue">
              <RenderCustomerName />
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Reference Invoice No
            </div>
            <div className="detailDescValue">
              {invoice.ReferenceNo}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />Total Refund Amount
            </div>
            <div className="detailDescValue">
              ${invoice.Amount?.toFixed(2)}
            </div>
          </div>
          {
            invoice.RecvAccts ?
              <div className="detailDescriptions">
                <div className="detailDescLabel">
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/paymentMethod-icon@2x.png"} className="pageIcon16" />Refund Method
                </div>
                <div className="detailDescValue">
                  {/* {invoice.RecvAccts} */}
                  {invoice.RecvAccts[0]?.PayBy}
                </div>
              </div>
              :
              null
          }
          {/* <Descriptions bordered>
            <Descriptions.Item label={<div><img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Store</div>} span={3}>
              {invoice.StoreId ? invoice.StoreId : "STORE01"}
            </Descriptions.Item>
            <Descriptions.Item label="Sales" span={3}>
              {invoice.SalesName ? invoice.SalesName : "Supervisor"}
            </Descriptions.Item>
            <Descriptions.Item label="Customer Name" span={3}>
              <RenderCustomerName />
            </Descriptions.Item>
            <Descriptions.Item label="Reference Invoice No" span={3}>
              {invoice.ReferenceNo}
            </Descriptions.Item>
            <Descriptions.Item label="Total Refund Amount" span={3}>
              ${invoice.Amount?.toFixed(2)}
            </Descriptions.Item>
            {
              invoice.RecvAccts ?
                <Descriptions.Item label="Refund Method" span={3}>
                  {invoice.RecvAccts[0]?.PayBy}
                </Descriptions.Item>
                :
                null
            }
          </Descriptions> */}
          <Table
            rowClassName={(_, index) => {
              if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
            }}
            scroll={{
              y: 200,
              // scrollToFirstRowOnChange: true
            }}
            className="pageDetailTable"
            columns={itemColumns}
            dataSource={invoice.InvoiceItems}
            pagination={false}
          // rowSelection={rowSelection}
          // rowKey={(row) => row.ID}
          />
        </Spin>
        {isRefundReceiptVisible ?
          <RefundReceiptModal
            isVisible={isRefundReceiptVisible}
            //@ts-ignore
            invoice={invoice}
            onCancel={() => setIsRefundReceiptVisible(false)}
            isSale={false}
          />
          : null
        }
      </PageHeader>
    </div>
  )
}
