import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CartItem, Total, Order } from "../../models"

interface OrderState {
  orders: Array<Order>
  holdingOrders: Array<Order>
  selectedOrder: CartItem | null
  searchedOrders: Array<Order>
  isKeyboardVisible: boolean
  keyboardLanguage: string
  editable: any
  nextToken: string | null
  totalOrderNo: number
}

const initialState = {
  orders: [],
  holdingOrders: [],
  searchedOrders: [],
  selectedOrder: null,
  isKeyboardVisible: false,
  keyboardLanguage: "eng",
  editable: null,
  nextToken: null,
  totalOrderNo: 0
} as OrderState

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrder(state, action: PayloadAction<any>) {
      state.orders.unshift(action.payload.order)
      // state.orders.push(action.payload.order)
    },
    updateOrder(state, action: PayloadAction<any>) {
      state.orders.push(action.payload.item)
    },
    deleteOrder(state, action: PayloadAction<any>) {
      state.orders.push(action.payload.item)
    },
    loadHoldingOrders(state, action: PayloadAction<any>) {
      state.holdingOrders = action.payload.holdingOrders
    },
    addHoldingOrder(state, action: PayloadAction<any>) {
      state.holdingOrders.unshift(action.payload.order)
      // state.holdingOrders.push(action.payload.order)
    },
    removeHoldingOrder(state, action: PayloadAction<any>) {
      console.log(action.payload.orderId)
      const result = state.holdingOrders.filter(item => {return (item.id !== action.payload.orderId)})
      // console.log("result: ", result)
      state.holdingOrders = result
    },
    searchOrder(state, action: PayloadAction<any>) {
      state.searchedOrders = action.payload.orders
    },
    showKeyboard(state, action: PayloadAction<any>) {
      state.isKeyboardVisible = action.payload.isVisible
    },
    switchKeyboardLanguage(state, action: PayloadAction<any>) {
      if (state.keyboardLanguage === "eng") {
        state.keyboardLanguage = "kor"
      } else {
        state.keyboardLanguage = "eng"
      }
    }
  },
})

export const {
  addOrder,
  updateOrder,
  deleteOrder,
  addHoldingOrder,
  removeHoldingOrder,
  searchOrder,
  showKeyboard,
  switchKeyboardLanguage,
  loadHoldingOrders
} = orderSlice.actions

export default orderSlice.reducer
