import API, { pageSize, largePageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global"
import { notification } from "antd"
import axios from "axios"

export const listCustomers = async () => {
  try {
    const url = generateRequestUrl(API.queryCustomerList)
    const config = {
      params: {
        "pageSize": pageSize,
        "page": 1
      }
    }
    const response = await axios.get(url, config)
    return response.data

    // Need to handle multiple page request if exceeds pagesize
    // console.log("客户信息。。。", response)
    // if (response.data?.total > 50000) {
    //   const pages = Math.ceil(response.data?.total / 5000)
    //   const requestPromises: any[] = []
    //   for (let i = 1; i < pages + 1; i++) {
    //     let pageConfig = {
    //       params: {
    //         "pageSize": 5000,
    //         "page": i
    //       }
    //     }
    //     requestPromises.push(await axios.get(url, pageConfig))
    //   }
    //   const results = await Promise.all(requestPromises)
    //   return results
    // } else {
    //   return response
    // }
  } catch (e) {
    console.log("Something went wrong at customers!", e)
    // notification.error({
    //   message: "Something went wrong at customers!"
    // })
    return e
  }
}

export const getCustomerByCustomerCode = async (customerCode: string) => {
  try {
    const url = generateRequestUrl(API.queryCustomer)
    const config = {
      // params: {
      //   "pageSize": pageSize,
      //   "CustomerCode": customerCode
      // }
    }
    const response = await axios.get(url + customerCode, config)
    return response.data
  } catch (e) {
    console.log('getCustomerByCustomerCode ERROR::', e)
    // notification.error({
    //   message: `Customer ${customerCode} is not exist.`
    // })
    return null
  }
}

export const searchCustomerOnline = async (input: string, isScan?: boolean) => {
  if (input) {
    try {
      const url = generateRequestUrl(API.searchCustomer)
      let config: any
      if (isScan) {
        config = {
          params: {
            "pageSize": 20,
            "CustomerCode": input
          }
        }
      } else {
        config = {
          params: {
            "pageSize": 20,
            // "pageSize": largePageSize,
            "Any": input
          }
        }
      }
      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Something went wrong! when get customers"
      })
      return e
    }
  } else {
    console.log("客户查询条件为空")
    return { succcess: false, data: [] }
  }

}

export const createNewCustomer = async (customer: any) => {
  try {
    const url = generateRequestUrl(API.createCustomer)
    //const config = {}
    const response = await axios.post(url, customer)
    console.log("customer: ", response)
    return response.data
  } catch (e) {
    console.log(e)
    return e
  }
}
