import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CartItem, Total, Customer, InvoiceHead } from "../../models"
import { ExampleInvoiceInDB, ExampleInvoices, ExampleCustomers } from "../../constants/data"

export interface CustomerState {
  customers: Array<Customer>
  searchedCustomers: Array<Customer>
  searchedInvoices: Array<InvoiceHead>
  selectedCustomer: Customer | null
  servingCustomer: Customer | null
  invoicesByCustomer: Array<InvoiceHead>
  selectedInvoice: InvoiceHead | null
  allInvoices: Array<InvoiceHead>
  isKeyboardVisible: boolean
  keyboardLanguage: string
  editable: any
  nextToken: string | null
  totalCustomerNo: number
  searchedCustomerInvoices: Array<InvoiceHead>
  invoicePagination: any
}

const initialState = {
  customers: ExampleCustomers,
  searchedCustomers: [],
  searchedInvoices: [],
  selectedCustomer: null,
  servingCustomer: null,
  invoicesByCustomer: [],
  allInvoices: [],
  selectedInvoice: null,
  isKeyboardVisible: false,
  editable: null,
  nextToken: null,
  keyboardLanguage: "eng",
  totalCustomerNo: 0,
  searchedCustomerInvoices: [],
  invoicePagination: {}
} as CustomerState

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    loadCustomers(state, action: PayloadAction<any>) {
      // console.log("customers: ", action.payload.customers)
      state.customers = action.payload.customers
      state.nextToken = action.payload.nextToken
    },
    selectCustomer(state, action: PayloadAction<any>) {
      state.selectedCustomer = action.payload.customer
    },
    appendCustomer(state, action: PayloadAction<any>) {
      state.customers = [...state.customers, action.payload.customer]
    },
    loadAllInvoices(state, action: PayloadAction<any>) {
      state.allInvoices = action.payload.invoices
      if (action.payload.pagination) {
        state.invoicePagination = action.payload.pagination
      }
      if (action.payload.nextToken) {
        state.nextToken = action.payload.nextToken
      }
    },
    addInvoice(state, action: PayloadAction<any>) {
      // state.allInvoices.push(action.payload.invoice)
      state.allInvoices.unshift(action.payload.invoice)
    },
    loadInvoiceByCustomer(state, action: PayloadAction<any>) {
      const customerCode = action.payload.customerCode
      state.invoicesByCustomer = state.allInvoices.filter(item => {return item.CustomerCode === customerCode})
    },
    searchCustomerInvoices(state, action: PayloadAction<any>) {
      state.searchedCustomerInvoices = action.payload.invoices
    },
    selectInvoice(state, action: PayloadAction<any>) {
      state.selectedInvoice = action.payload.invoice
    },
    serveCustomer(state, action: PayloadAction<any>) {
      state.servingCustomer = action.payload.customer
    },
    searchCustomer(state, action: PayloadAction<any>) {
      state.searchedCustomers = action.payload.customers
    },
    updateCustomer(state, action: PayloadAction<any>) {
      const editItem = action.payload.customer
      state.customers = state.customers.map((item) =>
      item.CustomerCode === editItem.CustomerCode
        ? editItem
        : item
      )
    },
    searchInvoice(state, action: PayloadAction<any>) {
      state.searchedInvoices = action.payload.invoices
    },
    showKeyboard(state, action: PayloadAction<any>) {
      state.isKeyboardVisible = action.payload.isVisible
    },
    switchKeyboardLanguage(state, action: PayloadAction<any>) {
      if (state.keyboardLanguage === "eng") {
        state.keyboardLanguage = "kor"
      } else {
        state.keyboardLanguage = "eng"
      }
    },
    overWrite(state, action: PayloadAction<any>) {
      console.log("customer overwrite是", action.payload)
      state = action.payload
    }
  }
})

export const {
  loadCustomers,
  loadInvoiceByCustomer,
  selectCustomer,
  selectInvoice,
  serveCustomer,
  loadAllInvoices,
  addInvoice,
  showKeyboard,
  switchKeyboardLanguage,
  searchCustomer,
  searchInvoice,
  updateCustomer,
  appendCustomer,
  searchCustomerInvoices,
  overWrite
} = customerSlice.actions

export default customerSlice.reducer
