import axios from "axios"

axios.interceptors.request.use(function (config) {
  // Add authorization header
  // const token = store.getState().User.accessToken
  const token = localStorage.getItem("token")
  if (token) {
    if (!config.headers) config.headers = {}
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // �����������Щʲô
  return Promise.reject(error);
});

// ������Ӧ������
axios.interceptors.response.use(function (response) {
  if (response.headers["token"]) {
    localStorage.setItem("token", response.headers["token"])
  }
  return response
}, function (error) {
  return Promise.reject(error);
});