import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { User } from "../../models"

export interface UserState {
  isSignIn: boolean
  uid: string | null
  user: User | null
}

const initialState = {
  isSignIn: false,
  uid: null,
  user: null
} as UserState

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin(state, action: PayloadAction<{
      uid: string
      user: User
    }>) {
      // console.log("user: ", action.payload.user)
      state.isSignIn = true
      state.uid = action.payload.uid
      state.user = action.payload.user
    },
    userLogout(state, action: PayloadAction<void>) {
      state.isSignIn = false
      state.uid = null
      state.user = null
    },
  }
})

export const {
  userLogin,
  userLogout
} = userSlice.actions

export default userSlice.reducer
