import { notification } from "antd"
import axios from "axios"
import API from "../constants/API"
import { generateRequestUrl } from "../utils/Global"


export const getUserInfo = async (user: any) => {
  try {
    const url = generateRequestUrl(API.queryUserList) + `/${user.OpFirebaseUid}`
    // const url = generateRequestUrl(API.queryCurrentUser)
    // const config = {}
    console.log('显示用户信息获取url', url)
    const response = await axios.get(url)
    return response
  } catch (e) {
    console.log(e)
  }
}


export const loginUser = async (email:string, password:string, barcode?:string) => {
  try {
    const url = generateRequestUrl(API.loginUser)
    // console.log("send request")
    const response = await axios.post(url, {
      "Email": email,
      "Password": password,
      "Barcode": barcode
    }).then(response => {
      return {
        user: response.data,
        accessToken: response.headers.token
      }
    })
    return response
    // console.log("categories: ", response)
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
