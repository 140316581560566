import "../../css/Global.css"
import React, { useState, useEffect, useCallback } from "react"
import { Layout, Menu, Breadcrumb, List, Card, Drawer, Typography, Table, InputNumber, Image, notification, Button } from 'antd'
import { Product, Category, CartItem, CartItemType, ProductInDB, SpecialPrice, InvoiceItem, RefundInvoiceItem } from "../../models"
import { addItemToCart, updateTotal, showKeyboard, switchKeyboardLanguage, addItemToRefundCart, updateItemInRefundCart, addWholeToRefundCart } from "../../redux/Checkout/slice"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { calculateCartTotal, generateCartItemId, productInDBToProduct, nowIsWithinTimePeriod, calculateGSTAmount, generateImagePath } from "../../utils/Global"
import SearchBar from "../common/SearchBar"
import { showAllProducts, searchProduct, selectCategory, updateProduct, loadProductsByCategory } from "../../redux/Product/slice"
import KeyboardModal from "../common/KeyboardModal"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import { primary } from "../../constants/Colors"
import useViewport from "../../hooks/useViewport"
import { getSpecialPriceByProductCode, getProductByProductCode, searchProductsByName, getProductByBarCode } from "../../api/product"
import useSound from 'use-sound'
import ScrollButtons from "../common/ScrollButtons"

const successBeep = require('../../assets/sounds/normal.mp3')
const errorBeep = require('../../assets/sounds/error.mp3')
// const successBeep = require('../../assets/sounds/success_beep.mp3')
// const errorBeep = require('../../assets/sounds/error_beep.mp3')

const { Title } = Typography

let buffer = '' // buffer for constructing the barcode from key presses

export default function RefundProductSection() {
  const { SubMenu } = Menu
  const { Header, Content, Sider } = Layout
  const { width, height } = useViewport()

  const [playSuccess] = useSound(successBeep)
  const [playError] = useSound(errorBeep)

  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const Product = useSelector((state: RootStateOrAny) => state.Product)
  const Config = useSelector((state: RootStateOrAny) => state.Config)
  const { isKeyboardVisible, keyboardLanguage, isRefundMode, currentRefundInvoice, refundItems } = Checkout
  const { products, searchedProducts, productsByCategory, categories } = Product
  const [currentCatgory, setCurrentCategory] = useState<Category | null>(null)
  const [currentProduct, setCurrentProduct] = useState<Product>()
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [itemDisplayType, setItemDisplayType] = useState<"category" | "search" | "all">("all")
  const [currentInvoiceItems, setCurrentInvoiceItems] = useState<InvoiceItem[]>(currentRefundInvoice.InvoiceItems)
  const dispatch = useDispatch()
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [oldPage, setOldPage] = useState(1)

  const itemColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Image",
      dataIndex: ["Product", "ImageLoadPath"],
      key: "ID",
      render: (image: string, record: InvoiceItem) => (
        <Image src={generateImagePath(image)} width={60} />
      )
    },
    {
      title: "Description",
      dataIndex: ["Product", "Description"],
      key: "ID",
      render: (description: string, record: InvoiceItem) => (
        // @ts-ignore
        <Typography>{record?.Product?.KoreanName ? `${record?.Product?.KoreanName} ${description}` : description}</Typography>
      )
    },
    {
      title: "Order Qty",
      dataIndex: "Qty",
      key: "ID",
      render: (text: number, record: InvoiceItem) => (
        <Typography>{text}</Typography>
      )
    },
    {
      title: "Available Refund Qty",
      dataIndex: "RefundQty",
      key: "ID",
      render: (text: number, record: InvoiceItem) => (
        <Typography>
          {record.Qty - Math.abs(text) - getRefundCartItemCounts(record.ID) > 0 ? record.Qty - Math.abs(text) - getRefundCartItemCounts(record.ID) : 0}
        </Typography>
      )
    },
    {
      title: "UnitPrice",
      dataIndex: "Price",
      // dataIndex: "UnitCost",
      key: "ID",
      render: (text: any) => <Typography>${text.toFixed(2)}</Typography>
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "ID",
      render: (text: any) => <Typography>{text?.toFixed(0)}%</Typography>
    },
    {
      title: "GST",
      dataIndex: "GSTRate",
      key: "ID",
      render: (text: any) => <Typography>{text?.toFixed(0)}%</Typography>
    },
  ]

  const handleItemScan = (value: string) => {
    const item: InvoiceItem = currentRefundInvoice.InvoiceItems.filter((item: any) => item.Product.BarCode === value)[0]
    if (item) {
      handleItemAddToRefundCart(item)
    }
  }

  const scannerCallback = useCallback((event) => {
    let data = buffer || ''
    if (event.key === '') {
      return
    }
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ''
      if (isRefundMode) {
        handleItemScan(data)
      }
    }
  }, [isRefundMode, refundItems])

  useEffect(() => {
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [refundItems, isRefundMode])

  useEffect(() => {//实现move to 效果
    console.log('捕获', refundItems)
    if (refundItems.length === 0) {
      console.log('here 1----->')
      setCurrentInvoiceItems(currentRefundInvoice.InvoiceItems)
    } else {
      console.log('here 2----->')
      let newInvoiceItems: InvoiceItem[] = []
      currentRefundInvoice.InvoiceItems.forEach((item: InvoiceItem) => {
        const filterRefund: RefundInvoiceItem = refundItems.filter((refund: RefundInvoiceItem) => refund.ID === item.ID)[0]
        console.log(item, filterRefund)
        if (filterRefund && filterRefund.planRefundQty === item.Qty) {

        } else newInvoiceItems.push(item)
      })
      setCurrentInvoiceItems(newInvoiceItems)
    }

  }, [refundItems])

  const onSearchProduct = (value: string) => {
    const result: Array<Product> = products.filter((product: Product) => {
      return (product.productCode.toLowerCase().includes(value.toLowerCase()) || product.name.toLowerCase().includes(value.toLowerCase())) ? true : false
    })
    setCurrentCategory(null)
    setItemDisplayType("search")
    dispatch(searchProduct({ products: result }))
  }

  const onSearchProductOnline = async (value: string) => {
    const result = await searchProductsByName(value)
    console.log("products: ", result)
    setCurrentCategory(null)
    setItemDisplayType("search")
    const searchedResults = productInDBToProduct(result.data)
    dispatch(searchProduct({ products: searchedResults }))
  }

  const getRefundCartItemCounts = (invoiceItemId: number) => {
    let result = 0
    refundItems.forEach((item: RefundInvoiceItem) => {
      if (item.ID === invoiceItemId) {
        result += item.planRefundQty
      }
    })
    return result
  }

  const checkIfItemInRefundCart = (invoiceItemId: number) => {
    let result = false
    refundItems.forEach((item: RefundInvoiceItem) => {
      if (item.ID === invoiceItemId) {
        result = true
      }
    })
    return result
  }

  const getItemInRefundCart = (invoiceItemId: number) => {
    return refundItems.filter((item: RefundInvoiceItem) => item.ID === invoiceItemId)[0]
  }

  const handleItemAddToRefundCart = (item: InvoiceItem) => {
    let newItem: RefundInvoiceItem
    if (checkIfItemInRefundCart(item.ID)) {
      const oldItem: RefundInvoiceItem = getItemInRefundCart(item.ID)
      //如果是称重产品，则全部退货
      const newPrice = (oldItem.planRefundQty + (item.Product?.Scalable ? item.Qty : 1)) * item.Price * (1 - item.Discount / 100)
      // const newPrice = (oldItem.planRefundQty + (item.Product?.Scalable ? item.Qty : 1)) * item.UnitCost * (1 - item.Discount / 100)
      newItem = {
        ...oldItem,
        planRefundQty: oldItem.planRefundQty + (item.Product?.Scalable ? item.Qty : 1),
        // planRefundQty: oldItem.planRefundQty + 1,
        refundTotal: newPrice
      }
      dispatch(updateItemInRefundCart({ item: newItem }))
    } else {
      newItem = {
        ...item,
        planRefundQty: item.Product?.Scalable ? item.Qty : 1,
        // planRefundQty: 1,
        refundItemId: generateCartItemId(item.ProductCode),
        refundTotal: item.Price * (item.Product?.Scalable ? item.Qty : 1) * (1 - item.Discount / 100)
        // refundTotal: item.UnitCost * (item.Product?.Scalable ? item.Qty : 1) * (1 - item.Discount / 100)
      }
      dispatch(addItemToRefundCart({ item: newItem }))
    }
    // notification.success({ message: "Item Added to Refund Cart!" })
    playSuccess()
  }

  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[2]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[2]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[2]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.38
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.38
    }
    setOldPage(newPage)
  };

  useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);

  return (
    <>
      <KeyboardModal
        initialValue={searchValue}
        isVisible={isKeyboardVisible}
        language={keyboardLanguage}
        onHideKeyboard={() => {
          dispatch(showKeyboard({ isVisible: false }))
        }}
        onSwitchLanguage={() => {
          dispatch(switchKeyboardLanguage({}))
        }}
        onConfirm={(value: string) => {
          setSearchValue(value)
          dispatch(showKeyboard({ isVisible: false }))
          onSearchProductOnline(value)
          // onSearchProduct(value)
          // console.log("value: ", value)
        }}
        onSelect={(value) => {
          setSearchValue(value)
        }}
      />

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div className="pageTitle">Items In Current Refund Invoice</div>
        <SearchBar
          value={searchValue}
          onChange={(value: string) => { setSearchValue(value) }}
          onSearch={onSearchProductOnline}
          onClick={() => {
            dispatch(showKeyboard({ isVisible: true }))
          }}
          containerStyle={{ width: width * 0.2, marginBottom: 20 }}
          placeholderText={"Search By Product Code/Name"}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ height: 44, margin: "10px", borderRadius: "4px" }}
          onClick={() => {
            let newItems: RefundInvoiceItem[] = []
            currentRefundInvoice.InvoiceItems.forEach((item: InvoiceItem) => {
              if (item.Qty - item.RefundQty > 0) {
                const newItem = {
                  ...item,
                  planRefundQty: item.Qty - item.RefundQty,
                  refundItemId: generateCartItemId(item.ProductCode),
                  refundTotal: item.Price * (1 - item.Discount / 100)
                  // refundTotal: item.UnitCost * (1 - item.Discount / 100)
                }
                newItems.push(newItem)
              }
            })
            dispatch(addWholeToRefundCart({ items: newItems }))
          }}
        >
          Refund whole Order
        </Button>
      </div>
      <Table
        scroll={{
          y: height * 0.745,
          // scrollToFirstRowOnChange: true
        }}
        className="pageDetailTable pageDetailTableHeight900"
        rowClassName={(record, index) => { return index % 2 > 0 ? "rowStyle1" : "rowStyle2" }}

        columns={itemColumns}
        dataSource={currentInvoiceItems} //本地化处理添加删除发票items的显示效果
        pagination={false}
        style={{ minHeight: "700px" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              if (record.Qty > record.RefundQty) {
                console.log("退货数量", record)
                if (record?.Product?.Scalable) {//称重产品退货，直接退全部
                  // if (record?.Product?.Scalable) {
                  handleItemAddToRefundCart(record)
                } else {
                  if (getRefundCartItemCounts(record.ID) + 1 > record.Qty - Math.abs(record.RefundQty)) {
                    playError()
                    notification.warning({ message: "This Item Is Over Available Refund Qty!" })
                  } else {
                    handleItemAddToRefundCart(record)
                  }
                }

              } else {
                playError()
                notification.warning({ message: "This Item Is Not Eligible for Refund!" })
              }
            }
          }
        }}
      />
      <ScrollButtons
        onPageDown={onLastPage}
        onPageUp={onFirstPage}
        onRowDown={onPageDown}
        onRowUp={onPageUp}
      />
    </>
  )
}
