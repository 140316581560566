import React, { useState } from "react"
import { Input, Button, Modal, Typography } from "antd"
import useViewport from "../../hooks/useViewport"

const { Title } = Typography

interface Props {
  isVisible: boolean
  description: string
  buttonText: string
  onCancel: () => void
  onConfirm: () => void
  containerStyle?: React.CSSProperties
  buttonDanger?: boolean
  leftButtonDanger?: boolean
  rightButtonDanger?: boolean
}

const AlertModal = ({ isVisible, description, onConfirm, onCancel, containerStyle, buttonText, buttonDanger }: Props) => {
  const { width } = useViewport()

  return (
   <Modal
    // transitionName="none"
    // maskTransitionName="none"
    className="modalConfirm"
    centered
    width={width * 0.5}
    visible={isVisible}
    footer={null}
    onCancel={onCancel}
    closable={false}
   >
    <div style={containerStyle ? containerStyle : { margin: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", height: 200 }}>
    <div className="comment">{description}</div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 50 }}>
        {/* <Button 
          type="primary" 
          danger ={buttonDanger ? true : false}
          onClick={onConfirm}
          style={{ fontSize: 25, height: 80, width: 150 }}
        >
          {buttonText}
        </Button> */}
        <button
            className={"normalButton"}
            onClick={onConfirm}
          >
            {buttonText}
          </button>
      </div>
    </div>
   </Modal>
  )
}

export default AlertModal
