import { InvoiceHead, InvoiceItem, Order, CartItem } from "../models"
import { ExampleInvoiceInDB } from "../constants/data"
import API, { pageSize, largePageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

export const postRefund = async (newInvoice: InvoiceHead, callback?: (response: any) => void) => {
  try {
    const url = generateRequestUrl(API.postInvoice)
    //const config = {}
    axios.post(url, newInvoice)
    .then(response => {
      console.log("response: ", response)
      notification.success({
        message: "Refund created successfully!"
      })
      callback && callback(response)
    })
    .catch(error => {
      console.log("creating invoice error: ", error)
      notification.error({
        message: "Something went wrong!"
      })
    })
  } catch (e) {
    console.log(e)
  }
}

export const listRefunds = async ({WarehouseCode}:{WarehouseCode:string}) => {
  try {
    const url = generateRequestUrl(API.queryInvoiceFilter)
    const config = {
      params: {
        pageSize: pageSize,
        page: 1,
        WarehouseCode,
        hasNegetiveSales: "true"
        // isRefund: "true"
      }
    }
    const response = await axios.get(url, config)
    console.log('得到refund清单', response.data)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong at get refund list!"
    })
    return e
  }
}

export const listRefundsByInvoiceNo = async (invoiceNo: string) => {
  try {
    const url = generateRequestUrl(API.queryInvoiceFilter)
    const config = {
      params: {
        pageSize: pageSize,
        page: 1,
        isRefund: true,
        ReferenceNo: invoiceNo
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
