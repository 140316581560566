import React from "react"
export const viewportContext = React.createContext({ width: 0, height: 0 })

const ViewportProvider = ({ children }: any) => {
  
  // const [width, setWidth] = React.useState(window.innerHeight * 4/3)
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  const handleWindowResize = () => {
    // setWidth(window.innerHeight * 4/3);
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  
  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  )
}

export default ViewportProvider
