//@ts-ignore
const fs = window?.electronAPI?.require('fs')
const configKey = 'PosConfig'

// Delete the local storage to read from the file when first open the window
// Uncomment below to enable this feature
// localStorage.removeItem(configKey)

export function getConfiguration(callFrom?: string) {
      const configInLocalStorage = localStorage.getItem(configKey)
      if (configInLocalStorage && configInLocalStorage !== "undefined") {
        // console.log("localstorage.............", configInLocalStorage, "callFrom:::", callFrom)
        return JSON.parse(configInLocalStorage)
      }
      else return {
        "machineID": "POS2",
        "warehouseCode": "0002",
        "serverAddress": "http://13.211.140.62",
        "port": "4000"
      }
}
export function iniConfiguration() {
  console.log("初始化posconfig")
  //@ts-ignore
  if (window?.electronAPI) {
    //@ts-ignore
    window.electronAPI.getConfiguration(configKey, (response) => {
      // console.log("getConfiguration结果是。。。", response, JSON.parse(response))
      localStorage.setItem(configKey, response)
    })
  }
}

export function saveConfiguration(values: any) {
  const originValues:any = getConfiguration("configurate")
  // if (!fs) {
  //   console.log('Not in electron environment, save to local storage')
  // }
  const newValues = { ...originValues, ...values }
  console.log('new values is ---------->', JSON.stringify(newValues, null, 2))
  try {
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.setConfiguration(configKey, JSON.stringify(newValues), (response) => {
        console.log("response of setConfiguration...", response)
      })
    } 
    localStorage.setItem(configKey, JSON.stringify(newValues))
    console.log('Data successfully saved to store', configKey, JSON.stringify(newValues))
  } catch (error) {
    console.log('An error has occurred ', error)
  }

}