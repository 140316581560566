

interface ScrollButtonsProps {
    onPageUp: () => void
    onRowUp: () => void
    onRowDown: () => void
    onPageDown: () => void
  }

  export default function ScrollButtons ({ onPageUp, onRowUp, onRowDown, onPageDown }: ScrollButtonsProps)  {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", height: "60px", backgroundColor: "#DAE2EC", borderRadius: "0 0 10px 10px" }}>
        <button className="productPagination"
          onClick={() => {onPageUp()}}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/firstPage-icon.png"} className="pageIcon rotateRight" />
        </button>
        <button className="productPagination"
          onClick={() => {onRowUp()}}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/prePage-icon.png"} className="pageIcon rotateRight" />
        </button>
        <button className="productPagination"
          onClick={() => {onRowDown()}}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/afterPage-icon.png"} className="pageIcon rotateRight" />
        </button>
        <button className="productPagination"
          onClick={() => {onPageDown()}}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/lastPage-icon.png"} className="pageIcon rotateRight" />
        </button>
      </div>
    )
  }