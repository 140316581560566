import React, { useEffect, useState } from "react"
import VirtualGrid from "react-responsive-virtual-grid"
import ProductCard from "./ProductCard"
import { List, Image, Card, Button, Row, Col, Pagination, message } from "antd"
import useViewport from "../../hooks/useViewport"
import { Product } from "../../models"
import CheckoutProductCard from "./CheckoutProductCard"
import { DisplayDataType, PageOperationType } from "../../constants/Enums"
import { listCheckoutProductsByPage, searchProductsByDescriptionByPage, listProductsByCategoryByPage, listProductsByMostUsed, listProductsByMannuallyAdd } from "../../api/product"
import { loadCheckoutProductsByPage, loadCheckoutProductsPrePage, loadCheckoutProductsNextPage, loadNextPage, loadPrePage } from "../../redux/Product/slice"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import { smallPageSize } from "../../constants/API"
import { productInDBToProduct } from "../../utils/Global"

interface Props {
  data: Array<Product>
  dataType: DisplayDataType
  onViewDetail?: (item: Product) => void
  onAddItemToCart?: (item: Product, quantity: number) => void
  onCardPressed?: (item: Product) => void
  setItemDisplayType?: (value: DisplayDataType) => void
  clearCurrentCate: () => void
}

export default function ProductPageList({ data, onViewDetail, onAddItemToCart, onCardPressed, dataType, setItemDisplayType, clearCurrentCate }: Props) {
  const { width, height } = useViewport()
  const dispatch = useDispatch()
  // console.log("本页产品清单。。。", data)
  const [displayData, setDisplayData] = useState<any[]>([])
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const Product = useSelector((state: RootStateOrAny) => state.Product)
  const { checkoutProductTotal, checkoutProductCurrentPage, checkoutSeachedValue, checkoutSelectedCategory, productsNextPage, productsPrePage } = Product

  const getProducts = async (page:number, dataType: DisplayDataType) => {
    if (page === 0) {
      console.log("page===0", page, dataType)
      return {checkoutProducts: [], total: checkoutProductTotal}
    }
    let result
    switch (dataType) {
      case DisplayDataType.All:
        result = await listCheckoutProductsByPage(page)
        break
      case DisplayDataType.Category:
        console.log("大类翻页id", checkoutSelectedCategory.id, page)
        if (checkoutSelectedCategory.id === '9999') {//mannually add
          result = await listProductsByMannuallyAdd(page)
        } else if (checkoutSelectedCategory.id === '0') {//most searched
          result = await listProductsByMostUsed(page)
        }
         else {
          result = await listProductsByCategoryByPage(checkoutSelectedCategory.id, page)
        }
        break
      case DisplayDataType.Search:
        result = await searchProductsByDescriptionByPage(checkoutSeachedValue, page)
        break
    }
    const checkoutProducts = productInDBToProduct(result.data)
    return {checkoutProducts, total: result.total}
  }

  const onChangePage = async (type: PageOperationType) => {
    console.log("datatype是", dataType)
    let result, page, resultOther
    switch (type) {
      case PageOperationType.First:
        page = 1
        break
      case PageOperationType.Next:
        page = checkoutProductCurrentPage + 2//预查询后一页
        break
      case PageOperationType.Prev:
        page = checkoutProductCurrentPage - 2//预查询前一页
        break
      case PageOperationType.Last:
        page = Math.ceil(checkoutProductTotal / smallPageSize)
        break
    }
    console.log("第一次处理type", page, "total", checkoutProductTotal)
    // if (page < 0 || page > Math.ceil(checkoutProductTotal / smallPageSize)) {
    if (page < 0) {
      console.log("//已到上边界，无需预翻页", page)
      dispatch(loadPrePage({type: dataType}))
      dispatch(loadCheckoutProductsPrePage({ products: [] }))
      return
    } else if (page > Math.ceil(checkoutProductTotal / smallPageSize)) {
      console.log("//已到下边界，无需预翻页", page)
      dispatch(loadNextPage({type: dataType}))
      dispatch(loadCheckoutProductsNextPage({ products: [] }))
      return
    } else {
      //先显示翻页数据，再处理前后下一页
      switch (type) {
        case PageOperationType.First:
          dispatch(loadCheckoutProductsPrePage({ products: [] }))
          break
        case PageOperationType.Next:
          dispatch(loadNextPage({type: dataType}))
          break
        case PageOperationType.Prev:
          console.log("向上翻页", checkoutProductCurrentPage, productsPrePage, productsNextPage)
          dispatch(loadPrePage({type: dataType}))
          break
        case PageOperationType.Last:
          dispatch(loadCheckoutProductsNextPage({ products: [] }))
          break
      }
    }
    const {checkoutProducts, total} = await getProducts(page, dataType)
    console.log("//处理预查询数据", checkoutProducts, page)
    //处理预查询数据
    switch (type) {
      case PageOperationType.First:
        dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: page, total, type: dataType }))  
        const checkoutNext = await getProducts(page + 1, dataType)
        dispatch(loadCheckoutProductsNextPage({ products: checkoutNext.checkoutProducts }))
        break
      case PageOperationType.Next:
        dispatch(loadCheckoutProductsNextPage({ products: checkoutProducts }))
        break
      case PageOperationType.Prev:
        dispatch(loadCheckoutProductsPrePage({ products: checkoutProducts }))
        break
      case PageOperationType.Last:
        dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: page, total, type: dataType }))  
        const checkoutPre = await getProducts(page - 1, dataType)
        console.log("到底页面上一页", checkoutPre)
        dispatch(loadCheckoutProductsPrePage({ products: checkoutPre.checkoutProducts }))
        break
    }
    
  }

  const ChangePageButtons = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "60px", backgroundColor: "#DAE2EC", borderRadius: "0 0 10px 10px" }}>
        <button
          className="productPagination"
          style={{ width: "170px", height: "50px" }}
          onClick={() => {
            console.log("get all products start, clear selected category if exist....")
            clearCurrentCate()
            listCheckoutProductsByPage(1)
              .then(result => {
                const checkoutProducts = productInDBToProduct(result.data)
                console.log("all product list result is ", result, "转换后数据是。。", checkoutProducts)
                dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: 1, total: result.total, type: DisplayDataType.All }))
                setItemDisplayType && setItemDisplayType(DisplayDataType.All)
                listCheckoutProductsByPage(2).then(result => {
                  const checkoutProducts = productInDBToProduct(result.data)
                  dispatch(loadCheckoutProductsNextPage({ products: checkoutProducts }))
                })
              })
              .catch(e => message.error("Something wrong at get all products"))
          }}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/allProduct-icon.png"} className="pageIcon" />
          All Product List
        </button>
        <div>
          {checkoutProductCurrentPage}/{Math.ceil(checkoutProductTotal / smallPageSize)}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
          <button className="productPagination2"
            onClick={() => onChangePage(PageOperationType.First)}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/firstPage-icon.png"} className="pageIcon" />
          </button>
          <button className="productPagination2"
            onClick={() => onChangePage(PageOperationType.Prev)}
            disabled={checkoutProductCurrentPage === 1}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/prePage-icon.png"} className="pageIcon" />
          </button>
          <button className="productPagination2"
            onClick={() => onChangePage(PageOperationType.Next)}
            disabled={checkoutProductCurrentPage === Math.ceil(checkoutProductTotal / smallPageSize)}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/afterPage-icon.png"} className="pageIcon" />
          </button>
          <button className="productPagination2"
            onClick={() => onChangePage(PageOperationType.Last)}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/lastPage-icon.png"} className="pageIcon" />
          </button>
        </div>
      </div>
    )
  }
  useEffect(() => {
    if (data.length === 0) {
      console.log("get all products start....")
      listCheckoutProductsByPage(1)
        .then(result => {
          console.log("all product list result is ", result)
          const checkoutProducts = productInDBToProduct(result.data)
          dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: 1, total: result.total, type: DisplayDataType.All }))
          listCheckoutProductsByPage(2)
        .then(result => {
          console.log("all product list 第二页 is ", result)
          const checkoutProducts = productInDBToProduct(result.data)
          dispatch(loadCheckoutProductsNextPage({ products: checkoutProducts}))
        })
        })
        .catch(e => message.error("Something wrong at get all products"))
    }
  }, [])

  useEffect(() => {
    console.log("checkoutProductCurrentPage是", checkoutProductCurrentPage, productsPrePage, productsNextPage)
  }, [checkoutProductCurrentPage])

  return (
    <div className="productPageList">
      <List
        grid={{
          gutter: 4,
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        dataSource={data}
        style={{ height: "585px", padding: "5px" }}
        renderItem={item => (
          <List.Item
          // style={{backgroundColor: "red"}}
          >
            <CheckoutProductCard
              product={item}
              onCardPress={onCardPressed}
            />
          </List.Item>
        )}
      // footer={}
      />
      <ChangePageButtons />
    </div>
  )
}


