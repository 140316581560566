import React, { useEffect, useState } from "react"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { Form, Button, Input, Checkbox, Typography } from "antd"
import ProgressModal from "../common/ProgressModal"
import POSConfig from "../../constants/EFTPOS"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import { setPaired } from "../../redux/Config/slice"
import useViewport from "../../hooks/useViewport"

const { TextArea } = Input

const PairPOSTab = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const Config= useSelector((state: RootStateOrAny) => state.Config)
  const { paired, linklyAcc } = Config
  const { width } = useViewport()

  const [isInProgress, setIsInProgress] = useState(false)
  const [lastSessionId, setLastSessionId] = useState("")
  const [token, setToken] = useState("")
  const [secret, setSecret] = useState("")
  const [txnResponse, setTxnResponse] = useState<any>("response will be shown here")

  useEffect(() => {
    form.setFieldsValue({
      username: linklyAcc.username,
      password: linklyAcc.password
    })
  }, [])

  const sendPairRequest = (values: any) => {
    setIsInProgress(true)
    dispatch(setPaired({ paired: false }));
    const username = values.username
    const password = values.password
    const pairCode = values.paircode
    axios
      .post(
        POSConfig.pairingPath,
        {
          username,
          password,
          pairCode,
        },
        {
          headers: POSConfig.headers
        }
      )
      .then((response) => {
        console.log("SETTING SECRET", response.data.secret);
        setSecret(response.data.secret);
        setTxnResponse(JSON.stringify(response))
        setIsInProgress(false);
      })
      .catch((error) => {
        console.log("error :>> ", error);
        setTxnResponse(JSON.stringify(error))
        setIsInProgress(false);
      });
  };
 
  // const sendRequest = (request: any, type: string) => {
  //   // Add a request interceptor
  //   axios.interceptors.request.use(
  //     (config: any) => {
  //       config.headers["Authorization"] = "Bearer " + token;

  //       return config;
  //     },
  //     (error) => {
  //       Promise.reject(error);
  //     }
  //   );

  //   let tempSessionId = uuidv4()

  //   let requestType;
  //   switch (type) {
  //     case POSConfig.TYPES.TXN:
  //       requestType = "/transaction?async=false"
  //       break
  //     case POSConfig.TYPES.REPRINT_RECEIPT:
  //       requestType = "/reprintreceipt?async=false"
  //       break
  //     case POSConfig.TYPES.TXN_STATUS:
  //       requestType = "/transaction?async=false"
  //       tempSessionId = lastSessionId
  //       break
  //     case POSConfig.TYPES.SETTLEMENT:
  //       requestType = "/settlement?async=false"
  //       break;
  //     case POSConfig.TYPES.CANCEL:
  //       requestType = "/sendkey?async=false"
  //       tempSessionId = lastSessionId
  //   }

  //   const uri = POSConfig.linklyEndpoint + tempSessionId + requestType;
  //   console.log("request :", request);

  //   setIsInProgress(true)

  //   if (type === POSConfig.TYPES.TXN_STATUS) {
  //     return axios
  //       .get(uri)
  //       .then((response) => {
  //         console.log("response :", response);
  //         setIsInProgress(false);
  //         const selectedData = {
  //           Status: response.status,
  //           Message: response.statusText,
  //         };
  //         setTxnResponse(selectedData);
  //       })
  //       .catch((error) => {
  //         console.log("ERROR", error);
  //         setIsInProgress(false);
  //         setTxnResponse(error);
  //       });
  //   }

  //   setLastSessionId(tempSessionId);

  //   return axios
  //     .post(
  //       uri,
  //       { request } //, txnHeaders
  //     )
  //     .then((response) => {
  //       console.log("response :", response);
  //       setIsInProgress(false);
  //       setTxnResponse(response.data.response);
  //       dispatch(setPaired({ paired: true }))
  //     })
  //     .catch((error) => {
  //       console.log("ERROR", error);
  //       setIsInProgress(false);
  //       setTxnResponse(error);
  //       dispatch(setPaired({ paired: false }))
  //     });
  // };

  const onFinish = async (values: any) => {

    console.log('Success:', values)
    sendPairRequest(values)
    // dispatch(staffSignIn({ staffCode: values.username, password: values.password }))
    // setVisibility(false)
    // navigate("/checkout")
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "column", width: width * 0.4, alignItems: "flex-start" }}>
        <Typography style={{ marginBottom: 20, color: "grey" }}>Enter Linkly Cloud Details for this POS</Typography>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Enter username for this POS" }]}
          >
            <Input 
              // defaultValue={linklyAcc.username} 
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Enter Password for this POS" }]}
          >
            <Input.Password 
              // defaultValue={linklyAcc.password} 
            />
          </Form.Item>

          <Form.Item
            label="Pair Code"
            name="paircode"
            rules={[{ required: true, message: "Enter pair code from terminal screen" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
              Pair
            </Button>
            <Button type="primary" htmlType="submit" danger>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ width: width * 0.4, marginLeft: 50 }}>
        <Typography style={{ marginBottom: 20, color: "grey" }}>Response</Typography>
        <TextArea value={txnResponse} rows={20} />
      </div>
      <ProgressModal 
        isVisible={isInProgress} 
        // title={""} 
        description={"Pairing In Progress"}
        onCancel={() => setIsInProgress(false)}   
      />
    </div>
  )
}

export default PairPOSTab
