import React from "react"
import { Input, Space } from "antd"
import "../../css/Global.css"
const { Search } = Input

interface SearchBarProps {
  value: string
  onChange: (value: string) => void
  onSearch: (value: string) => void
  onClick?: () => void
  containerStyle?: React.CSSProperties
  placeholderText?: string
  searchBarStyle?: React.CSSProperties
}

const SearchBar = ({ value, onChange, onSearch, onClick, containerStyle, placeholderText, searchBarStyle }: SearchBarProps) => {
  return (
    <div>
    {/* <div style={containerStyle ? containerStyle : { marginBottom: 20 }}> */}
      <Search 
        className="pageSearchBar"
        value={value}
        placeholder={placeholderText ? placeholderText : "Start Search..."}
        onChange={(e: any) => {
          onChange(e.target.value)
        }}
        onSearch={onSearch} 
        onClick={onClick && onClick}
        // enterButton 
        size="large"
        // style={searchBarStyle ? searchBarStyle : { fontSize: 30 }}
      />
    </div>
  )
}

export default SearchBar
