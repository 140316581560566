import 'react-tiny-fab/dist/styles.css'
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form } from "antd"
import { CartItem, StyleSheet, Total, Order } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import useViewport from "../../hooks/useViewport"

interface TotalSectionProps {
  item: Total
  containerStyle?: React.CSSProperties
}

const RefundTotal = ({ item, containerStyle }: TotalSectionProps) => {
  const { width } = useViewport()

  return (
    <div style={containerStyle ? containerStyle : 
      { 
        backgroundColor: "white", 
        flexDirection: "column", 
        display: "flex", 
        marginTop: "188px", 
        // marginBottom: "60px" 
      }}
      >
      <div 
        className="detailDescriptionsSmall" style={{ backgroundColor: "#FFEEEE" }}
        >
        <div 
          className="detailDescLabelSmall" style={{ fontSize: "20px", color: "#FC2C2C", width: "200px" }}
          >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Refund Total:
        </div>
        <div className="detailDescValueSmall" style={{ fontSize: "20px", fontWeight: "bold", color: "#FC2C2C", width: "100px" }}>
          {"-$" + Math.abs(item.totalAmount)?.toFixed(2)}
        </div>
      </div>
      {/* <List.Item style={{ padding: 0, marginBottom: 8 }}>
        <div style={styles.total}>
          <Typography style={{...styles.totalDescription, width: width * 0.14, fontSize: width > 1500 ? "18px" : "16px", color: "red" }}>Refund Total:</Typography>
          <Typography style={{...styles.totalContent, color: "red", fontSize: width > 1500 ? "18px" : "16px" }}>
            {
              "-$" + item?.totalAmount?.toFixed(2)
            }
          </Typography>
        </div>
      </List.Item> */}
      <div className="detailDescriptionsSmall">
        <div className="detailDescLabelSmall" style={{width: "200px"}}>
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />GST Included
        </div>
        <div className="detailDescValueSmall" style={{ fontSize: "18px", fontWeight: "bold", width: "100px" }}>
          {
            "-$" + item?.gstAmount?.toFixed(2)
          }
        </div>
      </div>
      {/* <List.Item style={{ padding: 0, marginBottom: 8 }}>
        <div style={styles.total}>
          <Typography style={{...styles.totalDescription, width: width * 0.14, fontSize: width > 1500 ? "18px" : "16px" }}>GST Included:</Typography>
          <Typography style={{...styles.totalContent, fontSize: width > 1500 ? "18px" : "16px" }}>
            {
              "-$" + item?.gstAmount?.toFixed(2)
            }
          </Typography>
        </div>
      </List.Item> */}
    </div>
  )
}

export default RefundTotal

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  totalContainer: {
    right: 10,
    position: "absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row",
    // marginLeft: 10,
    width: 300
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20,
    fontSize: 22,
    width: 150,
    alignSelf: "center"
  },
  totalContent: {
    fontWeight: "bold",
    // marginRight: 20 ,
    fontSize: 30,
    alignSelf: "center"
  },
  button: {
    marginBottom: 20,
    width: 140,
    height: 140
  }
}