import axios from "axios"
import { randomString, hashString } from "./Global"
import { getConfiguration } from "./configurate"

const PosConfig = getConfiguration("royalpay-functions")
const { machineID: MachineID, warehouseCode: WarehouseCode, serverAddress: ServerAddress, port: Port } = PosConfig

const partnerCode = "KTMT"
const credentialCode = "1ddumZR5keMKDjjc0H5NbSvG42kY0zJ2"
// const partnerCode = "AIBU"
// const credentialCode = "UjtTSl5yMgnbCyvHIe1dOt8LdmLsTXxw"
const requestUrl = "https://mpay.royalpay.com.au/api/v1.0"

const queryParams = async () => {
  const time = Date.now()
  const nonceStr = randomString(15)
  const validStr = partnerCode + "&" + time + "&" + nonceStr + "&" + credentialCode
  const sign = await hashString(validStr)
  return "time=" + time + "&nonce_str=" + nonceStr + "&sign=" + sign
}

export const royalpayCreateOrder = async (params: any) => {
  const path = "/micropay/partners/" + partnerCode + "/orders/" + params.partnerOrderId
  const authParams = await queryParams()
  const result = await axios
  .put(
    requestUrl + path + "?" + authParams,
    {
      "description": params.description, // Required
      "price": params.price, // Required, Need to be based on cent, say $1.00 should be 100
      "currency": "AUD", // Required, "AUD" or "CNY"
      // "notify_url": "string",
      // "operator": "string",
      // "expire": "5m",
      // "extra": {
      //   "pay_type": "string"
      // },
      "device_id": MachineID || "POS01", // Reuqired, ID of the device which sends the request
      "auth_code": params.authCode // Required, The Payment QR Code scanned from customer's WeChat Wallet
    }
  )
  return result

  // Example Response
  // {
  //   "return_code": "SUCCESS",
  //   "result_code": "PAYING",
  //   "order_id": "string",
  //   "partner_order_id": "string",
  //   "channel_order_id": "string",
  //   "total_fee": 0,
  //   "real_fee": 0,
  //   "currency": "string",
  //   "rate": 0,
  //   "pay_time": "2019-08-24T14:15:22Z",
  //   "create_time": "2019-08-24T14:15:22Z",
  //   "settlement_date": "2019-08-24",
  //   "channel": "string",
  //   "customer_info": {
  //     "alipay_user_id": "string",
  //     "alipay_account": "string"
  //   }
  // }
}

export const royalpayCreateQRCodeOrder = async (params: any) => {
  const path = "/retail_qrcode/partners/" + partnerCode + "/orders/" + params.partnerOrderId
  const authParams = await queryParams()
  const result = await axios
  .put(
    requestUrl + path + "?" + authParams,
    {
      "description": params.description, // Required
      "price": params.price, // Required, Need to be based on cent, say $1.00 should be 100
      "currency": "AUD", // Required, "AUD" or "CNY"
      // "notify_url": "string",
      // "operator": "string",
      // "expire": "5m",
      // "extra": {
      //   "pay_type": "string"
      // },
      "device_id": MachineID || "POS01", // Reuqired, ID of the device which sends the request
    },
    // {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Header": "*"
    //   }
    // }
  )
  console.log("result: ", result)
  return result

  // Example Response
  // {
  //   "return_code": "string",
  //   "result_code": "string",
  //   "channel": "string",
  //   "partner_code": "string",
  //   "full_name": "string",
  //   "partner_name": "string",
  //   "order_id": "string",
  //   "partner_order_id": "string",
  //   "code_url": "string"
  // }
}

export const royalpayRevokeOrder = async (params: any) => {
  const path = "/gateway/partners/" + partnerCode + "/orders/" + params.partnerOrderId + "/revoke"
  const authParams = await queryParams()
  const result = await axios
  .put(
    requestUrl + path + "?" + authParams
  )
  return result

  // Example Response
  // {
  //   "return_code": "SUCCESS",
  //   "result_code": "PAYING",
  //   "order_id": "string",
  //   "partner_order_id": "string",
  //   "channel_order_id": "string",
  //   "total_fee": 0,
  //   "real_fee": 0,
  //   "currency": "string",
  //   "rate": 0,
  //   "pay_time": "2019-08-24T14:15:22Z",
  //   "create_time": "2019-08-24T14:15:22Z",
  //   "settlement_date": "2019-08-24",
  //   "channel": "string",
  //   "customer_info": {
  //     "alipay_user_id": "string",
  //     "alipay_account": "string"
  //   }
  // }
}

export const royalpayCheckOrderStatus = async (params: any) => {
  const path = "/gateway/partners/" + partnerCode + "/orders/" + params.partnerOrderId
  const authParams = await queryParams()
  const result = await axios
  .get(
    requestUrl + path + "?" + authParams
  )
  return result

  // Example Response
  // {
  //   "return_code": "SUCCESS",
  //   "result_code": "PAYING",
  //   "order_id": "string",
  //   "partner_order_id": "string",
  //   "channel_order_id": "string",
  //   "total_fee": 0,
  //   "real_fee": 0,
  //   "currency": "string",
  //   "rate": 0,
  //   "pay_time": "2019-08-24T14:15:22Z",
  //   "create_time": "2019-08-24T14:15:22Z",
  //   "settlement_date": "2019-08-24",
  //   "channel": "string",
  //   "customer_info": {
  //     "alipay_user_id": "string",
  //     "alipay_account": "string"
  //   }
  // }
}

export const royalpayRefundOrder = async (params: any) => {
  const path = "/gateway/partners/" + partnerCode + "/orders/" + params.partnerOrderId + "/refunds/" + params.partnerRefundId
  const authParams = await queryParams()
  console.log("royalpayRefundOrder的url是", requestUrl + path + "?" + authParams,)
  const result = await axios
  .put(
    requestUrl + path + "?" + authParams,
    {
     "fee": params.fee, // Required, refund amount
    //  "device_id": params.deviceId
    }
  )
  return result

  // Example Response
  // {
  //   "return_code": "SUCCESS",
  //   "result_code": "WATING",
  //   "refund_id": "string",
  //   "partner_refund_id": "string",
  //   "amount": 0,
  //   "currency": "AUD",
  //   "settlement_date": "2019-08-24"
  // }
}

export const checkRefundStatus = async (params: any) => {
  const path = "/gateway/partners/" + partnerCode + "/orders/" + params.partnerOrderId + "/refunds/" + params.partnerRefundId
  const authParams = await queryParams()

  const result = await axios
  .get(
    requestUrl + path + "?" + authParams
  )
  return result

  // Example Response
  // {
  //   "return_code": "SUCCESS",
  //   "result_code": "WATING",
  //   "refund_id": "string",
  //   "partner_refund_id": "string",
  //   "amount": 0,
  //   "currency": "AUD",
  //   "settlement_date": "2019-08-24"
  // }
}

export const royalpayCheckExchangeRate = async () => {
  const path = "/gateway/partners/" + partnerCode + "/channel_exchange_rate"
  const authParams = await queryParams()
  
  const result = await axios
  .get(
    requestUrl + path + "?" + authParams
  )
  console.log("royalpay汇率查询路径",requestUrl + path + "?" + authParams, result)
  return result

  // Example Response
  // {
  //   "return_code": "SUCCESS",
  //   "wechat_rate": 4.41111,
  //   "alipay_retail_rate": 4.411111,
  //   "alipay_online_rate": 4.411111
  // }
}
