import API, { pageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"
import { getConfiguration } from "../utils/configurate"

const PosConfig = getConfiguration("drawOpen")
const { machineID } = PosConfig

export const PostDrawOpen = async (payload: any) => {
  try {
    const url = generateRequestUrl(API.addDrawOpenRecord)
    const newPayload = {
      ...payload,
      MachineID: machineID,
      // OpFirebaseUid: uid // this will be filled by backend with info given in token
     }
    const response = await axios.post(url, newPayload)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong at addDrawOpenRecord!"
    })
    return e
  }
}
