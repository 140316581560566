import React, { useState, useEffect, useRef, useCallback } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, Typography, Button, Tag, message } from 'antd';
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import { selectCustomer, selectInvoice, loadInvoiceByCustomer, showKeyboard, switchKeyboardLanguage, searchCustomer, searchInvoice, serveCustomer } from "../../redux/Customer/slice"
import { StyleSheet } from "../../models"
import moment from "moment"
import { dateFormat } from "../../constants/data"
import { checkIfInvoiceContainsRefundItem } from '../../utils/Global';
import { getInvoiceByInvoiceNo } from "../../api/invoice"
import "../../css/Global.css"
import { GlobalState, freshBarcode, freshWeight } from "../../redux/Globals/slice"

type TableProps = Parameters<typeof Table>[0] & {
  onViewDetail: (item: any) => void,
  onSearchInvoice: (item: any) => void,
  setSearchValue: (value: string) => void
  newPage: number
}
interface RowProps {
  customerCode: string
}

let buffer = '' // buffer for constructing the barcode from key presses
let isScanable: boolean = false

export default function InvoiceVirtualTable(props: TableProps) {
  const { columns, scroll, onViewDetail, onSearchInvoice, setSearchValue, newPage, ref } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [oldPage, setOldPage] = useState(1);
  const dispatch = useDispatch()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { customers } = Customer
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)
  const widthColumnCount = columns!.filter(({ width }) => !width).length;
  const [curSearch, setCurSearch] = useState("")
  const mergedColumns = columns!.map(column => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / widthColumnCount),
    };
  });

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += 624
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= 624
    }
    setOldPage(newPage)
  };


  useEffect(() => resetVirtualGrid, [tableWidth]);

  // useEffect(() => onPageUpDown(newPage), [newPage]);


  //begin of 处理扫描invoiceNo事件
  const handleBarcodeScan = async (data: string) => {
    if (isScanable) {
      console.log('handleBarcodeScan:', data)
      setSearchValue && setSearchValue(data)
      onSearchInvoice(data)
      const result = await getInvoiceByInvoiceNo(data)
      if (result) {
        onViewDetail(result)
      } else message.error(`Invoice ${data} is not exist.`)
    } else {
      console.log("invoice不执行串口监听2")
    }
  }
  const scannerCallback = useCallback((event) => {
    let data = buffer || ''
    if (event.key === '') {
      return
    }
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ''
      handleBarcodeScan(data)
    }
  }, [])

  useEffect(() => {
    isScanable = true
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      isScanable = false
      console.log("customerInvoice卸载")
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [])

  useEffect(() => {
    if (Globals.barcode) {
      handleBarcodeScan(Globals.barcode)
      dispatch(freshBarcode({
        barcode: ""
      }))
    }
  }, [Globals.barcode])

  //end of 处理扫描invoiceNo事件
  return (
    <Table
      ref={ref}
      {...props}
      className="pageDetailTable pageDetailTableHeight900"
      // className="pageDetailTable"
      columns={mergedColumns}
      pagination={false}
      rowClassName={(_, index) => {
        if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
      }}
      scroll={scroll}
    />
  );
}

const styles: StyleSheet = {
  row: {
    //@ts-ignore
    "&:hover": {
      background: "#efefef"
    },
  }
}
