import React, { useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Tooltip } from "antd"
import { InfoCircleOutlined, UserOutlined, HomeOutlined, MobileOutlined } from "@ant-design/icons"
import { RootStateOrAny, useSelector } from "react-redux"

export default function CustomerInfoSection() {
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer
  return (
    <div>
      <Typography style={{ fontWeight: "bold", marginBottom: 10, fontSize: 22, marginLeft: 20 }}>
        {servingCustomer ?
          "Customer: " + servingCustomer.name + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "Points: " + servingCustomer.Points + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "Mobile: " + servingCustomer.Mobile
          :
          "Customer: Cash Customer"
        }
      </Typography>
    </div>
  )
}
