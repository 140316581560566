import { Product } from "../models";

export function getProductDisplayName(item: Product): string {
  const sectionsToShow: string[] = []

  if (item?.nameKorean) {
    sectionsToShow.push(item?.nameKorean)
  }

  // By default, English Name (Description column) is always available
  sectionsToShow.push(item.name)

  if (!item?.nameKorean) {
    if (item.nameChinese) {
      sectionsToShow.push(item.nameChinese)
    } else if (item.nameJapanese) {
      sectionsToShow.push(item.nameJapanese)
    }
  }

  return sectionsToShow.join(' ')
}