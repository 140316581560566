import {
  PageHeader,
  Spin,
  Typography,
  Table,
  message
} from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Product, Order } from "../../models"
import { removeHoldingOrder } from "../../redux/Order/slice"
import { revokeOrder } from "../../redux/Checkout/slice"
import { calculateCartTotal, orderToCustomerOrderHead } from "../../utils/Global"
import { useNavigate } from "react-router-dom"
import useViewport from "../../hooks/useViewport"
import { dateFormat } from "../../constants/data"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { postHoldingOrder } from "../../api/order"
import ScrollButtons from "../common/ScrollButtons"
import { User } from "../../models"

type Props = {
  order: Order
  clearCurrentOrder: () => void
  user?: User | null
}

export default function OrderDetail({ order, clearCurrentOrder, user }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmMsg, setConfirmMsg] = useState("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmType, setConfirmType] = useState<"revoke" | "remove">("revoke")
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [oldPage, setOldPage] = useState(1);

  const deleteHoldingOrder = async (order: Order) => {
    const newOrderInDB = orderToCustomerOrderHead(order, "remove")
    await postHoldingOrder(newOrderInDB, "remove")
  }

  const onConfirmRevoke = async () => {
    const total = calculateCartTotal(order.cartItems)
    dispatch(revokeOrder(
      {
        itemsInCart: order.cartItems,
        total: total,
        orderNo: order.customerOrderNo,
        createdAt: order.createdAt,
        machineID: order.machineId
      }))
    //revoke后删除当前holding order
    await deleteHoldingOrder(order)
    dispatch(removeHoldingOrder({ orderId: order.id }))
    setIsConfirmModalVisible(false)
    navigate("/checkout")
  }

  const onConfirmRemove = async () => {
    await deleteHoldingOrder(order)
    dispatch(removeHoldingOrder({ orderId: order.id }))
    setIsConfirmModalVisible(false)
    clearCurrentOrder()
  }

  const itemColumns = [
    {
      title: "No",
      dataIndex: "itemId",
      key: "id",
      width: "7%",
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Promotion?",
      dataIndex: "type",
      width: "10%",
      render: (text: any) => <Typography>{text === "promotion" ? "Yes" : "No"}</Typography>
    },
    {
      title: "Description",
      dataIndex: ["productInfo", "name"],
      width: "46%",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      width: "10%",
      render: (text: any) => <Typography style={{ textAlign: "right" }}>{text}</Typography>
    },
    {
      title: "Price",
      dataIndex: "unitPrice",
      width: "10%",
      render: (text: any) => <Typography style={{ textAlign: "right" }}>${text.toFixed(2)}</Typography>
    },
    {
      title: "Amount",
      dataIndex: "total",
      width: "10%",
      render: (text: any) => <Typography style={{ textAlign: "right" }}>${text.toFixed(2)}</Typography>
    },
    {
      title: "Discount",
      dataIndex: "discountRatio",
      width: "6%",
      render: (text: any) => <Typography style={{ textAlign: "right" }}>{text.toFixed(0)}%</Typography>
    },
    {
      title: "GST",
      dataIndex: "gstRatio",
      width: "10%",
      render: (text: any) => <Typography style={{ textAlign: "right" }}>{text.toFixed(0)}%</Typography>
    },
  ]

  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.52
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.52
    }
    setOldPage(newPage)
  };

  useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);

  return (
    <div style={{ overflow: "auto", height: "100%" }}>
      <ActionConfirmModal
        isVisible={isConfirmModalVisible}
        description={confirmMsg}
        actionText={"Yes"}
        cancelText={"No"}
        onConfirm={confirmType === "revoke" ? onConfirmRevoke : onConfirmRemove}
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }}
        rightButtonDanger={confirmType === "revoke" ? false : true}
      />
      <PageHeader
        className="detailsPageHeader"
        ghost={false}
        backIcon={false}
        title={
          <div className="detailNo"><img src={process.env.PUBLIC_URL + "/images/assets/icons/order-icon.png"} className="pageIcon" /> Order</div>
        }
        extra={[
          <div style={{ display: "flex" }}>
            <button
              className="scanToRefundButton"
              style={{ width: "80px", height: "50px" }}
              key="scan"
              onClick={() => {
                if (user && user.OrderProcessStatusMenu === 1) {
                  setConfirmMsg("Are you sure to delete this holding order?")
                  setConfirmType("remove")
                  setIsConfirmModalVisible(true)
                } else {
                  message.error("No permission")
                }

                // setConfirmMsg("Are you sure to delete this holding order?")
                // setConfirmType("remove")
                // setIsConfirmModalVisible(true)
              }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/delete-icon.png"} className="pageIcon" />
              Delete
            </button>

            <button
              key="print"
              className="printDetailsButton"
              style={{ width: "80px", height: "50px" }}
              onClick={() => {
                setConfirmMsg("Are you sure to revoke this holding order?")
                setConfirmType("revoke")
                setIsConfirmModalVisible(true)
              }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/cancel-icon.png"} className="pageIcon" />
              Revoke
            </button>
          </div>,

        ]}
      >
        <Spin tip="Loading..." spinning={isLoading}>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Created Date
            </div>
            <div className="detailDescValue">
              {moment(order.createdAt).format(dateFormat)}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Updated Date
            </div>
            <div className="detailDescValue">
              {moment(order.updatedAt).format(dateFormat)}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Staff
            </div>
            <div className="detailDescValue">
              {order.staffName}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Machine
            </div>
            <div className="detailDescValue">
              {order.machineId}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/customer-icon@2x.png"} className="pageIcon16" />Customer
            </div>
            <div className="detailDescValue">
              {order.customerName}
            </div>
          </div>
          <div className="detailDescriptions">
            <div className="detailDescLabel">
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Total
            </div>
            <div className="detailDescValue">
              ${order.totalAmount?.toFixed(2)}
            </div>
          </div>
          <Table
            rowClassName={(_, index) => {
              if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
            }}
            scroll={{
              y: height * 0.45,
              // scrollToFirstRowOnChange: true
            }}
            className="pageDetailTable"
            columns={itemColumns}
            dataSource={order.cartItems}
            pagination={false}
          />
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {/* <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}> */}
            <ScrollButtons
              onPageDown={onLastPage}
              onPageUp={onFirstPage}
              onRowDown={onPageDown}
              onRowUp={onPageUp}
            />
          </div>
        </Spin>
      </PageHeader>
    </div>
  )
}
