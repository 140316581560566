import React, { useState, useEffect } from "react"
import { Form, Button, Input, Typography } from "antd"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { StyleSheet } from "../../models"
import { retrieveLinklyData, storeLinklyData } from "../../utils/EFTPOS-functions"
import ProgressModal from "../common/ProgressModal"
import POSConfig from "../../constants/EFTPOS"
// import "./TestPOS.css"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import { setPaired, setToken, setSecret } from "../../redux/Config/slice"
import useViewport from "../../hooks/useViewport"
import { doPurchase } from "../../api/payment"

const PAIRING_MENU = "PAIRING_MENU";
const POS_SALE_UI = "POS_SALE_UI";
const TXN = "TXN";
const REPRINT_RECEIPT = "REPRINT_RECEIPT";
const TXN_STATUS = "TXN_STATUS";
const SETTLEMENT = "SETTLEMENT";
const CANCEL = "CANCEL";

const pairingPath =
  "https://auth.sandbox.cloud.pceftpos.com/v1/pairing/cloudpos";
const tokenRequestPath =
  "https://auth.sandbox.cloud.pceftpos.com/v1/tokens/cloudpos";
const linklyEndpoint =
  "https://rest.pos.sandbox.cloud.pceftpos.com/v1/sessions/";
const posName = "React REST POS";
const posVersion = "1.0.0";
const posId = "f818d27b-c98c-4007-97f5-6eb173bb7d9b";
const posVendorId = "482c12c7-4506-482e-a05a-761c113c9a40";

const TransactionTab = () => {
  const dispatch = useDispatch()
  const Config = useSelector((state: RootStateOrAny) => state.Config)
  const { paired, token, tokenExpiry, secret } = Config
  const { width } = useViewport()
  const [form] = Form.useForm()

  const [displayPage, setDisplayPage] = useState(PAIRING_MENU)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [pairCode, setPairCode] = useState("")
  // const [secret, setSecret] = useState("")
  // const [token, setToken] = useState("")
  // const [tokenExpiry, setTokenExpiry] = useState<number | undefined>()
  // const [paired, setPaired] = useState(false)
  const [txnResponse, setTxnResponse] = useState<any>()
  const [lastSessionId, setLastSessionId] = useState("")
  const [amtPurchase, setAmtPurchase] = useState(0)
  const [amtCash, setAmtCash] = useState(0)
  // const [txnInProgress, setTxnInProgress] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  // console.log("paired :>> ", paired);
  // useEffect(() => {
  //   form.setFieldsValue({
  //     username: linklyAcc.username,
  //     password: linklyAcc.password
  //   })
  // }, [])

  useEffect(() => {
    // console.log("saved token: ", retrieveLinklyData("token"))
    // console.log("saved expiry: ", retrieveLinklyData("token-expiry"))
    // setSecret(retrieveLinklyData("secret"))
    // dispatch(setToken({ token: retrieveLinklyData("token"), tokenExpiry: retrieveLinklyData("token-expiry") }))
    if (token && tokenExpiry && tokenExpiry > Date.now()) {
      // Do nothing
    } else {
      getToken()
    }
    form.setFieldsValue({
      purchase: 0,
      cash: 0
    })
  }, []);

  useEffect(() => {
    console.log("DETECTING PAIRED");
    if (paired) setDisplayPage(POS_SALE_UI);
  }, [paired]);

  useEffect(() => {
    console.log("SECRET NOTED BY EFFECT");
    if (secret) {
      console.log("SECRET NOT NULL... STORING...");
      storeLinklyData("secret", secret);
      if (!token || (tokenExpiry && tokenExpiry <= Date.now()) || !paired) {
        console.log("GETTING TOKEN");
        getToken();
      } else {
        console.log("SETTING PAIRED");
        dispatch(setPaired({ paired: true }));
      }
    }
  }, [secret]);

  useEffect(() => {
    if (token !== "" && tokenExpiry) {
      storeLinklyData("token", token);
      storeLinklyData("token-expiry", tokenExpiry);
    }
  }, [token, tokenExpiry]);

  // const handleMenuChange = (e: any) => {
  //   if (e.target.value === "pairing") setDisplayPage(POS_SALE_UI);
  //   else if (e.target.value === "sale") setDisplayPage(POS_SALE_UI);
  // };

  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handlePairingCodeChange = (e: any) => {
    setPairCode(e.target.value);
  };

  const handleAmtPurchaseChange = (e: any) => {
    setAmtPurchase(e.target.value);
  };

  const handleAmtCashChange = (e: any) => {
    setAmtCash(e.target.value);
  };

  const sendPairRequest = () => {
    dispatch(setPaired({ paired: false }));
    axios
      .post(
        pairingPath,
        {
          username,
          password,
          pairCode,
        },
        {
          headers: POSConfig.headers
        }
      )
      .then((response) => {
        console.log("SETTING SECRET");
        setSecret(response.data.secret);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  };

  const getToken = () => {
    console.log("secret :>> ", secret);
    axios
      .post(
        tokenRequestPath,
        {
          secret,
          posName,
          posVersion,
          posId,
          posVendorId,
        },
        {
          headers: POSConfig.headers
        }
      )
      .then((response) => {
        console.log("response :", response);
        const token = response.data.token
        const tokenExpiry = Date.now() + response.data.expirySeconds * 1000
        dispatch(setToken({ token, tokenExpiry }))
        // setToken(response.data.token);
        // setTokenExpiry(Date.now() + response.data.expirySeconds * 1000);
        console.log("SETTING PAIRED");
        dispatch(setPaired({ paired: true }))
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  };

  const purchase = () => {
    const amtPurchase = form.getFieldValue("purchase")
    const amtCash = form.getFieldValue("cash")
    console.log("purchase: ", amtPurchase)
    console.log("cash: ", amtCash)
    const request = {
      Merchant: "00",
      TxnType: "P",
      amtPurchase,
      amtCash,
      TxnRef: "1234567890",
      CurrencyCode: "AUD",
      CutReceipt: "0",
      ReceiptAutoPrint: "0",
      Application: "00",
      PurchaseAnalysisData: {
        OPR: "00766|test",
        AMT: "0042000",
        PCM: "0000",
      },
      Basket: {
        id: "t39kq18134553",
        amt: 2145,
        tax: 200,
        dis: 50,
        sur: 0,
        items: [
          {
            id: "t39kq002",
            sku: "k24086723",
            qty: 2,
            amt: 2145,
            tax: 200,
            dis: 50,
            name: "XData USB Drive",
          },
        ],
      },
    };

    sendRequest(request, TXN);
  };

  const refund = () => {
    try {
      const request = {
        Merchant: "00",
        TxnType: "R",
        AmtPurchase: 100,
        TxnRef: "1234567890",
        CurrencyCode: "AUD",
        CutReceipt: "0",
        ReceiptAutoPrint: "0",
        App: "00",
        PurchaseAnalysisData: {
          RFN: txnResponse?.PurchaseAnalysisData?.RFN,
          OPR: "00766|test",
          AMT: "0042000",
          PCM: "0000",
        },
      };

      sendRequest(request, TXN);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const oneButton = () => {
    const request = {
      txnType: "P",
      amtPurchase: 100,
      txnRef: "96B32M9UNZ421MEI",
      merchant: "99",
      application: "02",
      receiptAutoPrint: "0",
    };

    sendRequest(request, TXN);
  };

  const reprintReceipt = () => {
    try {
      const request = {
        Merchant: "99",
        ReceiptAutoPrint: "0",
        ReprintType: "2",
      };

      sendRequest(request, REPRINT_RECEIPT);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const getTxnStatus = () => {
    try {
      const request = {};

      sendRequest(request, TXN_STATUS);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const settlement = () => {
    try {
      const request = {
        Merchant: "00",
        SettlementType: "S",
        Application: "00",
        ReceiptAutoPrint: "0",
        CutReceipt: "0",
      };

      sendRequest(request, SETTLEMENT);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const sendCancel = () => {
    try {
      const request = {
        Key: "0",
      };

      sendRequest(request, CANCEL);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const sendRequest = (request: any, type: string) => {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config: any) => {
        config.headers["Authorization"] = "Bearer " + token;

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    let tempSessionId = uuidv4()

    let requestType;
    switch (type) {
      case TXN:
        requestType = "/transaction?async=false"
        break
      case REPRINT_RECEIPT:
        requestType = "/reprintreceipt?async=false"
        break
      case TXN_STATUS:
        requestType = "/transaction?async=false"
        tempSessionId = lastSessionId
        break
      case SETTLEMENT:
        requestType = "/settlement?async=false"
        break;
      case CANCEL:
        requestType = "/sendkey?async=false"
        tempSessionId = lastSessionId
    }

    const uri = linklyEndpoint + tempSessionId + requestType;
    console.log("request :", request);

    setIsInProgress(true)

    if (type === TXN_STATUS) {
      return axios
        .get(uri)
        .then((response) => {
          console.log("response :", response);
          setIsInProgress(false);
          const selectedData = {
            Status: response.status,
            Message: response.statusText,
          };
          setTxnResponse(selectedData);
        })
        .catch((error) => {
          console.log("ERROR", error);
          setIsInProgress(false);
          setTxnResponse(error);
        });
    }

    setLastSessionId(tempSessionId);

    return axios
      .post(
        uri,
        { request } //, txnHeaders
      )
      .then((response) => {
        console.log("response :", response);
        setIsInProgress(false);
        setTxnResponse(response.data.response);
      })
      .catch((error) => {
        console.log("ERROR", error);
        setIsInProgress(false);
        setTxnResponse(error);
      });
  };

  let display;
  switch (displayPage) {
    case PAIRING_MENU:
      display = (
        <form>
          <div className="form-group">
            <label htmlFor="clientIdInput">Username: </label>
            <input
              className="form-control w-75"
              id="clientIdInput"
              onChange={handleUsernameChange}
              value={username}
              placeholder="Linkly Cloud Username"
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="passwordInput">Password: </label>
            <input
              className="form-control w-75"
              id="passwordInput"
              onChange={handlePasswordChange}
              value={password}
              placeholder="Password"
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="pairingCodeInput">Pairing Code: </label>
            <input
              className="form-control w-75"
              id="pairingCodeInput"
              onChange={handlePairingCodeChange}
              value={pairCode}
              placeholder="Pairing code from pinpad"
            ></input>
          </div>
          <button
            type="button"
            className="btn btn-outline-info"
            onClick={sendPairRequest}
          >
            Pair
          </button>
        </form>
      );
      break;
    case POS_SALE_UI:
      display = (
        <div>
          <form className="row">
            <div className="form-group col">
              <label htmlFor="amtPurchaseInput">Purchase Amount: </label>
              <input
                className="form-control"
                id="amtPurchaseInput"
                onChange={handleAmtPurchaseChange}
                value={amtPurchase}
                placeholder="Amount"
              ></input>
            </div>
            <div className="form-group col">
              <label htmlFor="cashInput"> Cash: </label>
              <input
                className="form-control"
                id="cashInput"
                onChange={handleAmtCashChange}
                value={amtCash}
                placeholder="Amount"
              ></input>
            </div>
          </form>
          <br />

          <div className="form-group">
            <div className="row">
              <button
                type="button"
                className="btn btn-outline-info col"
                onClick={purchase}
              >
                Purchase
              </button>
              <button
                type="button"
                className="btn btn-outline-info col"
                onClick={refund}
              >
                Refund
              </button>
            </div>
            <div className="row">
              <button
                type="button"
                className="btn btn-outline-info col"
                onClick={oneButton}
              >
                One Button
              </button>
              <button
                type="button"
                className="btn btn-outline-info col"
                onClick={reprintReceipt}
              >
                Reprint receipt
              </button>
            </div>
            <div className="row">
              <button
                type="button"
                className="btn btn-outline-info col"
                onClick={getTxnStatus}
              >
                Txn Status
              </button>
              <button
                type="button"
                className="btn btn-outline-info col"
                onClick={settlement}
              >
                Settlement
              </button>
            </div>
          </div>
        </div>
      );
      break;
    default:
      display = <p>Please refresh page</p>;
  }

  let printResponse: {} | null | undefined;
  if (txnResponse) {
    printResponse = Object.entries(txnResponse).map((field: any) => {
      return (
        <tr key={field[0]}>
          <th scope="col">{field[0]}: </th>
          <td scope="col">{typeof field[1] !== "object" ? field[1] : null}</td>
        </tr>
      );
    });
  }

  const onFinish = (values: any) => {
    console.log('Success:', values)
    // setAmtCash(Number(values.cash))
    // setAmtPurchase(Number(values.purchase))
    // dispatch(staffSignIn({ staffCode: values.username, password: values.password }))
    // setVisibility(false)
    // navigate("/checkout")
  }

  const onFinishFailed = (values: any) => {
    console.log('Failed:', values)
    // dispatch(staffSignIn({ staffCode: values.username, password: values.password }))
    // setVisibility(false)
    // navigate("/checkout")
  }

  const SalesUI = () => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* <Typography style={{ marginBottom: 20, color: "grey" }}></Typography> */}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Purchase Amount"
          name="purchase"
          key="purchase"
          initialValue={0}
          rules={[{ required: true, message: "Enter purchase amount" }]}
        >
          <Input 
            // value={amtPurchase} 
            type="number"
            onChange={(e: any) => {
              const value = Number(e.target.value)
              console.log("value: ", value)
              form.setFieldsValue({ purchase: value })
              
              // console.log("type of value: ", typeof value)
              // setAmtPurchase(value)
            }}
          />
        </Form.Item>

        <Form.Item
          label="Cash"
          name="cash"
          key="cash"
          initialValue={0}
          rules={[{ required: true, message: "Enter cash amount" }]}
        >
          <Input 
            // value={amtCash} 
            type="number"
            onChange={(e) => {
              const value = Number(e.target.value)
              form.setFieldsValue({ cash: value })
            }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div style={{ display: "flex", flexDirection: "row"}}>
            <Button type="primary" htmlType="submit" onClick={purchase} style={styles.button}>
              Purchase
            </Button>
            <Button type="primary" danger htmlType="submit" onClick={refund} style={styles.button}>
              Refund
            </Button>
            <Button type="primary" htmlType="submit" onClick={getTxnStatus} style={styles.button}>
              Txn Status
            </Button>
          </div>
          <div>
            <Button type="primary" htmlType="submit" onClick={reprintReceipt} style={styles.button}>
              Reprint Receipt
            </Button>
            <Button type="primary" htmlType="submit" onClick={oneButton} style={styles.button}>
              One Button
            </Button>
            <Button type="primary" htmlType="submit" onClick={settlement} style={styles.button}>
              Settlement
            </Button>
          </div>
          
        </Form.Item>
      </Form>
    </div>
  )

  const PairStatus = () => (
    <div>
      <Typography style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20  }}>Connect Status: { paired ? "POS Online" : "POS Offline"}</Typography>
    </div>
  )

  const POSOutput = () => (
    <table className="table table-sm">
      <thead>
        <tr>
          <th scope="col">Field</th>
          <th scope="col">Response</th>
        </tr>
      </thead>
      <tbody>
        {/* <tr>
          <th scope="row">Connect Status: </th>
          <td>{paired ? "Connected" : "Offline"}</td>
        </tr> */}
        {printResponse}
      </tbody>
    </table>
  );

  const sendPurchaseRequest = async () => {
    const purchaseRequest = {
      txtPurchaseAmount: 1,
      txtCashOutAmount: 0,
      applicationCode: 0,
      requestOptionCode: 0
    }
    await doPurchase(purchaseRequest)
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: width * 0.4 }}>
        <Button type="primary" onClick={sendPurchaseRequest} >Do Purchase</Button>
        <PairStatus />
        <SalesUI />
      </div>
      <div style={{ width: width * 0.4, marginLeft: 50 }}>
        <POSOutput />
      </div>
      <ProgressModal 
        isVisible={isInProgress} 
        title={"Transaction In Progress"} 
        description={"Transcation In Progress"}
        onCancel={() => setIsInProgress(false)}   
      />
    </div>
  );
}

export default TransactionTab

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "white"
  },
  button: {
    margin: 10
  }
}
