import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"

export interface GlobalState {
  barcode: string | null
  weight: string | null
}

const initialState = {
  barcode: null,
  weight: null
} as GlobalState

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    freshBarcode(state, action: PayloadAction<{
      barcode: string
    }>) {
      // console.log("user: ", action.payload.user)
      state.barcode = action.payload.barcode
    },
    freshWeight(state, action: PayloadAction<{
      weight: string
    }>) {
      // console.log("user: ", action.payload.user)
      state.weight = action.payload.weight
    },
    
  }
})

export const {
  freshBarcode,
  freshWeight
} = globalSlice.actions

export default globalSlice.reducer
