import { notification } from "antd"
import React, { ErrorInfo } from "react"

type Props = { fallback?: React.ReactNode | ((params: { error: Error }) => React.ReactNode) }
type State = {
  hasError: true,
  error: Error
} | {
  hasError: false,
  error: null
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    notification.warn({
      message: `Encounter a network error, message: ${error.message}`,
      duration: 5,
    })
    console.error(error)
    console.error("errorInfo", errorInfo.componentStack)
  }

  render() {
    if (this.state.hasError && this.props.fallback) {
      // You can render any custom fallback UI
      if (typeof this.props.fallback === "function") {
        return this.props.fallback({ error: this.state.error })
      }
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary