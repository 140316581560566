import 'react-tiny-fab/dist/styles.css'
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form, PageHeader } from "antd"
import { CartItem, StyleSheet, Total, Order } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import useViewport from "../../hooks/useViewport"

interface TotalSectionProps {
  item: Total
}

const TotalSectionSmall = ({ item }: TotalSectionProps) => {
  const { width } = useViewport()
  console.log("totalsectionsmall", item)

  return (
    <div style={{width: 300}}
      // className="detailsPageHeader"
    >
      <div className="detailDescriptionsSmall">
        <div className="detailDescLabelSmall">
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Amount
        </div>
        <div className="detailDescValueSmall" style={{fontWeight: "bold"}}>
          {
            item?.amount < 0 ?
              "-$" + Math.abs(item?.amount)?.toFixed(2)
              :
              "$" + item.amount?.toFixed(2)
          }
        </div>
      </div>
      <div className="detailDescriptionsSmall">
        <div className="detailDescLabelSmall">
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Discount
        </div>
        <div className="detailDescValueSmall" style={{fontWeight: "bold"}}>
          {
            item?.discountAmount < 0 ?
              "$" + Math.abs(item.discountAmount)?.toFixed(2)
              :
              "-$" + item.discountAmount?.toFixed(2)
          }
        </div>
      </div>
      <div className="detailDescriptionsSmall">
        <div className="detailDescLabelSmall">
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/customer-icon@2x.png"} className="pageIcon16" />Points Redeemed
        </div>
        <div className="detailDescValueSmall" style={{fontWeight: "bold"}}>
          {
            item?.redeemPoints > 0 && `-${(item.redeemPoints / 200)?.toFixed(2)}`
          }
        </div>
      </div>
      <div className="detailDescriptionsSmall" style={{backgroundColor: "#FFEEEE"}}>
        <div className="detailDescLabelSmall" style={{fontSize: "20px", color: "#FC2C2C"}}>
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Total
        </div>
        <div className="detailDescValueSmall" style={{fontSize: "20px", fontWeight: "bold", color: "#FC2C2C"}}>
          {
            item?.totalAmount < 0 ?
              "-$" + Math.abs(item.totalAmount)?.toFixed(2)
              :
              "$" + item.totalAmount?.toFixed(2)
          }
        </div>
      </div>
      <div className="detailDescriptionsSmall">
        <div className="detailDescLabelSmall">
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />GST Included
        </div>
        <div className="detailDescValueSmall" style={{fontSize: "18px", fontWeight: "bold"}}>
          {
            item.gstAmount < 0 ?
              "-$" + Math.abs(item.gstAmount)?.toFixed(2)
              :
              "$" + item.gstAmount?.toFixed(2)
          }
        </div>
      </div>
      <div className="detailDescriptionsSmall">
        <div className="detailDescLabelSmall">
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/points-icon@2x.png"} className="pageIcon16" />Refund Included
        </div>
        <div className="detailDescValueSmall" style={{fontSize: "18px", fontWeight: "bold"}}>
          {
            item.refundAmount < 0 ?
              "-$" + Math.abs(item.refundAmount)?.toFixed(2)
              :
              "$" + item.refundAmount?.toFixed(2)
          }
        </div>
      </div>
    </div>
  )
}

export default TotalSectionSmall

// const styles: StyleSheet = {
//   container: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column"
//   },
//   footer: {
//     display: "flex",
//     justifyContent: "flex-end",
//     flexDirection: "column",
//     marginLeft: 500
//     // position: "absolute"
//     // display: "flex",
//     // justifyContent: "flex-end",
//     // alignItems: "flex-end"
//   },
//   boldFont: {
//     fontWeight: "bold"
//   },
//   totalContainer: {
//     right: 10,
//     position: "absolute"
//   },
//   total: {
//     display: "flex",
//     flexDirection: "row",
//     marginLeft: 20,
//     width: 500
//   },
//   totalDescription: {
//     fontWeight: "bold",
//     marginRight: 20,
//     fontSize: 22,
//     width: 150,
//     alignSelf: "center"
//   },
//   totalContent: {
//     fontWeight: "bold",
//     // marginRight: 20 ,
//     fontSize: 30,
//     alignSelf: "center"
//   },
//   button: {
//     marginBottom: 20,
//     width: 140,
//     height: 140
//   }
// }