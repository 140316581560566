import API, { categoryPageSize as pageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

export const listCategories = async () => {
  try {
    const url = generateRequestUrl(API.queryCategory)
    const config = {
      params: { "pageSize": pageSize }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong at get categories!"
    })
    return e
  }
}
