import {
  Button,
  Descriptions,
  Divider,
  PageHeader,
  Popconfirm,
  Spin,
  Typography,
  Image,
  Table,
  Tag,
  Checkbox,
  notification,
  InputNumber
} from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Product, Order, InvoiceHead, InvoiceItem, Customer } from "../../models"
import { checkIfInvoiceContainsRefundItem, customerInDBToCustomer, invoiceInDBsToLocalInvoices, randomInteger } from "../../utils/Global"
import { royalpayRefundOrder } from "../../utils/royalpay-functions"
import { postRefund, listRefunds } from "../../api/refund"
import { setToken } from "../../redux/Config/slice"
import { loadAllInvoices } from "../../redux/Customer/slice"
import { listInvoices, listNoneRefundInvoices } from "../../api/invoice"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { clearRefundCart, setCurrentRefundInvoice, setRefundMode } from "../../redux/Checkout/slice"
import { useNavigate } from "react-router-dom"
import { getConfiguration } from "../../utils/configurate"
import ReceiptModal from "../Checkout/Payment/ReceiptModal"
import RefundReceiptModal from "../Refund/RefundReceiptModal"
import { setReceiptCopy } from "../../redux/Payment/slice"
import useViewport from "../../hooks/useViewport"
import ScrollButtons from "../common/ScrollButtons"
import { serveCustomer } from "../../redux/Customer/slice"
type Props = {
  invoice: InvoiceHead
  closeDrawer?: () => void
}

export default function InvoiceDetail({ invoice, closeDrawer }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { customers, servingCustomer } = Customer
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRefunding, setIsRefunding] = useState(false)
  const [selectedRefundItems, setSelectedRefundItems] = useState<InvoiceItem[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [refundQty, setRefundQty] = useState<Map<number, number>>(new Map(invoice.InvoiceItems.map(item => { return [item.ID, 0] })))
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isReceiptVisible, setIsReceiptVisible] = useState(false)
  const [isRefundReceiptVisible, setIsRefundReceiptVisible] = useState(false)
  const [selectedMethod, setSelectedMethod] = useState<"cash" | "card" | "royalpay">("cash")
  const PosConfig = getConfiguration("InvoiceDetail")
  const { machineID: MachineID, warehouseCode: WarehouseCode } = PosConfig
  const recvAcct: any = invoice?.RecvAccts?.filter(acct => !acct.Notes)
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [oldPage, setOldPage] = useState(1);

  useEffect(() => {
    console.log("invoice:", invoice)
    setRefundQty(new Map(invoice.InvoiceItems.map(item => { return [item.ID, 0] })))
    if (invoice.ReceiptCustomerCopy && invoice.ReceiptCustomerCopy.indexOf("CUSTOMER COPY") > -1) {
      console.log('card -------------------->', invoice.ReceiptCustomerCopy.split(","))
      setSelectedMethod('card')
      dispatch(setReceiptCopy({ customerCopy: invoice.ReceiptCustomerCopy.split(",") }))
    } else {
      setSelectedMethod('cash')
      dispatch(setReceiptCopy({ customerCopy: [] }))
    }
  }, [invoice])

  const itemColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: 40,
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Description",
      dataIndex: ["Product", "Description"],
      key: "ID",
      render: (description: string, record: InvoiceItem) => {
        // The Product in record is the same as DB
        // @ts-ignore
        const product: ProductInDB = record?.Product
        if (description) {
          if (record.Qty < 0 || record.RefundQty > 0) {
            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography>{product?.KoreanName ? `${product?.KoreanName} ${description}` : description}</Typography>
                <Tag
              color={"error"}
              style={{
                width: "50px",
                marginLeft: "auto",
                marginTop: "-20px",
                marginRight: "0px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "16px",
                borderRadius: "8px"
              }}>refund</Tag>
              </div>
            )
          } else {
            return (
              <Typography>{product?.KoreanName ? `${product?.KoreanName} ${description}` : description}</Typography>
            )
          }
        } else {
          if (record.Qty < 0) {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography>{product?.KoreanName ? `${product?.KoreanName} ${description}` : record.Product?.name}</Typography>
                <Tag color={"error"} style={{ marginLeft: 20, alignItems: "center", justifyContent: "center", display: "flex" }}>refund</Tag>
              </div>
            )
          } else {
            return (
              <Typography>{product?.KoreanName ? `${product?.KoreanName} ${description}` : record.Product?.name}</Typography>
            )
          }
        }
      }
    },
    {
      title: "Order Qty",
      dataIndex: "Qty",
      key: "ID",
      width: 40,
      render: (text: number, record: InvoiceItem) => (
        <Typography style={{textAlign: "right"}}>{text}</Typography>
      )
    },
    {
      title: "Available Refund Qty",
      dataIndex: "RefundQty",
      key: "ID",
      width: 60,
      render: (text: number, record: InvoiceItem) => (
        <Typography style={{textAlign: "right"}}>{record.Qty - Math.abs(text) > 0 ? record.Qty - Math.abs(text) : 0}</Typography>
      )
    },
    // {
    //   title: "Refund Qty",
    //   dataIndex: "Qty",
    //   key: "ID",
    //   render: (qty: number, record: InvoiceItem) => (
    //     <InputNumber defaultValue={0} max={qty} min={0} onChange={(value: number) => { setRefundQty(new Map(refundQty.set(record.ID, value))) }} disabled={qty - Math.abs(record.RefundQty) <= 0} />
    //   )
    // },
    {
      title: "Unit Price",
      dataIndex: "Price",
      // dataIndex: "UnitCost",
      key: "ID",
      width: "55px",
      render: (text: any) => <Typography style={{textAlign: "right"}}>${text.toFixed(2)}</Typography>
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "ID",
      width: "55px",
      render: (text: any) => <Typography style={{textAlign: "right"}}>{text?.toFixed(0)}%</Typography>
    },
    {
      title: "GST",
      dataIndex: "GSTRate",
      key: "ID",
      width: "40px",
      render: (text: any) => <Typography style={{textAlign: "right"}}>{text?.toFixed(0)}%</Typography>
    },
    {
      title: "Subtotal",
      // dataIndex: "Price",
      width: "70px",
      key: "ID",
      render: (text: any, record: InvoiceItem) => <Typography style={{textAlign: "right"}}>${(record.Price * (record.Discount? (1 - record.Discount/100) : 1) * record.Qty).toFixed(2)}</Typography>
    },
    
  ]

  const refreshInvoiceList = async () => {
    const invoicesData = await listNoneRefundInvoices()
    const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)
    // console.log("invoice: ", invoices)
    dispatch(loadAllInvoices({ invoices: invoices }))
  }

  const RenderCustomerName = () => {
    let customerName = "Cash Customer"
    const filteredCustomers = customers.filter((customer: Customer) => { return customer.CustomerCode === invoice.CustomerCode })
    if (filteredCustomers.length > 0) {
      customerName = filteredCustomers[0].name
    }
    return (
      <Typography>{customerName}</Typography>
      // <Typography>{invoice.CustomerCode + "/" + customerName}</Typography>
    )
  }

  const calculateRefundAmount = () => {
    let amount = 0
    // console.log(invoice.InvoiceItems)
    invoice.InvoiceItems.forEach(item => {
      if (selectedRowKeys.includes(item.ID)) {
        //@ts-ignore
        amount += item.UnitCost * refundQty.get(item.ID) * (1 - item.Discount / 100)
      }
    })
    return -amount
  }

  const isEligibleRefund = () => {
    let result = true
    invoice.InvoiceItems.forEach(item => {
      if (selectedRowKeys.includes(item.ID) && (refundQty.get(item.ID)! > item.Qty - Math.abs(item.RefundQty) || item.Qty < 1)) {
        result = false
      }
    })
    return result
  }

  const getRefundInvoiceItems = () => {
    const result: InvoiceItem[] = []
    invoice.InvoiceItems.forEach(item => {
      if (selectedRowKeys.includes(item.ID)) {
        const newItem = {
          Price: item.Price,
          ProductCode: item.ProductCode,
          Qty: -refundQty.get(item.ID)!,
          UnitCost: item.UnitCost,
          Discount: item.Discount,
          GSTRate: item.GSTRate,
          ReferenceItemID: item.ID
        }
        // const { InvoiceNo, Product, CreditedQty, DeductStockQty, KindFlag...newItem } = item
        //@ts-ignore
        result.push(newItem)
      }
    })
    return result
  }

  const onSelectChange = (newSelectedRowKeys: any[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const onPostRefund = async () => {
    if (isEligibleRefund()) {
      const amount = calculateRefundAmount()
      const items = getRefundInvoiceItems()
      const ReferenceNo = invoice.InvoiceNo
      const RecvAcct = {
        //@ts-ignore
        PayBy: invoice.RecvAccts[0]?.PayBy ? invoice.RecvAccts[0]?.PayBy : "CASH",
        PaidAmount: amount,
        MachineID,
        Transfer: false,
        PaymentID: randomInteger(1, 10000)
      }
      const payload = {
        InvoiceHead: {
          Amount: Number(amount.toFixed(2)),
          CustomerCode: invoice.CustomerCode,
          InvoiceDate: new Date().toString(),
          InvoiceItems: items,
          WarehouseCode,
          ReferenceNo
        },
        //@ts-ignore
        RecvAcct
      }
      //@ts-ignore
      await postRefund(payload, refreshInvoiceList)
      setSelectedRowKeys([])
      closeDrawer && closeDrawer()

    } else {
      notification.warning({ message: "Some Items Are Not Eligible for Refund!" })
    }

  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.38
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.38
    }
    setOldPage(newPage)
  };

  useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);
  
  return (
    <PageHeader
      className="detailsPageHeader"
      ghost={false}
      backIcon={false}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="detailNo">{invoice.InvoiceNo}</div>
          <Tag color={checkIfInvoiceContainsRefundItem(invoice.InvoiceItems) ? "error" : "success"} style={{
            marginLeft: "10px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "16px",
            borderRadius: "8px"
          }}>
            {checkIfInvoiceContainsRefundItem(invoice.InvoiceItems) ? "incl refund" : "paid"}
          </Tag>

        </div>

      }
      extra={isRefunding ? [
        <button
          key="print"
          className="printDetailsButton"
          onClick={() => {
            setIsReceiptVisible(true)
          }}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/print-icon.png"} className="pageIcon" />
          Print
        </button>
      ]
        :
        [<div style={{ display: "flex" }}>
          <button
            className="scanToRefundButton"
            key="scan"
            onClick={() => {
              let isScan:boolean | undefined = undefined
              console.log("待退货的发票", invoice)
              dispatch(setRefundMode({ isRefundMode: true }))
              dispatch(setCurrentRefundInvoice({ invoice: invoice }))
              if (invoice.InternalNotes && invoice.InternalNotes.indexOf("3*RewardPoints")>-1) {
                isScan = true
              } else isScan = false
              console.log("当前发票客户退3倍积分？", isScan)
              const selectedCustomer = customerInDBToCustomer([invoice.Customer!])
              dispatch(serveCustomer({ customer: {...selectedCustomer[0], isScan} }))
              // dispatch(serveCustomer({ customer: invoice.Customer }))
              dispatch(clearRefundCart({}))
              navigate("/checkout")
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/scanToRefund-icon.png"} className="pageIcon" />
            Scan to Refund
          </button>

          <button
            key="print"
            className="printDetailsButton"
            onClick={() => {
              setIsReceiptVisible(true)
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/print-icon.png"} className="pageIcon" />
            Print
          </button>

          <div className="paidStatus">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/paid-icon.png"} className="pageIcon" />
            Paid
          </div>
        </div>
        ]
      }
    >
      <Spin tip="Loading..." spinning={isLoading}>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Store
          </div>
          <div className="detailDescValue">
            {invoice.StoreId ? invoice.StoreId : "STORE01"}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Sales
          </div>
          <div className="detailDescValue">
            {invoice.SalesName ? invoice.SalesName : "Supervisor"}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/customer-icon@2x.png"} className="pageIcon16" />Customer Name
          </div>
          <div className="detailDescValue">
            {/* <RenderCustomerName /> */}
            {invoice.Customer ? invoice.Customer.CustomerName : "Cash Customer"}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Total Amount
          </div>
          <div className="detailDescValue">
            ${invoice.PaidAmount?.toFixed(2)}
            {/* ${invoice.Amount?.toFixed(2)} */}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/totalAmount-icon@2x.png"} className="pageIcon16" />Points Redeemed
          </div>
          <div className="detailDescValue">
            {invoice.RedeemPoints ? invoice.RedeemPoints : 0}
          </div>
        </div>
        <div className="detailDescriptions">
          <div className="detailDescLabel">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/points-icon@2x.png"} className="pageIcon16" />Points Earned
          </div>
          <div className="detailDescValue">
            {invoice.RedeemPoints ? invoice.RewardPoints : invoice.Amount ? Math.floor(invoice.Amount * 1) : 0}
            {/* {invoice.RedeemPoints ? invoice.RewardPoints : invoice.Amount ? Math.floor(invoice.Amount * 3) : 0} */}
          </div>
        </div>
        {
          invoice.RecvAccts ?
            <div className="detailDescriptions">
              <div className="detailDescLabel">
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/paymentMethod-icon@2x.png"} className="pageIcon16" />Payment Method
              </div>
              <div className="detailDescValue">
                {/* {invoice.RecvAccts} */}
                {recvAcct[0]?.PayBy}{recvAcct[1]? ` & ${recvAcct[1].PayBy}` : null}
              </div>
            </div>
            :
            null
        }
        
        {
          isRefunding &&
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography
              style={{ color: "red", fontWeight: "bold", marginBottom: 10, marginTop: 10 }}
            >
              Selected Refund Items: {selectedRowKeys.length}, Total: $ {calculateRefundAmount().toFixed(2)}
            </Typography>
            <div>
              <Button
                onClick={() => setIsRefunding(false)}
                type="primary"
                danger
                style={{ marginRight: 20 }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (selectedRowKeys.length > 0 && Math.abs(calculateRefundAmount()) > 0) {
                    setIsConfirmModalVisible(true)
                  }
                }}
              >
                Confirm Refund
              </Button>
            </div>
          </div>
        }
        <Table
          rowClassName={(_, index) => {
            if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
          }}
          scroll={{
            y: height * 0.455,
            // scrollToFirstRowOnChange: true
          }}
          className="pageDetailTable"
          columns={itemColumns}
          dataSource={invoice.InvoiceItems}
          pagination={false}
          // rowSelection={rowSelection}
          rowKey={(row) => row.ID}
        />
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}> */}
          <ScrollButtons
            onPageDown={onLastPage}
            onPageUp={onFirstPage}
            onRowDown={onPageDown}
            onRowUp={onPageUp}
          />
        </div>
      </Spin>
      <ActionConfirmModal
        isVisible={isConfirmModalVisible}
        description={"Please wait for manager to confirm"}
        actionText={"Manager Approve"}
        cancelText={"Send Notification"}
        onConfirm={() => {
          setIsConfirmModalVisible(false)
          setIsRefunding(false)
          onPostRefund()
        }}
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }}
      // rightButtonDanger={true}
      />
      {isReceiptVisible ?
        <ReceiptModal
          isVisible={isReceiptVisible}
          invoice={invoice}
          onCancel={() => setIsReceiptVisible(false)}
          // paymentMethod={selectedMethod}
          isSale={false}
        // setVisibility={(value: boolean) => {
        //   setIsReceiptModalVisible(value)
        // }}
        />
        : null
      }

      {isRefundReceiptVisible ?
        <RefundReceiptModal
          isVisible={isRefundReceiptVisible}
          //@ts-ignore
          invoice={invoice}
          onCancel={() => setIsRefundReceiptVisible(false)}
          isSale={false}
        />
        : null
      }
    </PageHeader>
  )
}
