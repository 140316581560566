import checkoutReducer from "./Checkout/slice"
import orderReducer from "./Order/slice"
import productReducer from "./Product/slice"
import customerReducer from "./Customer/slice"
import paymentReducer from "./Payment/slice"
import configReducer from "./Config/slice"
import userReducer from "./User/slice"
import invoiceReducer from "./Invoice/slice"
import cashDeclarationReducer from "./CashDeclaration/slice"
import globalsReducer from "./Globals/slice"

const reducers = {
  Checkout: checkoutReducer,
  Order: orderReducer,
  Product: productReducer,
  Customer: customerReducer,
  Payment: paymentReducer,
  Config: configReducer,
  User: userReducer,
  Invoice: invoiceReducer,
  cashDeclaration: cashDeclarationReducer,
  Globals: globalsReducer
}

export default reducers
