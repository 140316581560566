import { Product, Category, CartItem, Order, OrderItem, CartOrder, Total, Customer, InvoiceHead, SpecialInDB, PaymentTotal, CartItemType, SpecialPriceType } from "../models"
import moment from "moment"

// export const snackImage = "https://images.unsplash.com/photo-1621939514649-280e2ee25f60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5070&q=80"

export const snackImage = require("../assets/png/default.png")
export const snackImagePath = "/images/404.png"

export const dateFormat = "h:mm a, DD/MM/YYYY"

export const ExampleCategories: Category[] = [
  {
    id: "1",
    storeId: "1",
    categoryCode: "c1",
    name: "Category 1",
    description: "Example category 1",
    createdAt: "20220203",
    updatedAt: "20220203"
  },
  {
    id: "2",
    storeId: "1",
    categoryCode: "c2",
    name: "Category 2",
    description: "Example category 2",
    createdAt: "20220203",
    updatedAt: "20220203"
  },
  {
    id: "3",
    storeId: "1",
    categoryCode: "c3",
    name: "Category 3",
    description: "Example category 3",
    createdAt: "20220203",
    updatedAt: "20220203"
  }
]

export const ExampleProducts: Product[] = [
  {
    id: "1",
    storeId: "1",
    name: "Product 1",
    productCode: "p01",
    price: 19.99,
    categoryCode: "c1",
    category: ExampleCategories[0],
    stock: 10,
    description: "Example product 1",
    image: snackImage,
    isFavourite: true,
    isAvailable: true,
    isScalable: true,
    isSearchable: true,
    specs: [],
    extras: [],
    createdAt: "20220203",
    updatedAt: "20220203",
    hits: 20,
    nameChinese: "Name In Other Language",
    allowDiscount: true,
    netPrice: 19.99
  },
  {
    id: "2",
    storeId: "1",
    name: "Product 2",
    productCode: "p02",
    price: 18,
    categoryCode: "c2",
    category: ExampleCategories[1],
    stock: 10,
    description: "Example product 2",
    image: snackImage,
    isFavourite: true,
    isAvailable: true,
    isScalable: true,
    isSearchable: true,
    specs: [],
    extras: [],
    createdAt: "20220203",
    updatedAt: "20220203",
    hits: 25,
    nameChinese: "Name In Other Language",
    allowDiscount: true,
    netPrice: 19.99
  },
  {
    id: "3",
    storeId: "1",
    name: "Product 3",
    productCode: "p03",
    price: 15,
    categoryCode: "c3",
    category: ExampleCategories[2],
    stock: 10,
    description: "Example product 3",
    image: snackImage,
    isFavourite: true,
    isAvailable: true,
    isScalable: true,
    isSearchable: true,
    specs: [],
    extras: [],
    createdAt: "20220203",
    updatedAt: "20220203",
    hits: 30,
    nameChinese: "Name In Other Language",
    allowDiscount: true,
    netPrice: 19.99
  },
  {
    id: "4",
    storeId: "1",
    name: "Product 4",
    productCode: "p04",
    price: 15,
    categoryCode: "c3",
    category: ExampleCategories[2],
    stock: 10,
    description: "Example product 3",
    image: snackImage,
    isFavourite: true,
    isAvailable: true,
    isScalable: true,
    isSearchable: true,
    specs: [],
    extras: [],
    createdAt: "20220203",
    updatedAt: "20220203",
    hits: 10,
    nameChinese: "Name In Other Language",
    allowDiscount: true,
    netPrice: 19.99
  },
  {
    id: "5",
    storeId: "1",
    name: "Product 5",
    productCode: "p05",
    price: 15,
    categoryCode: "c3",
    category: ExampleCategories[2],
    stock: 10,
    description: "Example product 3",
    image: snackImage,
    isFavourite: true,
    isAvailable: true,
    isScalable: true,
    isSearchable: true,
    specs: [],
    extras: [],
    createdAt: "20220203",
    updatedAt: "20220203",
    hits: 15,
    nameChinese: "Name In Other Language",
    allowDiscount: true,
    netPrice: 19.99
  },
  {
    id: "6",
    storeId: "1",
    name: "Product 6",
    productCode: "p06",
    price: 15,
    categoryCode: "c3",
    category: ExampleCategories[2],
    stock: 10,
    description: "Example product 3",
    image: snackImage,
    isFavourite: true,
    isAvailable: true,
    isScalable: true,
    isSearchable: true,
    specs: [],
    extras: [],
    createdAt: "20220203",
    updatedAt: "20220203",
    hits: 40,
    nameChinese: "Name In Other Language",
    allowDiscount: true,
    netPrice: 19.99
  }
]

export const ExampleCartItems: CartItem[] = [
  {
    displayId: "1",
    itemId: "1",
    productId: "1",
    productInfo: ExampleProducts[0],
    quantity: 2,
    unitPrice: 20,
    itemPrinted: false,
    itemExtras: [],
    itemSpecs: [],
    total: 40,
    applyGst: true,
    allowDiscount: true,
    discountAmount: 0,
    discountRatio: 0,
    gstAmount: 0,
    gstRatio: 0,
    addedAt: "20220209",
    type: CartItemType.Normal,
    originalPrice: 1.23,
    netPrice: 1.23,
  },
  {
    displayId: "2",
    itemId: "2",
    productId: "2",
    productInfo: ExampleProducts[1],
    quantity: 3,
    unitPrice: 18,
    itemPrinted: false,
    itemExtras: [],
    itemSpecs: [],
    total: 54,
    applyGst: true,
    allowDiscount: true,
    discountAmount: 0,
    discountRatio: 0,
    gstAmount: 0,
    gstRatio: 0,
    addedAt: "20220209",
    type: CartItemType.Normal,
    originalPrice: 2.34,
    netPrice: 1.23,
  }
]

// export const ExampleCartOrder: CartOrder = {
//   storeId: "1",
//   type: "dineIn",
//   discoutAmount: 0,
//   taxAmount: 0,
//   refundAmount: 0,
//   total: 94,
//   cartItems: ExampleCartItems
// }

export const ExampleTotal: Total = {
  amount: 0,
  netAmount: 0,
  discountAmount: 0,
  voucherAmount: 0,
  redeemPoints: 0,
  redeemAmount: 0,
  totalAmount: 0,
  gstAmount: 0,
  totalWithGstAmount: 0,
  gainPoints: 0,
  pointsRatio: 200,
  gstRatio: 0,
  refundAmount: 0
}

export const EmptyPaymentTotal: PaymentTotal = {
  amount: 0,
  netAmount: 0,
  discountAmount: 0,
  voucherAmount: 0,
  redeemPoints: 0,
  redeemAmount: 0,
  totalAmount: 0,
  gstAmount: 0,
  totalWithGstAmount: 0,
  gainPoints: 0,
  pointsRatio: 200,
  gstRatio: 0,
  refundAmount: 0,
  depositAmount: 0,
  receivableAmount: 0,
  roundingAmount: 0,
  surchargeAmount: 0,
  changeAmount: 0
}
 
export const ExampleCustomer: Customer = {
  id: "1",
  storeId: "1",
  CustomerCode: "000001",
  Points: 0,
  isOwner: false,
  createdAt: "20220216",
  updatedAt: "20220216",
  lastLogin: "20220216",
  name: "POINTOFSALES",
  password: "123456",
  deleted: false,
  allowConfigureStore: false,
  allowManageStaff: false,
  allowManageProduct: false,
  allowMakeOrder: false,
  allowCheckData: false,
  allowCashier: false,
  Mobile: "0000000000"
}

export const ExampleCustomer1: Customer = {
  id: "2",
  storeId: "1",
  CustomerCode: "000034",
  Points: 200,
  isOwner: false,
  createdAt: "20220216",
  updatedAt: "20220216",
  lastLogin: "20220216",
  name: "Tracy",
  password: "123456",
  deleted: false,
  allowConfigureStore: false,
  allowManageStaff: false,
  allowManageProduct: false,
  allowMakeOrder: false,
  allowCheckData: false,
  allowCashier: false,
  Mobile: "06754231"
}

export const ExampleCustomers: Customer[] = [
  ExampleCustomer, ExampleCustomer1
]

export const ExampleInvoiceInDB: InvoiceHead = {
  InvoiceNo: "I12345",
  CustomerCode: "000001",
  CustomerName: "Cash Customer",
  GSTStatus: true,
  Delivery: true,
  ExemptTax: true,
  Printed: true,
  StoreId: "STORE01",
  WarehouseCode: "0001",
  Amount: 0,
  GST: 0,
  InvoiceItems:[
    // { 
    //   ID: "1",
    //   ProductCode: "000007",
    //   Qty: 1
    //   // Product: ExampleProducts[0]
    // },
  ],
  PaidAmount: 0,
  RedeemPoints: 0
}

export const ExampleInvoices: InvoiceHead[] = [
  {
    InvoiceNo: "I0124",
    CustomerCode: "032",
    GSTStatus: true,
    Delivery: true,
    ExemptTax: true,
    Printed: true,
    StoreId: "tempStoreId",
    InvoiceItems:[
      // { 
      //   ID: "1",
      //   ProductCode: "000007",
      //   Product: ExampleProducts[0]
      // },
      // { 
      //   ID: "2",
      //   ProductCode: "000008",
      //   Product: ExampleProducts[1]
      // },
      // { 
      //   ID: "3",
      //   ProductCode: "000009",
      //   Product: ExampleProducts[2]
      // }
    ],
    PaidAmount: 333,
    RedeemPoints: 1
  },
  {
    InvoiceNo: "I0125",
    CustomerCode: "032",
    GSTStatus: true,
    Delivery: true,
    ExemptTax: true,
    Printed: true,
    StoreId: "tempStoreId",
    InvoiceItems:[
      // { 
      //   ID: "1",
      //   ProductCode: "000007",
      //   Product: ExampleProducts[0]
      // },
      // { 
      //   ID: "2",
      //   ProductCode: "000008",
      //   Product: ExampleProducts[1]
      // },
      // { 
      //   ID: "3",
      //   ProductCode: "000009",
      //   Product: ExampleProducts[2]
      // }
    ],
    PaidAmount: 333,
    RedeemPoints: 1
  },
  {
    InvoiceNo: "I0126",
    CustomerCode: "033",
    GSTStatus: true,
    Delivery: true,
    ExemptTax: true,
    Printed: true,
    StoreId: "tempStoreId",
    InvoiceItems:[
      // { 
      //   ID: "1",
      //   ProductCode: "000007",
      //   Product: ExampleProducts[0]
      // },
      // { 
      //   ID: "2",
      //   ProductCode: "000008",
      //   Product: ExampleProducts[1]
      // },
      // { 
      //   ID: "3",
      //   ProductCode: "000009",
      //   Product: ExampleProducts[2]
      // }
    ],
    PaidAmount: 333,
    RedeemPoints: 1
  }
]

export const ExampleSpecials: Array<SpecialInDB> = [
  {
    ID: "0fce3be5-bada-4602-ae16-56d975707f9c",
    ProductCode: "000006",
    CateID: 5,
    type: SpecialPriceType.BuyNGetOneFree,
    BuyNGetOneFree: 2,
    SpecialPriceWithoutTerm: 1,
    Live: true,
    StartDate: "2022-02-28T23:02:34.000Z",
    EndDate: "2022-03-01T23:02:34.000Z",
    CreatedAt: "2022-03-30T23:02:33.597Z"
  }
]

export const ExamplePOSReceipt = [
  "------------------------",
  "                        ",
  "  TEST MERCHANT TITLE   ",
  "   TEST MERCHANT NAME   ",
  "         LINE2          ",
  "                        ",
  moment().format(dateFormat),
  "                        ",
  "CREDIT ACCOUNT          ",
  "                        ",
  "PURCHASE   AUD     $1.00",
  "                --------",
  "TOTAL      AUD     $1.00",
  "                        ",
  "APPROVED -  00          ",
  "                        ",
  "PLEASE RETAIN AS RECORD ",
  "      OF PURCHASE       ",
  "------------------------"
]
