import "../../css/Global.css"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { Layout, Menu, Breadcrumb, List, message, Typography, Modal } from 'antd'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons'
import { Product, Category, CartItem, CartItemType, ProductInDB, SpecialPrice, SpecialPriceType } from "../../models"
import { ExampleCategories, ExampleProducts, snackImage } from "../../constants/data"
import ProductDetail from "../../components/Product/ProductDetail"
import CheckoutProdutCard from "../../components/Product/CheckoutProductCard"
import CheckoutCategoryCard from "../Category/CheckoutCategoryCard"
import { addItemToCart, updateTotal, showKeyboard, switchKeyboardLanguage, clearCart } from "../../redux/Checkout/slice"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { calculateCartTotal, generateCartItemId, productInDBToProduct, nowIsWithinTimePeriod, calculateGSTAmount } from "../../utils/Global"
import SearchBar from "../common/SearchBar"
import { setDisplayType, showAllProducts, searchProduct, selectCategory, updateProduct, loadProductsByCategory, loadCheckoutProductsByPage, setCheckoutSearchedValue, setCheckoutSelectedCategory, loadCheckoutProductsPrePage, loadCheckoutProductsNextPage } from "../../redux/Product/slice"
import KeyboardModal from "../common/KeyboardModal"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import { primary } from "../../constants/Colors"
import useViewport from "../../hooks/useViewport"
import { getSpecialPriceByProductCode, getProductByProductCode, searchProductsByName, searchProductsByDescriptionByPage, listProductsByCategoryByPage, getProductByBarCode, listProductsByMostUsed, recordProductClick, listProductsByMannuallyAdd } from "../../api/product"
import { listProductsByCategory } from "../../api/product"
import ProductVirtualGrid from "../Product/ProductVirtualGrid"
import useSound from 'use-sound'
import ProductPageList from "../Product/ProductPageList"
import CategoryPageList from "../Product/CategoryPageList"
import { DisplayDataType } from "../../constants/Enums"
import { clearPaymentProcedure } from "../../redux/Payment/slice"
import axios from "axios"
import API from "../../constants/API"
import { GlobalState, freshBarcode, freshWeight } from "../../redux/Globals/slice"
import ProgressModal from "../common/ProgressModal"
import MoreBarcodeModal from "./Staff/MoreBarcodeModal"
import { randomString, hashString } from "../../utils/Global"
import AlertModal from "../common/AlertModal"
const successBeep = require('../../assets/sounds/scanproduct.mp3')
// const successBeep = require('../../assets/sounds/normal.mp3')
const waitBeep = require('../../assets/sounds/wait.mp3')
const errorBeep = require('../../assets/sounds/error.mp3')
// const successBeep = require('../../assets/sounds/success_beep.mp3')

const { Title } = Typography

const MostPopularCategory = [
  {
    id: "0",
    storeId: "1",
    categoryCode: "c0",
    name: "Most Used",
    description: "Most Used",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    image: snackImage
  }
]

let buffer = '' // buffer for constructing the barcode from key presses

type Props = {
  closeAllModal?: () => void
  isScanable: boolean
  setIsScanable: (value: boolean) => void
}
export default function ProductSection({ closeAllModal, isScanable, setIsScanable }: Props) {
  const { SubMenu } = Menu
  const { Header, Content, Sider } = Layout
  const { width, height } = useViewport()
  const [playSuccess] = useSound(successBeep)
  const [playWait] = useSound(waitBeep)
  const [playError] = useSound(errorBeep)
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const Product = useSelector((state: RootStateOrAny) => state.Product)
  const Config = useSelector((state: RootStateOrAny) => state.Config)
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)
  const { itemsInCart, isKeyboardVisible, keyboardLanguage, isRefundMode } = Checkout
  const { products, searchedProducts, productsByCategory, categories, checkoutProducts, displayType, checkoutSeachedValue, checkoutSelectedCategory } = Product
  const { currentScreen } = Config
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { showReceiptModal, showRefundReceiptModal } = Payment
  const [currentCatgory, setCurrentCategory] = useState<Category | null>(null)
  const [currentProduct, setCurrentProduct] = useState<Product>()
  const [curMoreBarcodeProducts, setCurMoreBarcodeProducts] = useState<Product[]>()
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [curAppendItem, setCurAppendItem] = useState<any>()
  const [isWaitScaleModal, setIsWaitScaleModal] = useState<boolean>(false)
  const [isMoreBarcodeModal, setIsMoreBarcodeModal] = useState<boolean>(false)
  const curIsScanable = useRef<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>("")
  const [itemDisplayType, setItemDisplayType] = useState<string>(displayType)
  
  // const [itemDisplayType, setItemDisplayType] = useState<DisplayDataType>(DisplayDataType.All)
  const [curWeight, setCurWeight] = useState<number>(0)
  const dispatch = useDispatch()
  const tempQty = useRef<number>(1)//初始值为扫码物品，可能需要称重
  const [errorMsg, setErrorMsg] = useState("")
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  let IntervalMessage: any
  let countInterval: number = 0
  // console.log("产品明细在productsection...", checkoutProducts)

  const appendItemTrigger = async (item: Product, itemDB?: ProductInDB, isSearch?: boolean, mannuallyWeight?: number) => {
    // console.time('addToCart-------------')
    // const appendItemPromise = () => new Promise(async (resolve, reject) => {
      playSuccess()//提示音提前
      const itemId = generateCartItemId(item.productCode)
      // console.timeLog('addToCart', 'aaaaaaaaaaaaaaa')
      if (item.isScalable && !mannuallyWeight) {
        //发送称重指令
        //@ts-ignore
        if (window?.electronAPI) {
          await new Promise((resolve, reject) => {
            //@ts-ignore
            window?.electronAPI?.requestWeight((response: any) => {
              //处理称重数据
              console.log("开始处理请求重量后的回调")
              console.log('response', response)
              if (response !== "failed" && Number(response) > 0) {
                setTimeout(() => tempQty.current)
                tempQty.current = Number(response)
                setTimeout(() => {
                  console.log("curWeight is changed???", tempQty.current)
                }, 1000);
                resolve(true)
              } else {//显示提示称重modal
                setCurAppendItem({ item, itemDB })
                setIsWaitScaleModal(true)
                playWait()
                setIsScanable(false)
                return
              }
            });
          })
        }
      } else {
        tempQty.current = mannuallyWeight ? mannuallyWeight : 1
      }
      // console.timeLog('addToCart', 'bbbbbbbbbbbbbbbbbbb')
  
      const onlinePrice = item.price? item.price : 0
      const onlineSpecials = item.specialPrices || []
      let specialPrice = onlinePrice
      let type = CartItemType.Normal
      let typeText = ''
      let specialValue = 0
      let specialInfo: SpecialPrice | undefined = undefined
      if (onlineSpecials.length > 0) {
        onlineSpecials.forEach((price: any) => {
          // if (onlineSpecials.length > 0) {
          //   onlineSpecials.forEach((price: any) => {
          // console.log('当前特价', price)
          // if (price.Live && nowIsWithinTimePeriod(price.StartDate, price.EndDate)) {
          if (price.Live && (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm === 1)) {
            specialInfo = price
            switch (price.Type) {
              case SpecialPriceType.SpecialPrice:
                specialPrice = price.SpecialPrice
                type = CartItemType.SpecialPrice
                specialValue = price.SpecialPrice
                typeText = "Special Price"
                break
              case SpecialPriceType.BuyNGetOneFree:
                type = CartItemType.BuyNGetOneFree
                specialValue = price.BuyNGetOneFree
                break
              case SpecialPriceType.Bonus:
                type = CartItemType.Bonus
                // specialValue = price.BuyNGetOneFree
                break
              case SpecialPriceType.ReductionPrice:
                specialPrice = specialPrice - price.ReductionPrice
                type = CartItemType.ReductionPrice
                specialValue = price.ReductionPrice
                typeText = "Reduction Price"
                break
              case SpecialPriceType.SpecialDiscount:
                specialPrice = specialPrice * (1 - price.SpecialDiscount / 100)
                type = CartItemType.SpecialDiscount
                specialValue = price.SpecialDiscount
                typeText = "Special Discount: " + price.SpecialDiscount!.toString() + "% Off"
                break
            }
          }
        })
      }
      // console.timeLog('addToCart', 'cccccccccccccccccccccccccc')
      const promotionText = type === CartItemType.Normal ? ""
        : type === CartItemType.BuyNGetOneFree ? "Promotion: Buy " + specialValue.toString() + " Get One Free"
          : `${typeText ? typeText : "Unknown Special"}/WAS $${item.includeGST && item.gstRate ? (item.price * (100 + item.gstRate) / 100).toFixed(2) : item.price.toFixed(2)}`
          // : `${typeText ? typeText : "Unknown Special"}/Original: $${item.includeGST && item.gstRate ? (item.price * (100 + item.gstRate) / 100).toFixed(2) : item.price.toFixed(2)}`
      // : `Special Price/Original: $${onlinePrice}`
      let newCartItem: CartItem = {
        itemId: itemId,
        productId: item.productCode,
        productInfo: item,
        // productInfo: newProduct[0],
        quantity: tempQty.current,//根据是否称重获得数量
        // quantity: 1,//根据是否称重获得数量
        unitPrice: item.includeGST && item.gstRate ? Number((specialPrice * (100 + item.gstRate) / 100).toFixed(2)) : specialPrice,
        // unitPrice: specialPrice,
        itemPrinted: false,
        total: Number(((item.includeGST && item.gstRate ? Number((specialPrice * (100 + item.gstRate) / 100).toFixed(2)) : specialPrice) * tempQty.current).toFixed(2)),
        applyGst: item.includeGST || false,
        allowDiscount: type === "normal" ? item.allowDiscount : false,
        // applyGst: onlineProduct.GSTStatus,
        // allowDiscount: type === "normal" ? onlineProduct.AllowDiscount : false,
        discountAmount: 0,
        discountRatio: 0,
        gstAmount: calculateGSTAmount(specialPrice, item.gstRate || 0),
        // gstRatio: onlineProduct.GSTRate,
        gstRatio: item.gstRate || 0,
        addedAt: new Date().toISOString(),
        type: type,
        promotionText: promotionText,
        specialInfo: specialInfo,
        originalPrice: item.includeGST && item.gstRate ? Number((specialPrice * (100 + item.gstRate) / 100).toFixed(2)) : specialPrice,
        // originalPrice: item.includeGST && item.gstRate ? Number((item.price * (100 + item.gstRate) / 100).toFixed(2)) : item.price,
        // originalPrice: Number(onlinePrice.toFixed(2))
        netPrice: Number(specialPrice.toFixed(2))
      }
      console.log('addItemToCart前--->', newCartItem)
      // console.timeLog('addToCart', 'ddddddddddddddddddddddd')
      dispatch(addItemToCart({ item: newCartItem }))
      // console.timeLog('addToCart', 'eeeeeeeeeeeeeeeeeeeee')
      dispatch(updateProduct({ product: item }))
      // console.timeLog('addToCart', 'ffffffffffffffffffff')
      // resolve("finish")
    // })
    // console.timeLog('addToCart', 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    // appendItemPromise()
    //   .then(() => {
      // playError()
        // console.timeLog('addToCart', 'yyyyyyyyyyyyyyyyyyyyyyyyyyyy')
        // reloadCustomerScreen()
        // Record product click event
        console.log("现在的查询点击标记是。。。", isSearch)
      //   isSearch && recordProductClick(item.productCode)
      // })
      // .catch((e) => console.log(e))
      // console.timeEnd('addToCart----------------')
  }

  const HandleBarcodeScan = async (data: string, type: number) => {
    // console.log("为什么isScan没改变？？？？", curIsScanable.current)
    isAlertModalVisible && setIsAlertModalVisible(false)
    if (curIsScanable.current && data) {
      // if (isScanable && data) {
      console.log("handle购物车产品barcode::", data)
      dispatch(showKeyboard({ isVisible: false }))//关闭键盘
      closeAllModal && closeAllModal()//关闭所有modal
      const result = await getProductByBarCode(data)
      if (result.data?.length > 0) {
        const item = productInDBToProduct(result.data)
        if (item.length > 1) {
          console.log('扫描到多个相同条码产品', item)
          // message.warn(`More than one items have same barcode. Please choose.`);
          //取消message提示，改为显示modal
          setCurMoreBarcodeProducts(item)
          setIsMoreBarcodeModal(true)
          playWait()//等待选择
          setIsScanable(false)
          setCurrentCategory(null)
          setItemDisplayType(DisplayDataType.Search)
          dispatch(setCheckoutSearchedValue({ value: data }))
          dispatch(loadCheckoutProductsByPage({ products: item, page: 1, total: result.total, type: DisplayDataType.Search }))
        }
        else appendItemTrigger(item[0], result.data[0])
        // else appendItemTrigger(item[0])
      } else {
        setErrorMsg(`Can not find product with this barcode ${data}.`)
        setIsAlertModalVisible(true)
        setSearchValue(data)//保留扫描到的条码待修改
        playError()//扫码无产品时提示报警音
      }
    } else {
      if (type === 0) {
        playError()
      }
      console.log("产品购物车不执行串口监听1 or no scan data")

    }
  }

  const scannerCallback = useCallback((event) => {
    // console.log('scannerCallback:', event)
    let data = buffer || ''
    if (event.key === '') {
      return
    }
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ''
      if (!isRefundMode) {
        HandleBarcodeScan(data, 1)//扫描枪，不触发警报
      }
    }
  }, [isRefundMode, itemsInCart])

  useEffect(() => {
    console.log("执行改变了。。", isScanable)
    curIsScanable.current = isScanable
  }, [isScanable])

  useEffect(() => {
    console.log("现在iswaitscalemodal是。。。", isWaitScaleModal)
    if (isWaitScaleModal) {
      //循环发送称重指令
      // setIntervalMessage(setInterval(async () => {
      const radom111 = randomString(15)
      IntervalMessage = setInterval(async () => {
        countInterval += 1
        console.log("称重判断循环开始", radom111, "循环次数：", countInterval)
        //@ts-ignore
        if (window?.electronAPI) {
          // await new Promise((resolve, reject) => {
          //@ts-ignore
          await window?.electronAPI?.requestWeight((response: any) => {
            console.log('称重返回得是否是重量？', response)
            if (response !== "failed" && Number(response) > 0) {
              console.log("//交给添加购物车函数正式称重")
              clearInterval(IntervalMessage)
              appendItemTrigger(curAppendItem.item, curAppendItem.itemDB)
              setIsWaitScaleModal(false)
              // resolve(true)
            } else if (countInterval > 30) {
              console.log("称重循环大于30秒次，退出")
              clearInterval(IntervalMessage)
              setIsWaitScaleModal(false)
            }
          });
          // })
        }
      }, 1000)
      // )
    } else {
      //取消循环
      setIsScanable(true)
      clearInterval(IntervalMessage)
      console.log("称重判断循环结束")
    }
  }, [isWaitScaleModal])

  useEffect(() => {
    if (Globals.barcode) {
      console.log("Magellan scanner get product barcode: ", Globals.barcode)
      HandleBarcodeScan(Globals.barcode, 0)//称重平台扫码，触发警报
      dispatch(freshBarcode({
        barcode: ""
      }))
    }
  }, [Globals.barcode])

  // useEffect(() => {
  //   if (Number(Globals.weight) > 0) {
  //     tempQty.current = Number(Globals.weight)
  //   }
  //   dispatch(freshWeight({
  //     weight: ""
  //   }))
  // }, [Globals.weight])

  useEffect(() => {
    //扫描枪信息捕捉
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [itemsInCart, isRefundMode])
  
  useEffect(() => {
    //当前产品页面显示内容保存redux
    console.log("当前itemDisplayType是---------", itemDisplayType)
    dispatch(setDisplayType({value: itemDisplayType}))
    // dispatch(setCheckoutSearchedValue({ value: data }))
  }, [itemDisplayType])

  useEffect(() => {
    //从其他页面跳转回来时，初始化产品列表显示条件
    if (displayType === DisplayDataType.Category) {
      setCurrentCategory(checkoutSelectedCategory)
    }
    if (displayType === DisplayDataType.Search) {
      setSearchValue(checkoutSeachedValue)
    }
  }, [])

  const onSearchProduct = (value: string) => {
    const result: Array<Product> = products.filter((product: Product) => {
      return (product.productCode.toLowerCase().includes(value.toLowerCase()) || product.name.toLowerCase().includes(value.toLowerCase())) ? true : false
    })
    setCurrentCategory(null)
    setItemDisplayType(DisplayDataType.Search)
    dispatch(searchProduct({ products: result }))
  }

  const onSearchProductOnline = async (value: string) => {
    // const result = await searchProductsByName(value)
    const result = await searchProductsByDescriptionByPage(value, 1)
    console.log("搜索products结果: ", result)
    setCurrentCategory(null)
    setItemDisplayType(DisplayDataType.Search)
    const searchedResults = productInDBToProduct(result.data)
    dispatch(setCheckoutSearchedValue({ value: value }))
    dispatch(loadCheckoutProductsByPage({ products: searchedResults, page: 1, total: result.total, type: DisplayDataType.Search }))
    // 准备下一页
    const resultNext = await searchProductsByDescriptionByPage(value, 2)
    const searchedResultsNext = productInDBToProduct(resultNext.data)
    dispatch(loadCheckoutProductsPrePage({ products: [] }))
    dispatch(loadCheckoutProductsNextPage({ products: searchedResultsNext }))
  }

  const onSelectProductCategory = async (item: any) => {
    setCurrentCategory(item)
    let result: any
    let searchedResults: any
    if (item.id !== "0") {
      if (item.id === "9999") {//mannually add
        result = await listProductsByMannuallyAdd(1)
        // console.log("9999", item, result)
      } else {
        result = await listProductsByCategoryByPage(item.id, 1)
      }
    } else {
      // Sort product by click rate
      result = await listProductsByMostUsed(1)
    }
    searchedResults = productInDBToProduct(result.data)
    setItemDisplayType(DisplayDataType.Category)
    dispatch(setCheckoutSelectedCategory({ category: item }))
    dispatch(loadCheckoutProductsByPage({ products: searchedResults, page: 1, total: result.total, type: DisplayDataType.Category }))
    dispatch(loadCheckoutProductsPrePage({ products: [] }))
    //准备下一页数据
    if (item.id !== "0") {
      if (item.id === "9999") {//mannually add
        result = await listProductsByMannuallyAdd(2)
      } else {
        result = await listProductsByCategoryByPage(item.id, 2)
      }
    } else {
      // Sort product by click rate
      result = await listProductsByMostUsed(2)
    }
    searchedResults = productInDBToProduct(result.data)
    dispatch(loadCheckoutProductsNextPage({ products: searchedResults }))
  }

  const onCardPress =async (item: Product) => {
    // console.time('addToCart')
    if (isRefundMode) {

    } else {
      if (isKeyboardVisible) {
        dispatch(showKeyboard({ isVisible: false }))
      }
      // console.timeLog('addToCart', 'ppp')
      await appendItemTrigger(item, undefined, true)//检索后单击图片的才需要记录点击数，不是扫描
    }
    // console.timeLog('addToCart', 'xxx')
    // console.timeEnd('addToCart')
  }

  const mannuallyAddWeight = (weight: number) => {
    clearInterval(IntervalMessage)
    appendItemTrigger(curAppendItem.item, curAppendItem.itemDB, false, weight)
    setIsWaitScaleModal(false)
  }

const clearCurrentCate = () => {//清除选中的category，显示全部产品或点击特殊大类时使用
  setCurrentCategory(null)
}

  return (
    <>
      {isAlertModalVisible ?
        <AlertModal
          isVisible={isAlertModalVisible}
          description={errorMsg}
          buttonText={"OK"}
          onCancel={() => setIsAlertModalVisible(false)}
          onConfirm={() => setIsAlertModalVisible(false)}
        />
        : null
      }
      {isWaitScaleModal ?
        <ProgressModal
          isVisible={isWaitScaleModal}
          description={`Please put ${curAppendItem ? curAppendItem.item.nameKorean ? curAppendItem.item.nameKorean : curAppendItem.item.name : "stuff"} on scale.`}
          closable={true}
          onCancel={() => {
            setIsScanable(true)
            clearInterval(IntervalMessage)
            setIsWaitScaleModal(false)
            console.log("设置为false")
          }}
          isScale={true}
          productImage={curAppendItem ? curAppendItem.item.image : undefined}

          onClick={(weight: number) => {
            mannuallyAddWeight(weight)
            setIsScanable(true)
          }}
          onOK={() => {
            setIsScanable(true)
          }}
        />
        : null
      }
      {isMoreBarcodeModal ?
        <MoreBarcodeModal
          title={"Products with same barcode"}
          isVisible={isMoreBarcodeModal}
          description={`Please select the product from the right panel first.`}
          closable={true}
          products={curMoreBarcodeProducts}
          onCancel={() => {
            setIsScanable(true)
            setIsMoreBarcodeModal(false)
            console.log("多条码产品topup设置为false")
          }}
          onConform={(item: Product) => {
            setIsScanable(true)
            setIsMoreBarcodeModal(false)
            appendItemTrigger(item, undefined, true)
          }}
        />
        : null
      }
      {isKeyboardVisible ?
        <KeyboardModal
          mask={false}
          isVisible={isKeyboardVisible}
          language={keyboardLanguage}
          onHideKeyboard={() => {
            dispatch(showKeyboard({ isVisible: false }))
          }}
          onSwitchLanguage={() => {
            dispatch(switchKeyboardLanguage({}))
          }}
          onConfirm={(value: string) => {
            // message.info("search clicked")
            setSearchValue(value)
            dispatch(showKeyboard({ isVisible: false }))
            onSearchProductOnline(value)
            // onSearchProduct(value)
            // console.log("value: ", value)
          }}
          onSelect={(value: string) => {
            console.log("点击键盘是", value)
            setSearchValue(value)
            onSearchProductOnline(value)
          }}
          initialValue={searchValue}
        />
        : null
      }

      <div style={{ display: "flex", alignItems: "center" }}>
        <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/product6-icon.png"} className="pageIcon" /></div>
        <div className="pageTitle">PRODUCTS</div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <SearchBar
            value={searchValue}
            onChange={(value: string) => { setSearchValue(value) }}
            onSearch={onSearchProductOnline}
            onClick={() => {
              // setSearchValue("")
              dispatch(showKeyboard({ isVisible: true }))
            }}
            // containerStyle={{ width: width * 0.4, marginBottom: 20 }}
            placeholderText={"Search By Product Code/Name"}
          />
        </div>
      </div>
      {
        itemDisplayType === DisplayDataType.Category ?
          productsByCategory.length > 0 ?
            <ProductPageList
              onCardPressed={onCardPress}
              data={productsByCategory}
              dataType={DisplayDataType.Category}
              setItemDisplayType={setItemDisplayType}
              clearCurrentCate={clearCurrentCate}
            />
            :
            <div className="emptyProduct">
              <Typography style={{ fontSize: 25, alignSelf: "center" }}>Sorry, we couldn't find products under this category</Typography>
            </div>
          :
          itemDisplayType === DisplayDataType.Search ?
            searchedProducts.length > 0 ?
              <ProductPageList
                onCardPressed={onCardPress}
                data={searchedProducts}
                dataType={DisplayDataType.Search}
                setItemDisplayType={setItemDisplayType}
                clearCurrentCate={clearCurrentCate}
              />
              :
              <div style={{ width: "100%", height: "77.5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography style={{ fontSize: 25, alignSelf: "center" }}>Sorry, we couldn't find searched products</Typography>
              </div>
            :
            <ProductPageList
              onCardPressed={onCardPress}
              data={checkoutProducts}
              dataType={DisplayDataType.All}
              setItemDisplayType={setItemDisplayType}
              clearCurrentCate={clearCurrentCate}
            />
      }
      <CategoryPageList
        onSelectProductCategory={onSelectProductCategory}
        categories={[...categories]}
        currentCatgory={currentCatgory}
      />
    </>
  )
}
