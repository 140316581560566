import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Credential, ScannnerFocusType, UserScreenType } from "../../models"
import { notification } from "antd"
import { retrieveLinklyData, storeLinklyData } from "../../utils/EFTPOS-functions"

const testAcc: Credential = {
  username: "38800605001",
  password: "DL57YCLWRA27FRNE"
}

const ktAcc: Credential = {
  username: "38900606001",
  password: "NT94TTWL0BPKMLV5"
}

interface ConfigState {
  paired: boolean
  linklyAcc: Credential
  paircode: string
  token: string
  tokenExpiry: number
  secret: string
  scannerFocus: ScannnerFocusType
  currentScreen: UserScreenType
}

const initialState = {
  paired: false,
  linklyAcc: ktAcc,
  paircode: "",
  token: "",
  tokenExpiry: 0,
  secret: "",
  scannerFocus: ScannnerFocusType.CustomerCode,
  currentScreen: UserScreenType.Checkout
} as ConfigState

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setPaired(state, action: PayloadAction<any>) {
      state.paired = action.payload.paired
    },
    setToken(state, action: PayloadAction<any>) {
      state.token = action.payload.token
      state.tokenExpiry = action.payload.tokenExpiry
    },
    setSecret(state, action: PayloadAction<any>) {
      state.secret = action.payload.secret
    },
    setScannerFocus(state, action: PayloadAction<any>) {
      state.scannerFocus = action.payload.scannerFocus
    },
    setCurrentScreen(state, action: PayloadAction<any>) {
      state.currentScreen = action.payload.currentScreen
    }
  }
})

export const {
  setPaired,
  setToken,
  setSecret,
  setScannerFocus,
  setCurrentScreen
} = configSlice.actions

export default configSlice.reducer
