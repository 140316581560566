import API from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

export const getWarehouseName = async (warehouseCode: string) => {
  try {
    const url = generateRequestUrl(API.queryWarehouseList)
    // const config = {
    //   params: {
    //     // "pageSize": pageSize,
    //     "WarehouseCode": warehouseCode
    //   }
    // }
    const response = await axios.get(url + `/${warehouseCode}`)
    console.log("warehouse is ", response)
    return response.data.WarehouseName
  } catch (e) {
    console.log(e)
    notification.error({
      message: "getWarehouseName failed !"
    })
    return e
  }
}
export const listWarehouses = async () => {
  try {
    const url = generateRequestUrl(API.queryWarehouseList)
    const response = await axios.get(url)
    console.log("warehouse list are ", response.data)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "get Warehouse list failed !"
    })
    return e
  }
}
