import React, { useState } from "react"
import { Button, Layout, Typography } from "antd"
import { StyleSheet, Total } from "../models"
import useViewport from "../hooks/useViewport"
import { useNavigate } from "react-router-dom"
import TotalSection from "../components/Checkout/TotalSection"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import CheckoutCart from "../components/Checkout/CheckoutCart"
import CheckoutList from "../components/Checkout/CheckoutList"
import PaymentSection from "../components/Checkout/Payment/PaymentSection"
import CustomerInfoSection from "../components/Checkout/Customer/CustomerInfoSection"
import CashModal from "../components/Checkout/Payment/CashModal"
import CustomerScanRedeemSection from "../components/Checkout/Customer/CustomerScanRedeemSection"
import { setShowReceiptModal } from "../redux/Payment/slice"
import { useEffect } from "react"
import RefundPaymentSection from "../components/Refund/RefundPaymentSection"
import RefundTotal from "../components/Refund/RefundTotal"
import RefundList from "../components/Refund/RefundList"

const { Content } = Layout

export default function PaymentPage () {
  const { width, height } = useViewport()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, isRefundMode, refundTotal } = Checkout
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { showReceiptModal } = Payment

  // useEffect(() => {
  //   dispatch(setShowReceiptModal({ isVisible: false }))
  // }, [])

  return (
    <Layout>
      <div style={{...styles.container }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: width * 0.5, marginRight: 30, marginLeft: 20, justifyContent: "flex-start", display: "flex", flexDirection: "column" }}>
            <div style={{ height: height * 0.3, marginBottom: 50 }}>
              <Typography.Title level={3} style={{ marginBottom: 20, marginTop: 20 }}>Customer Info</Typography.Title>
              <CustomerScanRedeemSection />
            </div>
            <div style={{ height: height * 0.5 }}>
              <Typography.Title level={3} style={{ marginBottom: 20, marginTop: 20 }}>Payment</Typography.Title>
              {
                isRefundMode ? 
                <RefundPaymentSection />
                :
                <PaymentSection />
              }
            </div>
          </div>
          
          <div style={{ width: width * 0.4, alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div style={{ height: height * 0.5 }}>
              {
                isRefundMode ?
                <>
                <Typography.Title level={3} style={{ marginBottom: 20, marginTop: 20 }}>Refund Items</Typography.Title>
                <RefundList tableHeightFactor={0.35} tableWidthFactor={0.4} />
                </>
                :
                <>
                <Typography.Title level={3} style={{ marginBottom: 20, marginTop: 20 }}>Items in Cart</Typography.Title>
                <CheckoutList tableHeightFactor={0.35} tableWidthFactor={0.4} />
                </>
              }
            </div>
            <div style={{ justifyContent: "flex-end", display: "flex", flexDirection: "column" }}>
              {
                isRefundMode ?
                <RefundTotal item={refundTotal} containerStyle={{ backgroundColor: "white", width: width * 0.4, padding: 10, marginBottom: 20, height: height * 0.25 }}/>
                :
                <TotalSection item={total} containerStyle={{ backgroundColor: "white", width: width * 0.4, padding: 10, marginBottom: 20 }}/>
              }
              <Button type="primary" style={{ width: width * 0.4, height: 80, fontSize: 20 }} onClick={() => { navigate("/checkout") }}>Go Back to Checkout</Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}


const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "white"
  }
}