import React, { useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Button, Divider, notification, Image } from "antd"
import { CreditCardFilled, DollarCircleFilled } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"
import useViewport from "../../hooks/useViewport"
import { primary } from "../../constants/Colors"
import ProgressModal from "../common/ProgressModal" 
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { 
  setCurrentInvoice, 
  setPaymentTotal, 
  setShowReceiptModal, 
  setPaymentType, 
  setSplitPaymentMethod1, 
  setSplitPaymentMethod2, 
  setSplitPaymentAmount1, 
  setSplitPaymentAmount2,
  setPaymentTotal1,
  setPaymentTotal2,
  setPaymentStep
} from "../../redux/Payment/slice"
import { calculatePaymentRounding, cartItemsToInvoiceItems, randomInteger, cartItemsToProductCodeOnlyItems, transferPaymentMethod } from "../../utils/Global"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { InvoiceHead, RecvAcct, Total } from "../../models"
import { ExampleInvoiceInDB } from "../../constants/data"
import { postInvoice } from "../../api/invoice"
import { addInvoice } from "../../redux/Customer/slice"
import AlertModal from "../common/AlertModal"

const logo_royalpay = require("../../assets/png/logo_royalpay.png")

interface Props {
  sectionHeightFactor?: number
  sectionWidthFactor?: number
}


export default function RefundPaymentSection({ sectionHeightFactor, sectionWidthFactor }: Props) {
  const { height, width } = useViewport()
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, currentInvoice, showReceiptModal, paymentType, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2 } = Payment
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart, isRefundMode } = Checkout
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer

  const [selectedMethod, setSelectedMethod] = useState<"cash" | "card" | "royalpay">("cash")
  // const [paymentType, setPaymentType] = useState<"oneOff" | "split">("oneOff")
  // const [splitOneMethod, setSplitOneMethod] = useState<"cash" | "card" | "royalpay">("cash")
  // const [splitTwoMethod, setSplitTwoMethod] = useState<"cash" | "card" | "royalpay">("cash")
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [isCashModalVisible, setIsCashModalVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isRoyalPayModalVisible, setIsRoyalPayModalVisible] = useState<boolean>(false)
  const [splitOneAmount, setSplitOneAmount] = useState(0)
  const [splitTwoAmount, setSplitTwoAmount] = useState(0)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isCardPaymentModalVisible, setIsCardPaymentModalVisible] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
   

  const handleCashPayment = (amount: number, type: string) => {
    const roundingResult = calculatePaymentRounding(amount)
    const newPaymentTotal = {
      ...total,
      depositAmount: 0,
      receivableAmount: Number(roundingResult.payable),
      roundingAmount: Number(roundingResult.rounding),
      surchargeAmount: 0,
      changeAmount: 0
    }
    if (type === "oneOff") {
      dispatch(setPaymentStep({ paymentStep: "oneOff" }))
      dispatch(setPaymentTotal({ paymentTotal: newPaymentTotal }))
    } else if (type === "split1") {
      dispatch(setPaymentStep({ paymentStep: "split1" }))
      dispatch(setPaymentTotal1({ paymentTotal1: newPaymentTotal }))
    } else if (type === "split2") {
      dispatch(setPaymentStep({ paymentStep: "split2" }))
      dispatch(setPaymentTotal2({ paymentTotal2: newPaymentTotal }))
    }
    setIsCashModalVisible(true)
  }

  const handleCardPayment = async (amount: number, type: string) => {
    // await postInvoiceToBackend("EFTPOS", total.totalAmount)
    // dispatch(setShowReceiptModal({ isVisible: true }))
    setIsCardPaymentModalVisible(true)
  }

  const handleRoyalPay = (amount: number, type: string) => {
    setIsRoyalPayModalVisible(true)
  }

  return (
    <div 
      style={{ 
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "flex-start", 
        backgroundColor: "white", 
        flexDirection: "column", 
        zIndex: 100, 
        padding: 18, 
        height: sectionHeightFactor ? height * sectionHeightFactor : height * 0.45
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", alignSelf: "center", zIndex: 100 }}>
        <Typography style={{ fontWeight: "bold", fontSize: 18 }}>Please Select Refund Method: </Typography>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 50, alignSelf: "center" }}>
          <div 
            onClick={() => { 
              setIsConfirmModalVisible(true)
            }} 
            style={{ display: "flex", flexDirection: "column" }}
          >
            <DollarCircleFilled style={{ fontSize: 80, color: primary }}/>
            <Typography style={{ fontSize: 30, color: primary }}>Cash</Typography>
          </div>
          <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }}/>
          <div 
            onClick={async () => { 
              setIsConfirmModalVisible(true)
            }} 
            style={{ display: "flex", flexDirection: "column" }}
          >
            <CreditCardFilled style={{ fontSize: 80, color: primary }}/>
            <Typography style={{ fontSize: 30, color: primary }}>Credit Card</Typography>
          </div>
          <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }}/>
          <div 
            onClick={() => { 
              setIsConfirmModalVisible(true)
            }} 
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Image
              src={logo_royalpay}
              width={150}
              preview={false}
            />
          </div>
        </div>
      </div>
      <ProgressModal isVisible={isInProgress} description={"Transaction In Progress"} />
      <AlertModal 
        isVisible={isAlertModalVisible} 
        description={errorMsg} 
        buttonText={"OK"} 
        onCancel={() => setIsAlertModalVisible(false)} 
        onConfirm={() => setIsAlertModalVisible(false)}      
      />
      <ActionConfirmModal 
        isVisible={isConfirmModalVisible} 
        description={"Please wait for manager to confirm"} 
        actionText={"Manager Approve"} 
        cancelText={"Send Notification"} 
        onConfirm={() => {
          setIsConfirmModalVisible(false)
          // setIsRefunding(false)
          // onPostRefund()
        }} 
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }} 
        // rightButtonDanger={true}
      />
    </div>
  )
}
