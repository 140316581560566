import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CartItem, Total, Customer, InvoiceHead } from "../../models"
import { ExampleInvoiceInDB, ExampleInvoices, ExampleCustomers } from "../../constants/data"

interface InvoiceState {
  searchedInvoices: Array<InvoiceHead>
  selectedInvoice: InvoiceHead | null
  allInvoices: Array<InvoiceHead>
  allRefunds: Array<InvoiceHead>
  selectedRefund: InvoiceHead | null
  searchedRefunds: Array<InvoiceHead>
  isKeyboardVisible: boolean
  keyboardLanguage: string
  editable: any
  nextToken: string | null
  totalInvoiceNo: number
}

const initialState = {
  searchedInvoices: [],
  allInvoices: [],
  allRefunds: [],
  selectedRefund: null,
  selectedInvoice: null,
  isKeyboardVisible: false,
  editable: null,
  nextToken: null,
  keyboardLanguage: "eng",
  totalInvoiceNo: 0,
  searchedRefunds: []
} as InvoiceState

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    loadAllInvoices(state, action: PayloadAction<any>) {
      state.allInvoices = action.payload.invoices
      state.nextToken = action.payload.nextToken
    },
    loadAllRefunds(state, action: PayloadAction<any>) {
      state.allRefunds = action.payload.refunds
      state.nextToken = action.payload.nextToken
    },
    addInvoice(state, action: PayloadAction<any>) {
      // state.allInvoices.push(action.payload.invoice)
      state.allInvoices.unshift(action.payload.invoice)
    },
    selectInvoice(state, action: PayloadAction<any>) {
      state.selectedInvoice = action.payload.invoice
    },
    searchInvoice(state, action: PayloadAction<any>) {
      state.searchedInvoices = action.payload.invoices
    },
    showKeyboard(state, action: PayloadAction<any>) {
      state.isKeyboardVisible = action.payload.isVisible
    },
    switchKeyboardLanguage(state, action: PayloadAction<any>) {
      if (state.keyboardLanguage === "eng") {
        state.keyboardLanguage = "kor"
      } else {
        state.keyboardLanguage = "eng"
      }
    },
    searchRefund(state, action: PayloadAction<any>) {
      console.log("action: ", action.payload.refunds)
      state.searchedRefunds = action.payload.refunds
    },
  }
})

export const {
  selectInvoice,
  loadAllInvoices,
  addInvoice,
  showKeyboard,
  switchKeyboardLanguage,
  searchInvoice,
  loadAllRefunds,
  searchRefund
} = invoiceSlice.actions

export default invoiceSlice.reducer
