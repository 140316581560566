import { Button, Drawer, Input, message } from "antd"
import React, { useRef, useState, useEffect, useCallback, useImperativeHandle, Ref } from "react"
import KoreanKeyboard from "react-hangul-virtual-keyboard"
import Keyboard from "react-simple-keyboard"
import Hangul from "hangul-js"

import "./Keyboard.css"

export const getKoreanLayout = () => {
  return {
    default: [
      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
      "{tab} ㅂ ㅈ ㄷ ㄱ ㅅ ㅛ ㅕ ㅑ ㅐ ㅔ [ ] \\",
      "{lock} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ ; ' {enter}",
      "{shift} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ , . / {shiftright}",
      ".com @ {space} {language} {clear}",
    ],
    shift: [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
      "{tab} ㅃ ㅉ ㄸ ㄲ ㅆ ㅛ ㅕ ㅑ ㅒ ㅖ { } |",
      '{lock} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ : " {enter}',
      "{shift} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ < > ? {shiftright}",
      ".com @ {space} {language} {clear}",
    ],
  }
}

export const getEnglishLayout = () => {
  return {
    default: [
      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
      "{tab} q w e r t y u i o p [ ] \\",
      "{lock} a s d f g h j k l ; ' {enter}",
      "{shift} z x c v b n m , . / {shiftright}",
      ".com @ {space} {language} {clear}",
    ],
    shift: [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
      "{tab} Q W E R T Y U I O P { } |",
      '{lock} A S D F G H J K L : " {enter}',
      "{shift} Z X C V B N M < > ? {shiftright}",
      ".com @ {space} {language} {clear}",
    ],
  }
}

export const getNumpadLayout = () => {
  return {
    default: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      "{language} 0 {bksp}",
      "{clear} {enter}",
    ],
    shift: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      "{language} 0 {bksp}",
      "{clear} {enter}",
    ],
    // default: [
    //   " 0  {language}",
    //   "1 2 3 {bksp}",
    //   "4 5 6 {enter}",
    //   "7 8 9 {clear}",
    // ]
  }
}

interface KeyboardProps {
  isVisible: boolean
  language?: string
  initialValue?: string
  confirmOnEnter?: boolean
  onHideKeyboard: () => void
  onSwitchLanguage?: (e: any) => void
  onConfirm: (value: string) => void
  onSelect?: (value: string) => void
  onEnter?: (value: string) => void
  containerStyle?: React.CSSProperties
  inputRef?: React.RefObject<HTMLInputElement>
  mask?: boolean
}

let buttonArray: string[] = []
let inputText = ""

const customizedButtonTheme = [
  {
    class: "space-wide",
    buttons: "{space}",
  },
  {
    class: "gray",
    buttons: "@ .com",
  },
  {
    class: "align-left",
    buttons: "{tab} {lock} {shift} .com",
  },
  {
    class: "align-right",
    buttons: "{enter} {shiftright} {clear}",
  },
]

const numpadButtonTheme = [
  {
    class: "hg-standardBtn",
    buttons: "{language} {bksp}",
  }
]

const KeyboardModal = ({
  isVisible,
  language: propsLanguage,
  initialValue,
  onSwitchLanguage,
  onHideKeyboard,
  onEnter,
  /** By default, will trigger onConfirm when pressing enter key */
  confirmOnEnter = true,
  onConfirm,
  onSelect,
  containerStyle,
  inputRef,
  mask = true
}: KeyboardProps, ref: Ref<any>) => {
  const [input, setInput] = useState<string>(initialValue ?? "")
  console.log("initialValue是============", initialValue, 'input========', input, "buttonArray ====", buttonArray)
  const keyboardRef = useRef<any>(null)

  const [keyboardLanguage, setKeyboardLanguage] = useState<string>(
    propsLanguage ?? "eng"
  )

  const [layoutName, setLayoutName] = useState<"default" | "shift">("default")

  const language: "kor" | "eng" | "num" | string = keyboardLanguage
    ? keyboardLanguage
    : propsLanguage ?
      propsLanguage : "eng"

  const defaultSwitchLanguage = useCallback(() => {
    switch (language) {
      case "eng":
        if (onSwitchLanguage) {
          onSwitchLanguage("num")
        }
        setKeyboardLanguage("num")
        break;
      case "num":
        if (onSwitchLanguage) {
          onSwitchLanguage("kor")
        }
        setKeyboardLanguage("kor")
        break;
      case "kor":
        if (onSwitchLanguage) {
          onSwitchLanguage("eng")
        }
        setKeyboardLanguage("eng")
        break;

      default:
        if (onSwitchLanguage) {
          onSwitchLanguage("eng")
        }
        setKeyboardLanguage("eng")
        break;
    }
    // if (language === "eng") {
    //   if (onSwitchLanguage) {
    //     onSwitchLanguage("num")
    //   }
    //   setKeyboardLanguage("num")
    // } else {
    //   if (onSwitchLanguage) {
    //     onSwitchLanguage("eng")
    //   }
    //   setKeyboardLanguage("eng")
    // }
  }, [language, onSwitchLanguage])

  const handleShiftButton = () => {
    const shiftToggle = layoutName === "default" ? "shift" : "default"
    setLayoutName(shiftToggle)
  }

  const inputRefInternal = useRef<any>()

  useImperativeHandle(
    ref,
    () => ({
      clear: () => {
        // Clear the local states
        keyboardRef?.current?.clearInput()
        setInput("")
        inputText = ""
        buttonArray = []
      },
      setInput: (dom: HTMLInputElement) => {
        inputRefInternal.current = dom
      }
    }),
    [],
  )

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.value) {
      inputRef.current.value = input
    }
    if (keyboardRef?.current) {
      // Initialize the keyboard's internal input
      keyboardRef?.current?.setInput(input)
    }
    if (buttonArray.length === 0) {
      buttonArray = Hangul.disassemble(input)
      // buttonArray = input.split('')
    }
  }, [input, inputRef])

  useEffect(() => {
    if (isVisible && !initialValue) {
      buttonArray = []
    } else if (isVisible && initialValue && !input) {
      setInput(initialValue)
      buttonArray = Hangul.disassemble(initialValue)
    }
  }, [isVisible])

  const onClear = () => {
    if (keyboardRef.current) {
      // console.log("ref: ", keyboardRef.current)
      // @ts-ignore
      keyboardRef?.current?.clearInput()
      setInput("")
      onSelect && onSelect("")
      inputText = ""
      buttonArray = []
    }
  }

  const onClose = () => {
    if (input || input === "") {
      onConfirm(input)
    } else {
      onHideKeyboard()
    }
    keyboardRef?.current?.clearInput()
    setInput("")
    inputText = ""
    if (!initialValue) buttonArray = []
  }

  return (
    <Drawer
      closable={false}
      mask={mask}
      placement="bottom"
      visible={isVisible}
      maskStyle={{
        opacity: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
      }} //遮罩透明
      onClose={onClose}
      maskClosable
      headerStyle={{
        padding: 8,
      }}
      height="385px"
      keyboard={true}
      bodyStyle={keyboardLanguage === "num" ? 
      {
        paddingLeft: 400,
        paddingRight: 400,
        paddingTop: 0,
        paddingBottom: 0
      } 
      :
      {
        padding: 0,
      }
    }
    >
      <div style={containerStyle ? containerStyle : { marginBottom: 5 }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <button
            style={{ width: 100, height: 40, marginLeft: 5, border: '1px solid #CDD1D7', borderRadius: 5, backgroundColor: "#CDD1D7" }}
            onClick={onClose}
          >
            Close
          </button>
          <Input
            bordered
            addonBefore="Value:"
            value={input}
            size="large"
            style={{
              margin: 10,
              fontSize: 20,
            }}
          />
          {/* <button
            style={{ width: 100, height: 40, marginLeft: 5, border: '1px solid #CDD1D7', borderRadius: 5, backgroundColor: "#CDD1D7" }}
            onClick={() => setKeyboardLanguage("num")}
          >
            Numpad
          </button> */}
        </div>
        <div>
          <Keyboard
            theme="hg-theme-default ktmart-theme"
            onChange={(value: string) => {
              // setInput(value)
              console.log('onchange value', value, 'inputText', inputText)
              setInput(inputText)
              console.log("keyboard onchange---->")
              if (inputRefInternal && inputRefInternal.current) {
                inputRefInternal.current.value = inputText
              } else if (inputRef && inputRef.current) {
                inputRef.current.value = inputText
              } else {
                onSelect && onSelect(inputText)
              }
            }}
            layout={language === "kor" ? getKoreanLayout() : language === "num" ? getNumpadLayout() : getEnglishLayout()}
            onKeyPress={(button: any) => {
              // console.log("button: ", button)
              console.log("Button pressed", button)
              if (
                ![
                  "{shift}",
                  "{shiftright}",
                  "{lock}",
                  "{language}",
                  "{enter}",
                  "{bksp}",
                  "{space}",
                  "{tab}",
                  "{clear}",
                ].includes(button)
              ) {
                buttonArray.push(button)
              }
              if (button === "{bksp}") {
                console.log("buttonArray", buttonArray)
                buttonArray.pop()
              }
              if (button === "{space}") {
                buttonArray.push(" ")
              }
              if (button === "{tab}") {
                buttonArray.push("  ")
              }

              inputText = Hangul.assemble(buttonArray)
              console.log("inputText", inputText)

              if (button === "{enter}") {
                onEnter && onEnter(inputText)
                confirmOnEnter && onConfirm && onConfirm(inputText)
                // keyboardRef?.current?.clearInput()
                // setInput("")
                // inputText = ""
                if (!initialValue) buttonArray = []
              }

              /**
               * Shift functionality
               */
              if (
                button === "{shift}" ||
                button === "{shiftright}" ||
                button === "{lock}"
              )
                handleShiftButton()
              if (button === "{language}") {
                console.log("语言切换", language)
                defaultSwitchLanguage()
                // onSwitchLanguage
                //   ? onSwitchLanguage(language === "kor" ? "eng" : language === "eng" ? "num" : "kor")
                //   : defaultSwitchLanguage()
              }

              if (button === "{clear}") {
                onClear()
              }
            }}
            baseClass={"keyboard"}
            keyboardRef={(r: any) => (keyboardRef.current = r)}
            layoutName={layoutName}
            mergeDisplay
            // language={language === "kor" ? "default" : "english"}
            display={language === "num" ? {
              '{language}': 'switch',
              "{bksp}": "⌫",
              "{clear}": "clear",
              "{enter}": "search",
            } : {
              "{enter}": "search",
              "{language}": "switch language",
              "{clear}": "clear",
            }}
            buttonTheme={keyboardLanguage !== "num" ? customizedButtonTheme : numpadButtonTheme}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default React.forwardRef(KeyboardModal)
