import React from "react"
import { ConfigProvider } from "antd"
import enUS from "antd/lib/locale/en_US"
import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom"
import HomePage from "./pages/HomePage"
import LoginPage from "./pages/LoginPage"
import CustomerScreen from "./pages/CustomerScreen"
import 'antd/dist/antd.css'
import reduxConfigure from "./redux/store"
import { Provider } from "react-redux"
import ViewportProvider from "./components/Provider/ViewportProvider"
import { PersistGate } from "redux-persist/integration/react"
import ErrorBoundary from "./components/Setting/Errorboundary"
import { iniConfiguration } from "./utils/configurate";
// import configureStore from "electron/redux/store"

function App() {
  const { store, persistor } = reduxConfigure()
  iniConfiguration()
  // let isSecondScreen = false
  //@ts-ignore
  // const { store, persistor } = configureStore("renderer")
  //是否是副屏显示，是则跳转
  //@ts-ignore
  // if (window?.electronAPI) {
  //   //@ts-ignore
  //   window?.electronAPI?.getMainOrSecond((response: any) => {
  //     console.log("App主副屏", response)
  //     isSecondScreen = response === 2
  //   });
  // }
  return (
    <ConfigProvider locale={enUS}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ViewportProvider>
            <ErrorBoundary>
              <HashRouter>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  {/* <Route path="/customerscreen" element={<CustomerScreen />} /> */}
                  <Route path="/*" element={<HomePage />} />
                  {/* <Route path="/*" element={isSecondScreen? <CustomerScreen /> : <HomePage />} /> */}
                </Routes>
              </HashRouter>
            </ErrorBoundary>
          </ViewportProvider>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  )
}

export default App