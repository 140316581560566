import React, { useEffect } from "react"
import { Form, Button, Input, Typography, Select } from "antd"
import useViewport from "../../hooks/useViewport"
import { getConfiguration, saveConfiguration } from "../../utils/configurate"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Warehouse } from "../../models"
// import {getConfiguration, saveConfiguration} from "../../utils/configurate"

const MachineIDTab = () => {
  const [form] = Form.useForm()
  const { width } = useViewport()
  const PosConfig = getConfiguration("MachineIDTab")
  const { machineID, warehouseCode, serverAddress, port, abn } = PosConfig
  const Product = useSelector((state: RootStateOrAny) => state.Product)
  const { warehouses } = Product
  console.log("仓库列表", warehouses)

  const getStoreEnum = () => {    //得到store的枚举json作为查询条件select的options
    const Enum: any[] = []
    warehouses.forEach((store: Warehouse) => {
      Enum.push({ label: store.WarehouseName, value: store.WarehouseCode })
    })
    return Enum
  }

  useEffect(() => {
    form.setFieldsValue({
      machineID,
      warehouseCode,
      serverAddress,
      port,
      abn
    })
  }, [])
  const onFinish = async (values: any) => {
    //获得仓库名称和地址
    const curWarehouse = warehouses.filter((store: Warehouse) => store.WarehouseCode === values.warehouseCode)
    const mergeValues = {
      ...values,
      warehouseName: curWarehouse[0].WarehouseName || "",
      warehouseAddress: curWarehouse[0].Address || "",
      warehousePhone: curWarehouse[0].Telephone || "",
    }
    saveConfiguration(mergeValues)
    // alert('This function just run in electron env.')
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
      <Typography style={{ marginBottom: 20, color: "grey" }}>Enter machine details for this POS</Typography>
      <Form
        // labelCol={{ span: 100 }}
        wrapperCol={{ span: 100 }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        style={{ width: "50%" }}
      >
        <Form.Item
          label="Machine ID"
          name="machineID"
          rules={[{ required: true, message: "Enter Machine ID for this POS" }]}
        >
          <Input style={{ backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px" }} />
        </Form.Item>

        <Form.Item
          label="Warehouse Code"
          name="warehouseCode"
          rules={[{ required: true, message: "Enter Warehouse Code for this POS" }]}
        >
          <Select
            style={{ backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px" }}
            options={getStoreEnum()}
          />
          {/* <Input style={{backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px"}}/> */}
        </Form.Item>

        <Form.Item
          label="ABN"
          name="abn"
          rules={[{ required: true, message: "Enter ABN of this store" }]}
        >
          <Input style={{ backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px" }} />
        </Form.Item>
        <Form.Item
          label="Server Address"
          name="serverAddress"
          rules={[{ required: true, message: "Enter Server Address for this POS" }]}
        >
          <Input style={{ backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px" }} />
        </Form.Item>

        <Form.Item
          label="Port Code"
          name="port"
          rules={[{ required: false, message: "Enter pair code from terminal screen" }]}
        >
          <Input style={{ backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px" }} />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit" style={{ width: "500px", height: "50px", borderRadius: "4px", border: "none" }}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default MachineIDTab
