import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CartItem, Total, Product, Category, SpecialPrice, Warehouse } from "../../models"
import { ExampleProducts, ExampleCategories } from "../../constants/data"
import { DisplayDataType } from "../../constants/Enums"

interface ProductState {
  products: Array<Product>
  searchedProducts: Array<Product>
  productsByCategory: Array<Product>
  selectedProduct: Product | null
  categories: Array<Category>
  selectedCategory: Category | null
  isKeyboardVisible: boolean
  keyboardLanguage: "eng" | "kor"
  categoryTotal: number
  productTotal: number
  currentProductPage: number
  // For Checkout Products
  productsNextPage: Array<Product>
  productsPrePage: Array<Product>
  checkoutProducts: Array<Product>
  checkoutProductTotal: number
  checkoutProductCurrentPage: number
  checkoutSelectedCategory: Category | null
  checkoutSeachedValue: string | null
  warehouses: Array<Warehouse>
  displayType: any
  // displayType: DisplayDataType.All | DisplayDataType.Category | DisplayDataType.Search
}

const initialState = {
  products: ExampleProducts,
  checkoutProducts: [],
  searchedProducts: [],
  productsByCategory: [],
  productsPrePage: [],
  productsNextPage: [],
  selectedProduct: null,
  categories: [],
  // categories: ExampleCategories,
  allCategories: ExampleCategories,
  selectedCategory: null,
  isKeyboardVisible: false,
  keyboardLanguage: "eng",
  categoryTotal: 0,
  productTotal: 0,
  currentProductPage: 1,
  checkoutProductTotal: 0,
  checkoutProductCurrentPage: 1,
  checkoutSelectedCategory: null,
  checkoutSeachedValue: null,
  warehouses: [],
  displayType: 'All'
} as ProductState

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    loadProducts(state, action: PayloadAction<any>) {
      state.products = action.payload.products
    },
    appendProducts(state, action: PayloadAction<any>) {
      state.products.push(...action.payload.products)
    },
    selectProduct(state, action: PayloadAction<any>) {
      state.selectedProduct = action.payload.product
    },
    searchProduct(state, action: PayloadAction<any>) {
      state.searchedProducts = action.payload.products
    },
    updateProduct(state, action: PayloadAction<any>) {
      const editItem = action.payload.product
      state.products = state.products.map((item) =>
        item.productCode === editItem.productCode
          ? editItem
          : item
      )
    },
    loadCategories(state, action: PayloadAction<any>) {
      state.categories = action.payload.categories
    },
    selectCategory(state, action: PayloadAction<any>) {
      state.selectedCategory = action.payload.category
      state.searchedProducts = []
      state.productsByCategory = state.products.filter((item: Product) => { return item.categoryCode === action.payload.category.categoryCode })
    },
    loadProductsByCategory(state, action: PayloadAction<any>) {
      state.selectedCategory = action.payload.category
      state.searchedProducts = []
      state.productsByCategory = action.payload.products
    },
    showAllProducts(state, action: PayloadAction<any>) {
      state.selectedCategory = null
      state.selectedProduct = null
      state.productsByCategory = []
      state.searchedProducts = []
      state.products = state.products.sort((product1, product2) => { return product2.hits - product1.hits })
    },
    showKeyboard(state, action: PayloadAction<any>) {
      state.isKeyboardVisible = action.payload.isVisible
    },
    switchKeyboardLanguage(state, action: PayloadAction<any>) {
      if (state.keyboardLanguage === "eng") {
        state.keyboardLanguage = "kor"
      } else {
        state.keyboardLanguage = "eng"
      }
    },
    loadProductsByPage(state, action: PayloadAction<any>) {
      state.products = [...state.products, ...action.payload.products]
      state.currentProductPage = state.currentProductPage + 1
    },
    loadCheckoutProductsNextPage(state, action: PayloadAction<any>) {
      state.productsNextPage = [...action.payload.products]
    },
    loadCheckoutProductsPrePage(state, action: PayloadAction<any>) {
      state.productsPrePage = [...action.payload.products]
    },
    loadNextPage(state, action: PayloadAction<any>) {
      switch (action.payload.type) {
        case DisplayDataType.All:
          state.productsPrePage = [...state.checkoutProducts]
          state.checkoutProducts = [...state.productsNextPage]
          
          break
        case DisplayDataType.Category:
          state.productsPrePage = [...state.productsByCategory]
          state.productsByCategory = [...state.productsNextPage]
          
          break
        case DisplayDataType.Search:
          state.productsPrePage = [...state.searchedProducts]
          state.searchedProducts = [...state.productsNextPage]
          
      }
      state.checkoutProductCurrentPage = state.checkoutProductCurrentPage + 1
    },
    loadPrePage(state, action: PayloadAction<any>) {
      switch (action.payload.type) {
        case DisplayDataType.All:
          state.productsNextPage = [...state.checkoutProducts]
          state.checkoutProducts = [...state.productsPrePage]
          break
        case DisplayDataType.Category:
          state.productsNextPage = [...state.productsByCategory]
          state.productsByCategory = [...state.productsPrePage]
          break
        case DisplayDataType.Search:
          state.productsNextPage = [...state.searchedProducts]
          state.searchedProducts = [...state.productsPrePage]
      }
      state.checkoutProductCurrentPage = state.checkoutProductCurrentPage - 1
    },

    loadCheckoutProductsByPage(state, action: PayloadAction<{ products: Array<Product>, page: number, total: number, type: DisplayDataType }>) {
      switch (action.payload.type) {
        case DisplayDataType.All:
          // console.log("显示全部数据的前16", action.payload.products)
          state.checkoutProducts = action.payload.products
          break
        case DisplayDataType.Category:
          state.productsByCategory = action.payload.products
          break
        case DisplayDataType.Search:
          state.searchedProducts = action.payload.products
      }
      state.checkoutProductCurrentPage = action.payload.page
      state.checkoutProductTotal = action.payload.total
    },
    setCheckoutSelectedCategory(state, action: PayloadAction<{ category: Category }>) {
      state.checkoutSelectedCategory = action.payload.category
    },
    setCheckoutSearchedValue(state, action: PayloadAction<{ value: string }>) {
      state.checkoutSeachedValue = action.payload.value
    },
    setDisplayType(state, action: PayloadAction<{ value: any }>) {
      state.displayType = action.payload.value
    },
    loadWarehouses(state, action: PayloadAction<any>) {
      state.warehouses = action.payload.warehouses
    },
  }
})

export const {
  loadProducts,
  appendProducts,
  selectProduct,
  searchProduct,
  selectCategory,
  loadCategories,
  showAllProducts,
  showKeyboard,
  switchKeyboardLanguage,
  updateProduct,
  loadProductsByPage,
  loadProductsByCategory,
  loadCheckoutProductsByPage,
  setCheckoutSearchedValue,
  setCheckoutSelectedCategory,
  loadWarehouses,
  loadCheckoutProductsNextPage,
  loadCheckoutProductsPrePage,
  loadPrePage,
  loadNextPage,
  setDisplayType
} = productSlice.actions

export default productSlice.reducer
