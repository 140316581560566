import { configureStore, createStore } from "@reduxjs/toolkit"
import { combineReducers, compose, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import reducers from "./reducer"
// import { forwardToMain, replayActionRenderer, getInitialStateRenderer } from "electron-redux"

// const store = createStore(
//   todoApp,
//   initialState, // optional
//   applyMiddleware(
//     triggerAlias, // optional, see below
//     ...otherMiddleware,
//     forwardToRenderer, // IMPORTANT! This goes last
//   ),
// );

// replayActionMain(store);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["Order", "User"],
  // whitelist:["User"]
}

const rootReducer = combineReducers(reducers)
const persistedReducer = persistReducer(persistConfig, rootReducer)
//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  const store = createStore(persistedReducer, undefined, composeEnhancers((applyMiddleware())))
  const persistor = persistStore(store)
  return { store, persistor }
}

// const composeEnhancers =
//   // @ts-ignore
//   typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//   ? // @ts-ignore
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//       trace: true,
//     })
//   : compose

// const store = configureStore({
//   reducer: combineReducers(reducers),
//   enhancers: composeEnhancers,
//   // middleware: [forwardToRenderer]
// })

// export default store
