import React, { useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form } from "antd"
import { CartItem, StyleSheet, Total, Order } from "../../../models"
import { ExampleCustomer } from "../../../constants/data"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { addItemToCart, showKeyboard, clearCart } from "../../../redux/Checkout/slice"
import { addOrder, addHoldingOrder } from "../../../redux/Order/slice"
import { useNavigate } from "react-router-dom"
import CustomerScanModal from "./CustomerScanModal"
import useViewport from "../../../hooks/useViewport"
import { UserState } from "../../../redux/User/slice"

export default function CustomerOperationSection() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, total, isKeyboardVisible } = Checkout
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, holdingOrders } = Order
  const User:UserState = useSelector((state: RootStateOrAny) => state.User)
  const { user } = User
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState<boolean>(false)
  const { height, width } = useViewport()

  return (
    <>
    <CustomerScanModal 
      isVisible={isCustomerModalVisible} 
      setVisibility={(value: boolean) => {
        setIsCustomerModalVisible(value)
      }} 
    />
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: width * 0.25, flexDirection: "column", backgroundColor: "white", marginTop: 50 }}>
      <Button 
        type="primary" 
        style={{...styles.button, marginRight: 20 }}
        onClick={() => setIsCustomerModalVisible(true)}
      >
        Scan <br/> Membership Card
      </Button>
      <Button 
        type="primary" 
        style={{...styles.button, marginRight: 20 }}
        onClick={() => navigate("/products")}
      >
        Scale <br/> Products
      </Button>
      <Button 
        type="primary" 
        style={{...styles.button, marginRight: 20 }} 
        onClick={async () => {
          if (itemsInCart.length > 0) {
            const count = orders.length + 1
            const newOrder: Order = {
              id: count.toString(),
              createdAt: new Date().toDateString(),
              updatedAt: new Date().toDateString(),
              // createdBy: servingCustomer?.name,
              // createdFor: servingCustomer?.name,
              staff: user||undefined,
              customer: servingCustomer ? servingCustomer : ExampleCustomer,
              status: "submitted",
              hasRefund: false,
              refundAmount: 0,
              surchargeAmount: 0,
              invoicePrinted: false,
              storeId: "1",
              type: "dineIn",
              amount: 0,
              discoutAmount: 0,
              taxAmount: 0,
              totalAmount: total.totalAmount,
              cartItems: itemsInCart,
              customerCode: "",
              customerName: "",
              customerOrderNo: "",
              machineId: ""
            }
            // console.log("confirm order")
            dispatch(addOrder({ order: newOrder }))
            // dispatch(clearCart({}))
            navigate("/payment")
            // await postInvoice(newOrder)
          }
        }}
      >
        Proceed <br/> Payment
      </Button>
    </div>
    </>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  totalContainer: {
    right: 10,
    position:"absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row"
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20 
  },
  button: {
    marginBottom: 20,
    width: 200,
    height: 120,
    marginTop: 20,
    fontSize: 18,
    fontWeight: "bold"
  }
}