export enum ScannnerFocusType {
  CustomerCode = "CustomerCode",
  RoyalPayAuthCode = "RoyalPayAuthCode",
  ProductBarcode = "ProductBarcode"
}

export enum UserScreenType {
  Checkout = "Checkout",
  Payment = "Payment",
  Products = "Products",
  Orders = "Orders",
  Customers = "Customers",
  Invoices = "Invoices",
  Setting = "Settings",
  CustomerScreen = "CustomerScreen"
}

export enum CartItemType {
  Special = "special",
  Promotion = "promotion",
  Normal = "normal",
  Refund = "refund",
  BuyNGetOneFree = "buyngetonefree",
  SpecialPrice = "specialprice",
  SpecialDiscount = "specialdiscount",
  ReductionPrice = "reductionprice"
}

export enum SpecialPriceType {
  SpecialPrice = "SpecialPrice",
  SpecialDiscount = "SpecialDiscount",
  ReductionPrice = "ReductionPrice",
  BuyNGetOneFree = "BuyNGetOneFree"
}

export enum PaymentType {
  OneOff = "oneOff",
  Split = "split"
}

export enum PaymentStep {
  Split1 = "split1",
  Split2 = "split2",
  OneOff = "oneOff",
  Finish = "finish"
}

export enum PaymentMethod {
  Cash = "cash",
  Card = "card",
  RoyalPay = "royalpay"
}

export enum BackendPaymentMethod {
  Cash = "CASH",
  EFTPOS = "EFTPOS",
  RoyalPay = "ROYAL PAY",
  DebitCard = "DEBIT CARD",
  Visa = "VISA",
  MasterCard = "MASTERCARD"
}

export enum PageOperationType {
  First = "First",
  Next = "Next",
  Prev = "Prev",
  Last = "Last"
}

export enum DisplayDataType {
  All = "All",
  Search = "Search",
  Category = "Category"
}
