import React, { useEffect, useState, useRef } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Steps, Tabs, Button, Input, message } from "antd"
import { UserOutlined, LaptopOutlined, NotificationOutlined, ShoppingCartOutlined } from "@ant-design/icons"
import { BrowserRouter, Routes, Route, Link, useLocation, useMatch, Router } from "react-router-dom"
import "react-tiny-fab/dist/styles.css"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { StyleSheet } from "../models"
import CheckoutCart from "../components/Checkout/CheckoutCart"
import ProductSection from "../components/Checkout/ProductSection"
import "react-simple-keyboard/build/css/index.css"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import MultiLanguageKeyboard from "../components/common/MultiLanguageKeyboard"
import { showKeyboard, switchKeyboardLanguage } from "../redux/Checkout/slice"
import StaffInfoSection from "../components/Checkout/Staff/StaffInfoSection"
import CustomerInfoSection from "../components/Checkout/Customer/CustomerInfoSection"
import TotalSectionSmall from "../components/Checkout/TotalSectionSmall"
import StaffOperationSection from "../components/Checkout/Staff/StaffOperationSection"
import CustomerOperationSection from "../components/Checkout/Customer/CustomerOperationSection"
import KeyboardModal from "../components/common/KeyboardModal"
import useViewport from "../hooks/useViewport"
import RefundCart from "../components/Refund/RefundCart"
import RefundTotal from "../components/Refund/RefundTotal"
import RefundProductSection from "../components/Refund/RefundProductSection"
import { userLogout, UserState } from "../redux/User/slice"
import { clearPaymentProcedure } from "../redux/Payment/slice"
// import { getSerialPortValue } from "../utils/Magellan-functions"
import { useNavigate } from "react-router-dom"
import Clock from "../components/Checkout/Clock"
import ActionConfirmModal from "../components/common/ActionConfirmModal"

const { confirm } = Modal

type Props = {
  setIsLoginModalVisible: (value: boolean) => void
}

export default function CheckoutPage({ setIsLoginModalVisible }: Props) {
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const { isSignIn, user } = User
  console.log("User Content: ", User)
  const { isKeyboardVisible, keyboardLanguage, total, isRefundMode, refundTotal, currentRefundInvoice } = Checkout
  const { Step } = Steps
  const { TabPane } = Tabs
  const { height, width } = useViewport()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentTab, setCurrentTab] = useState<string>("1")
  const [closeModal, setCloseModal] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [isScanableForProduct, setIsScanableForProduct] = useState<boolean>(true)
  const [isScanableForCustomer, setIsScanableForCustomer] = useState<boolean>(false)
  const [isCashDeclarationAlertModal, setIsCashDeclarationAlertModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState<string>()

  useEffect(() => {
    setIsScanableForProduct(true)
    // setIsScanableForCustomer(false)
    dispatch(clearPaymentProcedure({}))//清空结算缓存
    return () => {
      setIsScanableForProduct(false)
      // setIsScanableForCustomer(false)
      console.log("return effect")
    }
  }, [])

  useEffect(() => {
    setIsScanableForProduct(!isScanableForCustomer)
    console.log("反向设置isscanable", isScanableForCustomer)
  }, [isScanableForCustomer])

  const closeAllModal = () => {
    setCloseModal(!closeModal)
  }

  const showIsvaluble = () => {
    console.log("IsScanableForProduct是", isScanableForProduct)
    console.log("isScanableForCustomer是", isScanableForCustomer)
  }
  return (
    <>
      {isCashDeclarationAlertModal ?
        <ActionConfirmModal
          isVisible={isCashDeclarationAlertModal}
          description={"Do you want to proceed Cash Declaration?"}
          actionText={"Yes"}
          cancelText={"No"}
          onConfirm={() => {
            navigate("/cashDeclaration?from=EndDeclaration")
            setIsCashDeclarationAlertModal(false)
          }}
          onCancel={() => {
            setIsCashDeclarationAlertModal(false)
            if (isSignIn) {
              confirm({
                content: <Typography>Confirm Logout?</Typography>,
                async onOk() {
                  dispatch(userLogout())
                  navigate("/checkout")
                  //退出登录同步到客户屏
                  //@ts-ignore
                  if (window?.electronAPI) {
                    console.log("退出登录成功同步显示在客户屏...", false)
                    //@ts-ignore
                    window.electronAPI.signInSync(false, (response) => {
                      console.log("退出登录同步显示在客户屏===成功: ", response)
                    })
                  }
                  setIsLoginModalVisible(true)
                },
                async onCancel() {
                  console.log("Cancel")
                },
              })
            } else
              setIsLoginModalVisible && setIsLoginModalVisible(true)

          }}
          rightButtonDanger={false}
        />
        : null
      }
      {isSignIn ?
        <div className="checkoutContainer" style={{ width, height }}>
          <div className="reminder checkoutCartReminder">
            <div>
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/login-icon.png"} className="pageIcon16" />
              {
                isSignIn ?
                  <StaffInfoSection returnValue="staff" />
                  :
                  null
              }
            </div>
            <div style={{
              width: "210px",
              height: "32px",
              borderRadius: "16px",
              backgroundColor: "#8590A3",
              marginRight: "4px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}>
              <Link to="/cashdeclaration">
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/home@2x-icon.png"} className="pageIcon" />
              </Link>
              {user && user.UserGroupCode !== "STAFF" ?
                <Link to="/setting">
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/setting@2x-icon.png"} className="pageIcon" />
                </Link>
                :
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/setting@2x-icon.png"} className="pageIcon" />
              }
              <div style={{ color: "#fff", fontFamily: "PingFangSC-Semibold", fontWeight: 400 }}
                   onClick={() => {
                     setIsCashDeclarationAlertModal(true)
                   }}
              >
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/switch@2x-icon.png"} className="pageIcon" />
                {isSignIn ?
                  "logout"
                  :
                  "login"
                }
              </div>
            </div>
          </div>

          <div className="reminder checkoutProductReminder">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Clock />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StaffInfoSection returnValue="customer" />
              <Link to="/customers">
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/customerService-icon.png"}
                     className="pageIcon" />
              </Link>
            </div>
          </div>

          <div className="checkoutCart">
            <div style={{ display: "flex", alignItems: "center", marginTop: "5px", marginBottom: "5px" }}>
              <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/cartFull-icon.png"} className="pageIcon" /></div>
              <div className="pageTitle">
                SHOPPING CART
                {/* <button onClick={showIsvaluble}>频繁更换</button> */}
              </div>
            </div>

            {
              isRefundMode ?
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography style={{ margin: 10, fontWeight: "bold", fontSize: 20 }}>Current Refund Invoice No:</Typography>
                    <Typography style={{ margin: 10, fontSize: 20 }}>{currentRefundInvoice?.InvoiceNo}</Typography>
                  </div>
                  <RefundCart />
                </>
                :
                // null
                <CheckoutCart
                  //  scrollableHeight={555} 
                  isScanable={isScanableForProduct}
                  setIsScanable={setIsScanableForProduct}
                />
              // <CheckoutCart tableHeight={height * 0.65} scrollableHeight={height * 0.6} />
            }


          </div>
          <div className="checkoutOperation">
            <div className="checkoutOperationBox">
              {
                isRefundMode ?
                  <RefundTotal
                    item={refundTotal}
                    // containerStyle={{ backgroundColor: "white", flexDirection: "column", display: "flex", margin: 10 }}
                  />
                  :
                  <TotalSectionSmall
                    item={total}
                  />
              }
              {
                isSignIn ?
                  <StaffOperationSection
                    closeModal={closeModal}
                    isScanableForCustomer={isScanableForCustomer}
                    setIsScanableForCustomer={(value) => setIsScanableForCustomer(value)}
                  />
                  :
                  null
              }
            </div>
          </div>

          <div className="checkoutProductList">
            {
              isRefundMode ?
                <RefundProductSection />
                :
                <ProductSection
                  closeAllModal={closeAllModal}
                  isScanable={isScanableForProduct}
                  setIsScanable={setIsScanableForProduct}
                />
            }
          </div>
        </div>
        :
        null
      }
    </>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "white"
  },
  leftContainter: {
    width: "50%",
  },
  rightContainer: {
    width: "50%",
  },
  stepsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  tabsContainer: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    marginLeft: 50,
    flexDirection: "column",
    backgroundColor: "white",
  },
  infoContainer: {
    marginTop: 10,
    marginBottom: 5,
    flexDirection: "row",
  },
  paymentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  operationContainer: {
    marginTop: 5,
    marginBottom: 5,
    flexDirection: "row",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    marginBottom: 5,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: "grey",
  },
  descRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descText: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
  },
  disabledLayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  button: {
    marginLeft: 10,
  },
}