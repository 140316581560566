import { message, Table } from 'antd';
import type { TableProps } from 'antd';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import React, { useEffect, useRef, useState } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

const VirtualTable = <RecordType extends object>(props: TableProps<RecordType>) => {
    const { columns, scroll } = props;
    const [tableWidth, setTableWidth] = useState(0);

    const widthColumnCount = columns!.filter(({ width }) => !width).length;
    const mergedColumns = columns!.map(column => {
        if (column.width) {
            if (typeof (column.width) === 'string' && column.width.indexOf('%') > -1) {//宽度是百分比
                const widthRate = Number(column.width.substring(0, column.width.length - 1)) / 100
                // console.log('宽度为百分比', column.width, 'mei...', column.width.substring(0, column.width.length - 1), widthRate)
                return {
                    ...column,
                    width: tableWidth * widthRate,
                    // width: Math.floor(tableWidth * widthRate),
                };
            } else if (typeof (column.width) === 'number') {
                return column;
            }
        }

        return {
            ...column,
            width: Math.floor(tableWidth / (columns ? columns.length : 8)),
            // width: Math.floor(tableWidth / widthColumnCount),
        };
    });
    // console.log("传入的表字段定义", mergedColumns)
    const gridRef = useRef<any>();
    const [connectObject] = useState<any>(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => {
                if (gridRef.current) {
                    return gridRef.current?.state?.scrollLeft;
                }
                return null;
            },
            set: (scrollLeft: number) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({ scrollLeft });
                }
            },
        });

        return obj;
    });

    const resetVirtualGrid = () => {
        gridRef.current?.resetAfterIndices({
            columnIndex: 0,
            shouldForceUpdate: true,
        });
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

const calculateRowHeigh = (raw:any) => {
    {
        const promotionText = raw.promotionText
        const productName = raw.productInfo.name
        let rowHeight = promotionText? 60 : 30
        console.log("promotionText高度计算1", rowHeight, productName.length, productName.length*2/30, Math.max((Math.ceil(productName.length*2/30)), 2) * 30)
        rowHeight += Math.max((Math.ceil(productName.length*2/30)), 2) * 30
        if (raw.originalPrice && ((raw.quantity * raw.originalPrice) - raw.total) >= 0.01) {
            rowHeight = Math.max(60, rowHeight)
        }
        console.log("包含name高度计算2", rowHeight)
        return rowHeight
    }
}

    const renderVirtualList = (rawData: any[], { scrollbarSize, ref, onScroll }: any) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * 100;
        // console.log("virtualList  是。。", rawData)

        return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                columnCount={mergedColumns.length}
                columnWidth={(index: number) => {
                    const { width } = mergedColumns[index];
                    // console.log(`${mergedColumns[index].key}的宽度是：：`, width, "scrollbarSize", scrollbarSize)
                    return totalHeight > scroll!.y! && index === mergedColumns.length - 1
                        ? (width as number) - scrollbarSize? scrollbarSize : 10 - 1
                        : (width as number);
                }}
                height={scroll!.y as number}
                rowCount={rawData.length}
                // estimatedRowHeight={74}
                rowHeight={(index:number) => calculateRowHeigh(rawData[index])}
                width={tableWidth}
                onScroll={({ scrollLeft }: { scrollLeft: number }) => {
                    onScroll({ scrollLeft });
                }}
            >
                {({
                    columnIndex,
                    rowIndex,
                    style,
                }: {
                    columnIndex: number;
                    rowIndex: number;
                    style: React.CSSProperties;
                }) => (
                    <div
                        className={classNames('virtual-table-cell', {
                            'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                        })}
                        style={style}
                    // className={classNames('virtual-table-cell', {
                    //     'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                    // })}
                    // style={style}
                    >
                        {/* {columnIndex}//{rowIndex} */}
                        {(mergedColumns as any)[columnIndex].render((rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex], rawData[rowIndex], rowIndex)}
                        {/* {(rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex]} */}
                        {/* {(mergedColumns as any)[columnIndex].render((rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex], rawData[rowIndex], rowIndex)} */}
                    </div>
                )}
            </Grid>
        );
    };

    return (
        <ResizeObserver
            onResize={({ width }) => {
                setTableWidth(width);
            }}
        >
            <Table
                {...props}
                // className="virtual-table"
                className="virtual-table pageMainTable"
                columns={mergedColumns}
                pagination={false}
                components={{
                    //@ts-ignore  
                    body: renderVirtualList,
                }}
            />
        </ResizeObserver>
    );
};

// // Usage
// const columns = [
//   { title: 'A', dataIndex: 'key', width: 150 },
//   { title: 'B', dataIndex: 'key' },
//   { title: 'C', dataIndex: 'key' },
//   { title: 'D', dataIndex: 'key' },
//   { title: 'E', dataIndex: 'key', width: 200 },
//   { title: 'F', dataIndex: 'key', width: 100 },
// ];

// const data = Array.from({ length: 100000 }, (_, key) => ({ key }));

// const App: React.FC = () => (
//   <VirtualTable columns={columns} dataSource={data} scroll={{ y: 300, x: '100vw' }} />
// );

// export default App;
export default VirtualTable;